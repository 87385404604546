import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css';
import App from './App';
import PublicSite from './PublicSite.js';
import NotFound from './pages/NotFound';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';

Amplify.configure(config);
var active_user = false;

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Router>
    <Routes>
      {active_user ? (
        <Route path="/*" element={<App />} />
      ) : (
        <Route path="/*" element={<PublicSite />} />
      )}
      <Route path="*" element={<NotFound />} />
    </Routes>
  </Router>
);

reportWebVitals();