import React, { useState, useEffect, useRef } from 'react';
import { Button, Heading } from '@aws-amplify/ui-react';
import ReactDOM from 'react-dom';
import Confetti from 'react-confetti';


const OnboardingModal = ({ isOpen, onClose, onSubmit, existingName, existingPhoneNumber, existingYourGender, existingDueDate, hasChildren, babyImage }) => {
  const [currentScreen, setCurrentScreen] = useState(0);
  const [name, setName] = useState(existingName);
  const [phoneNumber, setPhoneNumber] = useState(existingPhoneNumber);
  const [yourGender, setYourGender] = useState(existingYourGender);
  const [dueDate, setDueDate] = useState(existingDueDate);
  const [childName, setChildName] = useState('');
  const [childBirthday, setChildBirthday] = useState('');
  const [childGender, setChildGender] = useState('');
  //const [gender, setGender] = useState(appUser?.your_gender || '');
  const [error, setError] = useState('');
  const modalRef = useRef(null);

  const handleGenderChange = (event) => {
    setYourGender(event.target.value);
  };


    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  
  useEffect(() => {
    const handleResize = () => {
      if (modalRef.current) {
        const modalWidth = modalRef.current.offsetWidth;
        const modalHeight = modalRef.current.offsetHeight;
        setWindowWidth(modalWidth);
        setWindowHeight(modalHeight);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleNext = () => {
    if (currentScreen === 1) {
      if (!phoneNumber) {
        setError('Phone number is required, so we can send you helpful texts.');
        return;
      }

      //console.log('hello');
      console.log(hasChildren, dueDate, childName, childBirthday, childGender);

      if (hasChildren!='true' && !dueDate && !childName) {
        setError('Please enter either a due date or info about your child, so we can help!');
        return;
      }

      const userData = {
        name,
        phoneNumber,
        yourGender,
        dueDate,
        childData: childName ? { name: childName, birthday: childBirthday, gender: childGender } : null,
      };

      onSubmit(userData);
      //set local storage
      localStorage.setItem("active_modal", "true");
        setCurrentScreen((prevScreen) => prevScreen + 1);
        //setShowOnboardingModal(false); // Hide the OnboardingModal
} else {
  setCurrentScreen((prevScreen) => prevScreen + 1);
  if (currentScreen === 2) {
    localStorage.setItem("active_modal", "false");
  }
}
  };

  const handleBack = () => {
    setCurrentScreen((prevScreen) => prevScreen - 1);
  };

  const handleSubscribe = () => {
    // Perform subscription logic here
    onClose();
  };

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  const handleContentClick = (event) => {
    event.stopPropagation();
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content" ref={modalRef} onClick={handleContentClick}>
        <div className="confetti-container">
          {currentScreen === 0 && (
            <Confetti
              width={windowWidth}
              height={windowHeight}
              numberOfPieces={200}
              recycle={false}
            />
          )}
        </div>
        {currentScreen === 0 && (
          <div className="screen-container">
            <div className="welcome-screen">
              <div className="welcome-content">
                <Heading level={4}>Welcome to Kintext! 🎉</Heading>
                <p>
                  Kintext is your personal AI companion during your pregnancy and parenthood journey. <br /><br />We provide helpful information, tips, and support to make the experience enjoyable and less overwhelming for moms and dads. Get ready for an exciting adventure! 🌟🌈👶
                </p>
            
                <img src={babyImage} alt='Baby and Parent' />
              </div>
              <center>
              <div className="modal-buttons-right">
                <Button onClick={handleNext}>Next →</Button>
              </div>
              </center>
            </div>
          </div>
        )}
        {currentScreen === 1 && (
          <div className="screen-container">
            <div className="welcome-screen">
              <Heading level={5}>Let's get started with some information:</Heading>
              <br />
              <div className="input-wrapper">
                <input
                  type="text"
                  placeholder="Enter your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="custom-input"
                />
              </div>
              <br /><br />
              <div className="input-wrapper">
                <input
                  type="tel"
              placeholder="+1 555-123-1234"
                  value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value.replace(/[^0-9+]/g, ''))}
                  className="custom-input"
                />
              </div>
              <br /><br />
    <div className="radio-group">
      <label className="radio-label">
        <input
          type="radio"
          name="gender"
          value="mom"
          checked={yourGender === 'mom'}
          onChange={handleGenderChange}
        />
        <span className="checkmark"></span>
        Mom
      </label>
      <label className="radio-label">
        <input
          type="radio"
          name="gender"
          value="dad"
          checked={yourGender === 'dad'}
          onChange={handleGenderChange}
        />
        <span className="checkmark"></span>
        Dad
      </label>
    </div>
    <br />
              <div className="input-wrapper">
                Baby Due Date (if expecting)
                <input
                  type="date"
                  value={dueDate}
                  onChange={(e) => setDueDate(e.target.value)}
                  className="custom-input"
                />
              </div>
              <br /><br />
              <span>Current Child Information (optional):</span>
              
              <div className="input-wrapper">
                <input
                  type="text"
                  placeholder="Enter child's name"
                  value={childName}
                  onChange={(e) => setChildName(e.target.value)}
                  className="custom-input"
                />
              </div>
              <br /><br />
              <div className="input-wrapper">
                <input
                  type="date"
                  value={childBirthday}
                  onChange={(e) => setChildBirthday(e.target.value)}
                  className="custom-input"
                />
              </div>
              <br /><br />
              <div className="select-wrapper">
                <select
                  value={childGender}
                  onChange={(e) => setChildGender(e.target.value)}
                  className="custom-select"
                >
                  <option value="">Select Gender</option>
                  <option value="Boy">Boy</option>
                  <option value="Girl">Girl</option>
                </select>
              </div>
              {error && <div className="error-message">{error}</div>}
              <br />
              <div className="modal-buttons">
                <Button onClick={handleBack}>Back</Button>
                <Button onClick={handleNext}>Next</Button>
              </div>
            </div>
          </div>
        )}
        {currentScreen === 2 && (
          <div className="screen-container">
            <div className="welcome-screen">
              <Heading level={4}>Pricing Information</Heading>
              <span>
                Kintext offers the following subscription plans:
                </span>
              <div className="subscription-plans">
                <div className="plan">
                  <h2>&#x1f476;<br />Basic Plan</h2>
                  <center>$20/month
                    <ul>
                      <li>✔ Basic features</li>
                      <li>✔ 9 months of support</li>
                      <li>✔ Text and email </li>
                      <form className='plan' style={{ backgroundColor: 'transparent', boxShadow: 'none', padding: '0px', borderRadius: '0px', width: '20%', minWidth: '100px' }} action="https://wq1tohfcpb.execute-api.us-east-2.amazonaws.com/staging/kintextsystempaymentsapi/create-checkout-session" method="POST">
                        <button className='' style={{ marginBottom: '10px' }} type="submit">
                          Subscribe
                        </button>
                      </form>
                    </ul>
                  </center>
                </div>
                <div className="plan">
                  <h2>&#x1f476; + &#x1F916;<br />Premium AI Plan</h2>
                  <center>$50/month
                    <ul>
                      <li>✔ All Basic features</li>
                      <li>✔ AI Agent support</li>
                      <li>✔ Priority email support</li>
                      <form className='plan' style={{ backgroundColor: 'transparent', boxShadow: 'none', padding: '0px', borderRadius: '0px', width: '20%', minWidth: '100px' }} action="https://wq1tohfcpb.execute-api.us-east-2.amazonaws.com/staging/kintextsystempaymentsapi/create-checkout-session-premium" method="POST">
                        <button className='' style={{ marginBottom: '10px' }} type="submit">
                          Subscribe
                        </button>
                      </form>
                    </ul>
                  </center>
                </div>
              </div>
              <div className="modal-buttons">
                <Button onClick={handleBack}>Back</Button>
                <Button onClick={onClose}>Finish</Button>
              </div>
            </div>
          </div>
        )}
      </div>
      <style jsx>{`
   @media (max-width: 600px) {
    .subscription-plans {
      flex-direction: column;
    }
  
    .plan {
      width: 100%;
      min-width: unset;
      margin-bottom: 20px;
    }
  
    .modal-content {
      padding: 10px;
      max-height: 90vh;
      overflow-y: auto;
    }
  
    .welcome-content h4 {
      font-size: 24px;
    }
  
    .welcome-content p {
      font-size: 16px;
    }
  
    .plan h2 {
      font-size: 20px;
    }
  
    .plan ul {
      font-size: 14px;
    }
  
    .modal-buttons button {
      font-size: 16px;
      padding: 10px 20px;
    }
  }
  @media (min-width: 601px) {
    .screen-container {
      height: 80vh;
    }
  }

  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    max-width: 800px;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    position: relative;
    max-height: 90vh;
    overflow-y: auto;
  }
          .confetti-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
            pointer-events: none;
          }
  
          .screen-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            text-align: center;
            min-height: 400px;
            background-color: #f0f8ff;
            border-radius: 8px;
            padding: 20px;
            position: relative;
            z-index: 1;
            overflow-y: auto;
          }
        .welcome-content {
          margin-bottom: 30px;
        }

        .welcome-content h4 {
          font-size: 32px;
          color: #007eff;
          margin-bottom: 20px;
        }

        .welcome-content p {
          font-size: 18px;
          color: #333;
          margin-bottom: 10px;
        }

        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 999;
        }


        .input-wrapper {
          position: relative;
          display: inline-block;
          width: 100%;
        }

        .custom-input {
          width: 100%;
          padding: 8px;
          border: 1px solid #ccc;
          border-radius: 4px;
          font-size: 14px;
          color: #333;
        }

        .custom-input:focus {
          outline: none;
          border-color: #007eff;
          box-shadow: 0 0 0 2px rgba(0, 126, 255, 0.2);
        }

        .select-wrapper {
          position: relative;
          display: inline-block;
          width: 100%;
        }

        .custom-select {
          width: 100%;
          padding: 8px;
          border: 1px solid #ccc;
          border-radius: 4px;
          appearance: none;
          background-color: #fff;
          font-size: 14px;
          color: #333;
          cursor: pointer;
        }

        .custom-select:focus {
          outline: none;
          border-color: #007eff;
          box-shadow: 0 0 0 2px rgba(0, 126, 255, 0.2);
        }

        .select-wrapper::after {
          content: '▼';
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          pointer-events: none;
          color: #999;
        }

        .error-message {
          color: red;
          margin-top: 10px;
          font-size: 14px;
        }

        .subscription-plans {
            display: flex;
            gap: 20px;
            flex-wrap: wrap; /* Add this line */
          }
  
          .plan {
            background-color: #f0f0f0;
            padding: 20px;
            border-radius: 4px;
            width: calc(50% - 10px); /* Adjust the width */
            min-width: 300px; /* Add a minimum width */
          }
  

        .plan h2 {
          margin-top: 0;
        }

        .plan ul {
          list-style-type: none;
          padding: 0;
          margin-bottom: 20px;
        }

        .plan-form {
          display: inline-block;
        }

        .plan-button {
          background-color: #007eff;
          color: #fff;
          border: none;
          padding: 10px 20px;
          border-radius: 4px;
          font-size: 16px;
          cursor: pointer;
          width: 100%;
          text-align: center;
        }

        .plan-button:hover {
          background-color: #0056b3;
        }

        .modal-buttons {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
        }

        .modal-buttons button {
            font-size: 18px;
            padding: 12px 24px;
            background-color: #007eff;
            color: #fff;
            border: none;
            border-radius: 4px;
            cursor: pointer;
        }

        .modal-buttons-right {
          display: flex;
          justify-content: center;
          margin-top: 20px;
        }

        .modal-buttons-right button {
          font-size: 18px;
          padding: 12px 24px;
          background-color: #007eff;
          color: #fff;
          border: none;
          border-radius: 4px;
          cursor: pointer;
        }

        .modal-buttons-right button:hover {
          background-color: #0056b3;
        }

        .welcome-content img {
            width: 35%;
            height: auto;
          }
          
          @media (min-width: 768px) {
            .welcome-content img {
              max-width: 400px;
            }
          }
      `}</style>
    </div>,
    document.body
  );
};

export default OnboardingModal;