import React from 'react';
import { Link } from 'react-router-dom';
import './BlogPost.css';
import PublicNav from './PublicNav';
import Head from './BlogHead';

const BlogPost = ({ title, subtitle, introduction, tips, conclusion, relatedArticles, description, keywords, ogTitle, ogDescription, ogImage, ogUrl }) => {
  return (
    <div>
      <Head
        title={title}
        description={description}
        keywords={keywords}
        ogTitle={ogTitle}
        ogDescription={ogDescription}
        ogImage={ogImage}
        ogUrl={ogUrl}
      />
    <PublicNav />
    <article className="blog-post">
      <header>
        <h1 className='blogPostH1'>{title}</h1>
        <p className="subtitle">{subtitle}</p>
      </header>
      <main>
        <section className="intro">
          <p>{introduction}</p>
        </section>
        <section className="tips">
          <h2 className='blogPostH2'>{tips.heading}</h2>
          <ol>
            {tips.items.map((tip, index) => (
              <li key={index}>
                <h3 className='blogPostH3'>{tip.title}</h3>
                <p className='blogPostP'>{tip.content}</p>
              </li>
            ))}
          </ol>
        </section>
        <section className="conclusion">
          <p>{conclusion}</p>
        </section>
        {relatedArticles && relatedArticles.length > 0 && (
          <section className="related-articles">
            <h2 className='blogPostH2'>Related Articles</h2>
            <ul>
              {relatedArticles.map((article, index) => (
                <li key={index}>
                  <Link to={article.url}>{article.title}</Link>
                </li>
              ))}
            </ul>
          </section>
        )}
      </main>
      <footer style={{}}>
        <Link to="/" className="homepage-link">
          Back to Kintext
        </Link>
      </footer>    
    </article>

    <div className="content-footer">
  <div className="footer-columns">
    <div className="footer-column">
      <h3>Advice for Expecting Moms</h3>
      <ul>
      <li><Link to="/morning-sickness-tips">Morning Sickness Tips</Link></li>
              <li><Link to="/nutrition-during-pregnancy">Nutrition During Pregnancy</Link></li>
              <li><Link to="/preparing-for-labor">Preparing for Labor</Link></li>
      </ul>
    </div>
    <div className="footer-column">
      <h3>Advice for Expecting Dads</h3>
      <ul>
      <li><Link to="/supporting-your-partner">Supporting Your Partner</Link></li>
              <li><Link to="/preparing-for-fatherhood">Preparing for Fatherhood</Link></li>
              <li><Link to="/bonding-with-your-baby">Bonding with Your Baby</Link></li>
      </ul>
    </div>
    <div className="footer-column">
      <h3>Favorite Partner Resources</h3>
      <ul>
      <li><a href="https://whattoexpect.com" target="_blank" rel="noopener noreferrer">What To Expect</a></li>
              <li><a href="https://mrdad.com" target="_blank" rel="noopener noreferrer">Mr. Dad</a></li>
              <li><a href="https://fathercraft.com" target="_blank" rel="noopener noreferrer">Fathercraft</a></li>
      </ul>
    </div>

  </div>
</div>

<div className="footer">
<span style={{'color':'grey', 'fontSize':'0.8em'}}>
  <center>
  <a className='footerLinks' href='/terms'>Terms of Service</a> | <a className='footerLinks' href='/privacy'>Privacy Policy</a> | <a className='footerLinks' href='https://learn.kintext.com/'>Resources</a> |  <a className='footerLinks' href='mailto:team@kintext.com'>Contact Us</a>
  </center>
  </span>
  </div>

</div>    
  );
};

export default BlogPost;