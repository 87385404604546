import React, { useEffect } from 'react';
import { generateClient } from 'aws-amplify/api';
import { createGiftUser, createAppUser } from '../graphql/mutations';
import { listAppUsers } from '../graphql/queries';
import { sendGiftSubscriptionEmails } from '../Functions';

const client = generateClient();

export default function PaymentSuccess() {
  useEffect(() => {
    // Google AdWords conversion tracking script
    const conversionScript = document.createElement('script');
    conversionScript.innerHTML = `
      gtag('event', 'conversion', {
        'send_to': 'AW-16549435740/RjRSCPjq66sZENyysdM9',
        'value': 1.0,
        'currency': 'USD',
        'transaction_id': ''
      });
    `;
    document.body.appendChild(conversionScript);

    const senderEmail = localStorage.getItem('senderEmail');
    const recipientEmail = localStorage.getItem('recipientEmail');
    if (senderEmail && recipientEmail) {
      addGiftUserToDatabase(senderEmail, recipientEmail);
      addRecipientToAppUserDatabase(recipientEmail);
      handleGiftSubscriptionEmails(senderEmail, recipientEmail);
    }
  }, []);

  const handleGiftSubscriptionEmails = (senderEmail, recipientEmail) => {
    const senderEmailValue = senderEmail;
    const recipientEmailValue = recipientEmail;
    sendGiftSubscriptionEmails(senderEmailValue, recipientEmailValue)
      .then(data => {
        console.log("Gift subscription emails sent successfully!", data)
      })
      .catch(error => console.error("Failed to send email", error));
  };

  const addGiftUserToDatabase = async (senderEmail, recipientEmail) => {
    try {
      const giftTimestamp = new Date().toISOString();
      await client.graphql({
        query: createGiftUser,
        variables: {
          input: {
            sender_email: senderEmail,
            sender_phone_number: '', // Provide the sender's phone number or leave it empty
            recipient_email: recipientEmail,
            recipient_phone_number: '', // Provide the recipient's phone number or leave it empty
            subscription_type: 'gift', // or any other appropriate value
            gift_timestamp: giftTimestamp,
          },
        },
      });
      console.log('Gift user added to the database.');
    } catch (error) {
      console.error('Error adding gift user to database:', error);
    }
  };

  const addRecipientToAppUserDatabase = async (recipientEmail) => {
    const giftTimestamp = new Date().toISOString();
    try {
      const response = await client.graphql({
        query: listAppUsers,
        variables: {
          filter: { email_address: { eq: recipientEmail } },
        },
      });
      const existingUsers = response.data.listAppUsers.items;
      if (existingUsers.length === 0) {
        await client.graphql({
          query: createAppUser,
          variables: {
            input: {
              email_address: recipientEmail,
              paid_subscription_status: 'true', // Set paid_subscription_status to true for gift recipients
              gift_subscription_status: 'true', // Set paid_subscription_status to true for gift recipients
              gift_subscription_referred: giftTimestamp, // Set paid_subscription_status to true for gift recipients
              // Leave other fields empty or provide default values as needed
            },
          },
        });
        console.log(`User with email ${recipientEmail} added to the AppUser database.`);
      } else {
        //TODO in the future you can extend this person's subscription/add it to an existing user
        console.log(`User with email ${recipientEmail} already exists in the AppUser database.`);
      }
    } catch (error) {
      console.error('Error adding recipient to AppUser database:', error);
    }
  };

  console.log('Recipient Email:', localStorage.getItem('recipientEmail'));
  console.log('Sender Email:', localStorage.getItem('senderEmail'));

  setTimeout(() => {
    window.location.href = '/dashboard';
  }, 2000);

  return <div>Gift Payment Success</div>;
}