import React from 'react'

export default function Privacy() {
  return (
    
    <div style={{'margin':'5%'}}>
      <h1>Privacy Policy</h1>
    
      <p>This Privacy Policy ("Policy") describes how Stacksort LLC ("Company," "we," "us," or "our") collects, uses, and shares personal information when you use our Kintext service ("Service"). By using our Service, you agree to the collection and use of your personal information as described in this Policy. If you do not agree with the practices described herein, please do not use our Service.</p>
    
      <h2>1. Information We Collect</h2>
      <h3>1.1 Personal Information</h3>
      <p>When you create an account and use our Service, we may collect personal information, including but not limited to your name, email address, and phone number. We require this information to provide the Service and to communicate with you regarding your account and the advice and updates.</p>
    
      <h3>1.2 Usage Data</h3>
      <p>We may collect non-personal information about your use of the Service, including IP addresses, browser type, operating system, and the pages you visit on our website. This information helps us improve the functionality and performance of our Service.</p>
    
      <h2>2. Use of Information</h2>
      <h3>2.1 Provision of Service</h3>
      <p>We use the personal information collected to provide the Service and send you advice, updates, and other information related to your pregnancy journey and early parenthood stages. This includes communicating with you regarding the Service.</p>
    
      <h3>2.2 Service Improvement</h3>
      <p>We may use aggregated and anonymized usage data to analyze trends, monitor the effectiveness of our Service, and enhance its features and functionality. This data does not identify individual users and is used for statistical purposes only.</p>
    
      <h3>2.3 Communication</h3>
      <p>We may use your email address or phone number to send you important updates, notifications, or other information related to the Service. You can opt-out of receiving non-essential communications by following the instructions provided in the communication.</p>
    
      <h2>3. Information Sharing</h2>
      <h3>3.1 Third-Party Service Providers</h3>
      <p>We may engage third-party service providers to assist us in delivering the Service or to perform related services. These providers may have access to your personal information but are strictly prohibited from using it for any other purpose.</p>
    
      <h3>3.2 Legal Compliance</h3>
      <p>We may disclose your personal information if required by law or in good faith belief that such action is necessary to comply with legal obligations, protect our rights, investigate fraud, or respond to a government request.</p>
    
      <h3>3.3 Business Transfers</h3>
      <p>In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred as part of the transaction. We will notify you via email or prominent notice on our website of any change in ownership or the use of your personal information.</p>
    
      <h2>4. Data Security</h2>
      <p>We take reasonable measures to protect the personal information we collect and maintain from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
    
      <h2>5. Your Privacy Choices</h2>
      <p>You have the right to access, update, or delete your personal information stored by us. You can make these changes by logging into your account or by contacting us directly. Please note that deleting your account may result in the permanent loss of data associated with your account.</p>
    
      <h2>6. Children's Privacy</h2>
      <p>Our Service is not directed to individuals under the age of 18. We do not knowingly collect personal information from children. If we become aware that we have inadvertently collected personal information from a child, we will take reasonable steps to delete it.</p>
    
      <h2>7. Changes to this Privacy Policy</h2>
      <p>We may update this Policy from time to time to reflect changes in our practices or legal obligations. The most current version of the Policy will be posted on our website. Your continued use of our Service after any modifications constitutes your acceptance of the updated Policy.</p>
    
      <h2>8. Contact Us</h2>
      <p>If you have any questions or concerns about this Privacy Policy or our Service, please contact us at <a href="mailto:team@kintext.com">team@kintext.com</a>.</p>
    
      <p>By using Kintext, you acknowledge that you have read, understood, and agreed to this Privacy Policy.</p>
    </div>
  )
}
