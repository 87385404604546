/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createRecentMessage = /* GraphQL */ `
  mutation CreateRecentMessage(
    $input: CreateRecentMessageInput!
    $condition: ModelRecentMessageConditionInput
  ) {
    createRecentMessage(input: $input, condition: $condition) {
      id
      user_id
      user_email
      user_phone_number
      conversation_id
      conversation_history
      createdAt
      updatedAt
    }
  }
`;
export const updateRecentMessage = /* GraphQL */ `
  mutation UpdateRecentMessage(
    $input: UpdateRecentMessageInput!
    $condition: ModelRecentMessageConditionInput
  ) {
    updateRecentMessage(input: $input, condition: $condition) {
      id
      user_id
      user_email
      user_phone_number
      conversation_id
      conversation_history
      createdAt
      updatedAt
    }
  }
`;
export const deleteRecentMessage = /* GraphQL */ `
  mutation DeleteRecentMessage(
    $input: DeleteRecentMessageInput!
    $condition: ModelRecentMessageConditionInput
  ) {
    deleteRecentMessage(input: $input, condition: $condition) {
      id
      user_id
      user_email
      user_phone_number
      conversation_id
      conversation_history
      createdAt
      updatedAt
    }
  }
`;
export const createAffiliates = /* GraphQL */ `
  mutation CreateAffiliates(
    $input: CreateAffiliatesInput!
    $condition: ModelAffiliatesConditionInput
  ) {
    createAffiliates(input: $input, condition: $condition) {
      id
      email_address
      affiliate_value
      createdAt
      updatedAt
    }
  }
`;
export const updateAffiliates = /* GraphQL */ `
  mutation UpdateAffiliates(
    $input: UpdateAffiliatesInput!
    $condition: ModelAffiliatesConditionInput
  ) {
    updateAffiliates(input: $input, condition: $condition) {
      id
      email_address
      affiliate_value
      createdAt
      updatedAt
    }
  }
`;
export const deleteAffiliates = /* GraphQL */ `
  mutation DeleteAffiliates(
    $input: DeleteAffiliatesInput!
    $condition: ModelAffiliatesConditionInput
  ) {
    deleteAffiliates(input: $input, condition: $condition) {
      id
      email_address
      affiliate_value
      createdAt
      updatedAt
    }
  }
`;
export const createAppUserChildren = /* GraphQL */ `
  mutation CreateAppUserChildren(
    $input: CreateAppUserChildrenInput!
    $condition: ModelAppUserChildrenConditionInput
  ) {
    createAppUserChildren(input: $input, condition: $condition) {
      id
      appuserid
      appuseremail
      gender
      birthday
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateAppUserChildren = /* GraphQL */ `
  mutation UpdateAppUserChildren(
    $input: UpdateAppUserChildrenInput!
    $condition: ModelAppUserChildrenConditionInput
  ) {
    updateAppUserChildren(input: $input, condition: $condition) {
      id
      appuserid
      appuseremail
      gender
      birthday
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteAppUserChildren = /* GraphQL */ `
  mutation DeleteAppUserChildren(
    $input: DeleteAppUserChildrenInput!
    $condition: ModelAppUserChildrenConditionInput
  ) {
    deleteAppUserChildren(input: $input, condition: $condition) {
      id
      appuserid
      appuseremail
      gender
      birthday
      name
      createdAt
      updatedAt
    }
  }
`;
export const createGiftUser = /* GraphQL */ `
  mutation CreateGiftUser(
    $input: CreateGiftUserInput!
    $condition: ModelGiftUserConditionInput
  ) {
    createGiftUser(input: $input, condition: $condition) {
      id
      sender_email
      sender_phone_number
      recipient_email
      recipient_phone_number
      subscription_type
      gift_timestamp
      createdAt
      updatedAt
    }
  }
`;
export const updateGiftUser = /* GraphQL */ `
  mutation UpdateGiftUser(
    $input: UpdateGiftUserInput!
    $condition: ModelGiftUserConditionInput
  ) {
    updateGiftUser(input: $input, condition: $condition) {
      id
      sender_email
      sender_phone_number
      recipient_email
      recipient_phone_number
      subscription_type
      gift_timestamp
      createdAt
      updatedAt
    }
  }
`;
export const deleteGiftUser = /* GraphQL */ `
  mutation DeleteGiftUser(
    $input: DeleteGiftUserInput!
    $condition: ModelGiftUserConditionInput
  ) {
    deleteGiftUser(input: $input, condition: $condition) {
      id
      sender_email
      sender_phone_number
      recipient_email
      recipient_phone_number
      subscription_type
      gift_timestamp
      createdAt
      updatedAt
    }
  }
`;
export const createAppUser = /* GraphQL */ `
  mutation CreateAppUser(
    $input: CreateAppUserInput!
    $condition: ModelAppUserConditionInput
  ) {
    createAppUser(input: $input, condition: $condition) {
      id
      email_address
      phone_number
      due_date
      days_pregnant_value
      days_pregnant_submitted_time
      communication_frequency
      paid_subscription_type
      paid_subscription_status
      paid_subscription_last_modified
      email_notification_status
      phone_notification_status
      gift_subscription_status
      gift_subscription_referred
      full_name
      additional_info
      your_gender
      expecting_boolean
      createdAt
      updatedAt
    }
  }
`;
export const updateAppUser = /* GraphQL */ `
  mutation UpdateAppUser(
    $input: UpdateAppUserInput!
    $condition: ModelAppUserConditionInput
  ) {
    updateAppUser(input: $input, condition: $condition) {
      id
      email_address
      phone_number
      due_date
      days_pregnant_value
      days_pregnant_submitted_time
      communication_frequency
      paid_subscription_type
      paid_subscription_status
      paid_subscription_last_modified
      email_notification_status
      phone_notification_status
      gift_subscription_status
      gift_subscription_referred
      full_name
      additional_info
      your_gender
      expecting_boolean
      createdAt
      updatedAt
    }
  }
`;
export const deleteAppUser = /* GraphQL */ `
  mutation DeleteAppUser(
    $input: DeleteAppUserInput!
    $condition: ModelAppUserConditionInput
  ) {
    deleteAppUser(input: $input, condition: $condition) {
      id
      email_address
      phone_number
      due_date
      days_pregnant_value
      days_pregnant_submitted_time
      communication_frequency
      paid_subscription_type
      paid_subscription_status
      paid_subscription_last_modified
      email_notification_status
      phone_notification_status
      gift_subscription_status
      gift_subscription_referred
      full_name
      additional_info
      your_gender
      expecting_boolean
      createdAt
      updatedAt
    }
  }
`;
export const createUsers = /* GraphQL */ `
  mutation CreateUsers(
    $input: CreateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    createUsers(input: $input, condition: $condition) {
      id
      email_address
      phone_number
      due_date
      days_pregnant_value
      days_pregnant_submitted_time
      communication_frequency
      paid_subscription_type
      paid_subscription_status
      paid_subscription_last_modified
      email_notification_status
      phone_notification_status
      gift_subscription_status
      gift_subscription_referred
      createdAt
      updatedAt
    }
  }
`;
export const updateUsers = /* GraphQL */ `
  mutation UpdateUsers(
    $input: UpdateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    updateUsers(input: $input, condition: $condition) {
      id
      email_address
      phone_number
      due_date
      days_pregnant_value
      days_pregnant_submitted_time
      communication_frequency
      paid_subscription_type
      paid_subscription_status
      paid_subscription_last_modified
      email_notification_status
      phone_notification_status
      gift_subscription_status
      gift_subscription_referred
      createdAt
      updatedAt
    }
  }
`;
export const deleteUsers = /* GraphQL */ `
  mutation DeleteUsers(
    $input: DeleteUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    deleteUsers(input: $input, condition: $condition) {
      id
      email_address
      phone_number
      due_date
      days_pregnant_value
      days_pregnant_submitted_time
      communication_frequency
      paid_subscription_type
      paid_subscription_status
      paid_subscription_last_modified
      email_notification_status
      phone_notification_status
      gift_subscription_status
      gift_subscription_referred
      createdAt
      updatedAt
    }
  }
`;
export const createNote = /* GraphQL */ `
  mutation CreateNote(
    $input: CreateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    createNote(input: $input, condition: $condition) {
      id
      name
      description
      image
      createdAt
      updatedAt
    }
  }
`;
export const updateNote = /* GraphQL */ `
  mutation UpdateNote(
    $input: UpdateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    updateNote(input: $input, condition: $condition) {
      id
      name
      description
      image
      createdAt
      updatedAt
    }
  }
`;
export const deleteNote = /* GraphQL */ `
  mutation DeleteNote(
    $input: DeleteNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    deleteNote(input: $input, condition: $condition) {
      id
      name
      description
      image
      createdAt
      updatedAt
    }
  }
`;
