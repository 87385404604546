/* Deploys: Simple */
import React from 'react';
import logo from '../media/logo_black_transparent.png'

const PublicNav = () => {
  return (
    
    <nav className="topNav">
      <a href="/" style={{textDecoration:'none', color:'black'}}>
      <div className="navBrand">
        <img src={logo} alt="Logo" className="navLogo" />
        <span className="brandName">Kintext</span>
      </div>
      </a>
      <div className="navLinks">
      <a href="/#features">Features</a>
        <a href="/gift">Gift</a>
        <a href="/#learn">Learn</a>
      </div>
    </nav>
  );
};

export default PublicNav;