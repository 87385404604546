//TODO add linebreaks before each type of tip etc.

export const pregnancyDailyMessages = {
    "0": `Preconception: It's a period of preparation. Now is the time to focus on a healthy lifestyle, maintaining a balanced diet, and considering prenatal vitamins based on healthcare advice.
Tips for Mom: Start taking 400 micrograms of folic acid daily to prevent birth defects. Quit smoking and avoid alcohol.
Tips for Dad: Support your partner's healthy lifestyle changes. Encourage her and join in making positive choices.
Maternal Health Reminders: Schedule a preconception checkup with your healthcare provider to discuss your health and any concerns.
`,

    "1": `Preconception: Continue focusing on your health. Regular exercise and a balanced diet can improve your fertility and prepare your body for pregnancy.
Tips for Mom: Aim for at least 30 minutes of moderate exercise most days. Choose activities you enjoy.
Tips for Dad: Exercise together with your partner to make it more fun and to support each other.
Maternal Health Reminders: Begin tracking your menstrual cycle to identify your most fertile days.
`,

    "2": `Preconception: Stress management is key. Find relaxation techniques that work for you, such as meditation, yoga, or reading.
Tips for Mom: Practice deep breathing exercises for 5-10 minutes daily to reduce stress levels.
Tips for Dad: Create a calm and supportive environment at home. Help with household chores to reduce your partner's stress.
Maternal Health Reminders: Excess stress can affect your fertility. If you're feeling overwhelmed, talk to your healthcare provider.
`,

    "3": `Preconception: If you haven't already, schedule a preconception visit with your healthcare provider to discuss any necessary lifestyle adjustments or supplements.
Tips for Mom: Write down any questions or concerns you have to discuss with your doctor.
Tips for Dad: Accompany your partner to the preconception visit to learn how you can best support her health.
Maternal Health Reminders: Ensure your vaccinations are up to date, especially rubella and varicella, which can cause birth defects.
`,

    "4": `Preconception: Consider tracking your menstrual cycle to identify your fertile window and increase your chances of conceiving.
Tips for Mom: Use a fertility tracking app or a basal body temperature thermometer to help identify your most fertile days.
Tips for Dad: Learn about the menstrual cycle and fertility signs to better understand the conception process.
Maternal Health Reminders: Remember, even if you're tracking your cycle, healthy couples may take up to a year to conceive.
`,

    "5": `Preconception: A balanced diet rich in fruits, vegetables, whole grains, and lean proteins is essential. Consider adding foods high in folic acid to your diet.
Tips for Mom: Include folate-rich foods like leafy greens, citrus fruits, beans, and fortified bread and cereals in your meals.
Tips for Dad: Cook healthy meals together, focusing on fresh, whole foods to nourish your bodies.
Maternal Health Reminders: A healthy BMI (18.5-24.9) improves your chances of conceiving and having a healthy pregnancy.
`,

    "6": `Preconception: Staying hydrated is important for overall health and can support fertility. Aim for eight glasses of water a day.
Tips for Mom: Carry a reusable water bottle with you to make it easier to stay hydrated throughout the day.
Tips for Dad: Encourage your partner to drink water by joining her in the habit and ensuring there's always fresh water available.
Maternal Health Reminders: Limit caffeine intake to 200mg per day (about 2 cups of coffee) as excessive caffeine may affect fertility.
`, 

    "7": `Preconception: Ensure you're getting enough sleep. Adequate rest can improve your mood and energy levels, contributing to a healthier conception environment.
Tips for Mom: Aim for 7-9 hours of sleep per night. Establish a relaxing bedtime routine to help you wind down.
Tips for Dad: Create a sleep-friendly environment with a comfortable mattress, cool temperature, and dark curtains.
Maternal Health Reminders: Lack of sleep can disrupt hormones that regulate ovulation, so prioritize rest.
`,

    "8": `Preconception: If you smoke or drink alcohol, now is the time to quit. These substances can affect fertility and the health of a future pregnancy.
Tips for Mom: Seek support from your healthcare provider, support groups, or counseling to help you quit smoking and avoid alcohol.
Tips for Dad: If you smoke or drink, consider quitting together with your partner. A strong support system makes it easier.
Maternal Health Reminders: Smoking and alcohol can harm egg and sperm quality, making conception more difficult.
`,

    "9": `Preconception: Begin taking prenatal vitamins, especially folic acid, to reduce the risk of birth defects when you do conceive.
Tips for Mom: Choose a prenatal vitamin with at least 400 micrograms of folic acid and other essential nutrients like iron and calcium.
Tips for Dad: Remind your partner to take her prenatal vitamin daily and ensure she has a consistent supply.
Maternal Health Reminders: Prenatal vitamins are an essential complement to a healthy diet, not a substitute for good nutrition.
`,

    "10": `Preconception: Continue to maintain a healthy weight through exercise and nutrition. Being overweight or underweight can affect your fertility.
Tips for Mom: Incorporate both cardio and strength training exercises into your routine for optimal health.
Tips for Dad: Be an active partner in maintaining a healthy lifestyle. Exercise together and support each other's goals.
Maternal Health Reminders: Rapid weight loss or gain can disrupt hormone levels and ovulation, so aim for gradual, sustainable changes.
`,

    "11": `Preconception: Check your vaccinations to ensure they are up to date for a healthy pregnancy environment.
Tips for Mom: Ensure you're immune to rubella and varicella, which can cause birth defects. Get the flu shot for added protection.
Tips for Dad: Check your own vaccination status and get any necessary boosters to avoid passing infections to your partner.
Maternal Health Reminders: Some vaccines, like MMR, should be given at least one month before conception.
`,

    "12": `Preconception: Limit your caffeine intake. High levels of caffeine can affect fertility and the early stages of pregnancy.
Tips for Mom: Switch to decaf coffee or herbal teas. If you do consume caffeine, keep it under 200mg per day.
Tips for Dad: If you're a coffee drinker, consider cutting back together with your partner to make it easier for her.
Maternal Health Reminders: Excess caffeine can cross the placenta and affect fetal heart rate and sleep patterns.
`,

    "13": `Preconception: Explore relaxation and stress-reduction techniques. High stress levels can impact your ability to conceive.
Tips for Mom: Try yoga, meditation, deep breathing, or massage to manage stress. Find what works best for you.
Tips for Dad: Be supportive and understanding of your partner's stress levels. Help create a calm environment at home.
Maternal Health Reminders: Chronic stress can disrupt ovulation and reduce sperm production, making conception more difficult.
`,

    "14": `Preconception: Reflect on your current medications with your healthcare provider to ensure they are safe for pregnancy.
Tips for Mom: Discuss any prescription, over-the-counter, or herbal medications you're taking with your doctor.
Tips for Dad: Be aware of your partner's medications and any changes she needs to make before conceiving.
Maternal Health Reminders: Some medications can affect fertility or harm fetal development. Always consult with a healthcare professional.
`,

    "15": `Congratulations on beginning your pregnancy journey! Conception has just occurred. It's crucial to continue focusing on your health, take prenatal vitamins, and maintain a balanced diet.
Tips for Mom: If you haven't already, start taking a prenatal vitamin with folic acid to support early fetal development.
Tips for Dad: Show your support and excitement for this new journey. Help your partner maintain a healthy lifestyle.
Maternal Health Reminders: Schedule your first prenatal appointment for about 8 weeks from your last menstrual period.
Baby Size: Your baby is microscopic, about the size of a poppy seed.
Baby Milestones: Conception has occurred, and the fertilized egg is dividing rapidly as it travels towards the uterus.  
`,

    "16": `The fertilized egg is making its way to implant in the uterus. Rest well, stay hydrated, and keep up with a nutritious diet to support your body.
Tips for Mom: Listen to your body and get plenty of rest. Fatigue is common in early pregnancy.
Tips for Dad: Take on extra household duties to allow your partner more time to rest and adjust to the changes in her body.
Maternal Health Reminders: Avoid smoking, alcohol, and excessive caffeine, which can harm fetal development.
Baby Size: Your baby is still microscopic, around 0.1-0.2 millimeters.
Baby Milestones: The fertilized egg continues to divide as it moves through the fallopian tube towards the uterus.
`,

    "17": `Implantation may be happening soon. While you might not notice any physical changes, your body is preparing for pregnancy. Continue to avoid harmful substances and stay well-nourished.
Tips for Mom: Eat small, frequent meals if you're experiencing nausea. Focus on foods rich in folic acid and lean protein.
Tips for Dad: Educate yourself on the early signs of pregnancy to better understand and support your partner.
Maternal Health Reminders: Gentle exercise like walking, swimming, or prenatal yoga can help manage stress and maintain fitness.
Baby Size: Your baby is still very tiny, about the size of a grain of salt.
Baby Milestones: The fertilized egg, now called a blastocyst, is preparing to implant in the uterine wall.
`,

    "18": `The blastocyst begins to implant in the uterine lining. This can cause implantation bleeding. It's a critical time for the embryo's development, so continue focusing on healthy lifestyle choices.
Tips for Mom: If you experience light spotting, don't worry. This is likely implantation bleeding and is normal. Contact your doctor if you have concerns.
Tips for Dad: Be patient and understanding if your partner is feeling more emotional or tired during this time.
Maternal Health Reminders: Start thinking about your prenatal care provider if you haven't already. Schedule your first appointment soon.
Baby Size: Your baby is still microscopic, around 0.1-0.2 millimeters.
Baby Milestones: The blastocyst starts to implant in the uterine wall, a process that can take several days.
`,

    "19": `Implantation continues. You may experience some spotting, which is normal. Focus on gentle exercise and staying relaxed.
Tips for Mom: Practice relaxation techniques like deep breathing or meditation to manage any anxiety or stress.
Tips for Dad: Offer emotional support and reassurance to your partner. Let her know you're there for her.
Maternal Health Reminders: Avoid contact sports or activities with a high risk of falling, which could cause complications.
Baby Size: Your baby is about the size of a poppy seed, around 0.2 millimeters.
Baby Milestones: As implantation progresses, the blastocyst begins to form the amniotic sac and placenta.
`,

    "20": `The blastocyst completes implantation. Your body starts producing hCG, which is detectable by pregnancy tests soon. Continue eating a well-balanced diet and taking prenatal vitamins.
Tips for Mom: Consider taking a home pregnancy test if your period is late. Follow the instructions carefully for accurate results.
Tips for Dad: If the pregnancy test is positive, share in the excitement with your partner. If it's negative and you've been trying for a while, offer support and discuss next steps.
Maternal Health Reminders: Hormonal changes may cause breast tenderness, fatigue, and mood swings. This is normal but talk to your doctor if symptoms are severe.
Baby Size: Your baby is still very tiny, measuring about 0.2 millimeters.
Baby Milestones: Implantation is complete, and the embryo is now securely attached to the uterine wall.
`,

    "21": `With implantation complete, the embryonic stage begins. Though it's still early, ensuring you're getting enough folic acid is crucial for the baby's development.
Tips for Mom: Incorporate folate-rich foods into your diet, like leafy greens, citrus fruits, and fortified cereals, in addition to your prenatal vitamin.
Tips for Dad: Start considering your family's financial future. Research costs related to pregnancy and childcare to begin budgeting.
Maternal Health Reminders: Stay hydrated to support increased blood volume and prevent constipation, a common issue in pregnancy.
Baby Size: Your baby is about the size of a grain of salt, around 0.2 millimeters.
Baby Milestones: The embryo is developing into two layers of cells: the epiblast and the hypoblast, which will form the baby's body and supportive structures.
`,

    "22": `The embryo is very small but growing rapidly. You might not feel different, but your body is doing incredible work. Stay hydrated and rested.
Tips for Mom: Prioritize sleep and rest when you can. Fatigue is a common early pregnancy symptom.
Tips for Dad: Take on more household tasks to allow your partner to rest. Ensure she has a comfortable sleep environment.
Maternal Health Reminders: If you're experiencing morning sickness, try eating small, frequent meals and avoiding triggers like strong smells.
Baby Size: Your baby is about 0.4 millimeters long, the size of a poppy seed.
Baby Milestones: The embryo's cells are rapidly dividing and beginning to form the primitive nervous system.
`,

    "23": `The embryo begins to form structures that will become its organs. It's more important than ever to maintain a nutritious diet and avoid harmful environments.
Tips for Mom: Focus on nutrient-dense foods like lean proteins, whole grains, and colorful fruits and vegetables.
Tips for Dad: Encourage your partner to eat well by preparing healthy meals and snacks. Join her in making nutritious choices.
Maternal Health Reminders: Avoid smoking, alcohol, and exposure to toxic substances, which can harm fetal development.
Baby Size: Your baby is about 1 millimeter long, the size of a sesame seed.
Baby Milestones: The embryo's gastrointestinal tract and early brain structure are beginning to form.
`,

    "24": `The foundations of the baby's nervous system are being laid. Omega-3 fatty acids, found in fish oil and flaxseeds, are beneficial for brain development.
Tips for Mom: Include omega-3 rich foods in your diet, like salmon, chia seeds, and walnuts. Talk to your doctor about safe fish choices.
Tips for Dad: Learn about the benefits of omega-3s for fetal brain development. Help your partner incorporate these nutrients into meals.
Maternal Health Reminders: Begin thinking about genetic screening tests you may want to discuss with your healthcare provider.
Baby Size: Your baby is about 2 millimeters long, the size of a poppy seed.
Baby Milestones: The neural tube, which will form the brain and spinal cord, is developing.
`,

    "25": `Tiny blood vessels are forming, and the heart will soon start beating. This is a good time to start a gentle exercise routine, if you haven't already.
Tips for Mom: Choose low-impact activities like walking, swimming, or prenatal yoga to stay active without overexerting yourself.
Tips for Dad: Offer to exercise with your partner. Go for walks together or join a prenatal fitness class for support.
Maternal Health Reminders: Drink plenty of water before, during, and after exercise to stay hydrated. Stop if you feel dizzy or uncomfortable.
Baby Size: Your baby is about 2.5 millimeters long, the size of a sesame seed.
Baby Milestones: The embryo's circulatory system is beginning to form, and the heart will soon start beating.
`,

    "26": `The embryo is developing its primitive placenta, which will provide nutrients and oxygen. Continue to focus on nutrient-rich foods to support both of your needs.
Tips for Mom: Ensure you're getting enough iron, calcium, and folic acid. These nutrients are crucial for placental and fetal development.
Tips for Dad: Help your partner meet her nutritional needs by learning about key pregnancy nutrients. Assist with meal planning and grocery shopping.
Maternal Health Reminders: If you're feeling fatigued, listen to your body and rest. Naps can be helpful in managing pregnancy exhaustion.
Baby Size: Your baby is about 3 millimeters long, the size of a lentil.
Baby Milestones: The placenta and umbilical cord are beginning to form, creating a support system for the growing embryo.
`,

    "27": `The baby's heart begins to beat, though it's too soon to hear it. Taking time for relaxation and bonding with your partner can be comforting.
Tips for Mom: Practice relaxation techniques like deep breathing, gentle stretching, or prenatal massage to ease stress and anxiety.
Tips for Dad: Spend quality time with your partner. Share your hopes and dreams for your growing family.`
,

"28": `The embryo is now the size of a small seed. Even if it's not visible from the outside, incredible changes are happening inside. Reflect on the miraculous process happening within.
Tips for Mom: Start a pregnancy journal to document your thoughts, feelings, and milestones throughout this journey.
Tips for Dad: Express your love and support for your partner. Let her know you appreciate the amazing work her body is doing.
Maternal Health Reminders: Avoid unpasteurized dairy products, raw or undercooked meats, and high mercury fish to minimize the risk of foodborne illness.
Baby Size: Your baby is about 4 millimeters long, the size of a poppy seed.
Baby Milestones: The embryo's upper and lower limb buds are beginning to form, which will eventually develop into arms and legs.
`,

    "29": `The baby's facial features begin to form, including the mouth, nostrils, and ears. Continue to prioritize a balanced diet and hydration for healthy development.
Tips for Mom: Aim to drink at least 8-10 glasses of water per day to support increased blood volume and amniotic fluid.
Tips for Dad: Encourage your partner to stay hydrated by offering water, herbal tea, or fresh fruits with high water content.
Maternal Health Reminders: Get plenty of rest to cope with fatigue. Go to bed early and take short naps if needed.
Baby Size: Your baby is about 5 millimeters long, the size of an orange seed.
Baby Milestones: The embryo's facial features are beginning to develop, and the eyes and ears are forming.
`,

    "30": `Your baby's brain, spinal cord, and heart are developing. It's essential to continue avoiding any harmful substances and to maintain a healthy lifestyle.
Tips for Mom: Engage in stress-reducing activities like prenatal yoga, meditation, or spending time in nature.
Tips for Dad: Create a calm and supportive environment at home. Help with household chores and offer emotional support.
Maternal Health Reminders: Schedule your first prenatal appointment if you haven't already. Regular check-ups are crucial for monitoring your health and your baby's development.
Baby Size: Your baby is about 6 millimeters long, the size of a sweet pea.
Baby Milestones: The embryo's brain, spinal cord, and heart are rapidly developing. The heart may begin beating.
`,
  
  "31": `The embryo's heart is becoming more complex and now has divided into right and left chambers. Continue your routine of healthy eating, staying active, and regular prenatal check-ups.
Tips for Mom: Keep taking your prenatal vitamins and ensure you're getting sufficient folic acid, iron, and calcium for your baby's development.
Tips for Dad: Attend prenatal appointments with your partner when possible. Hearing your baby's heartbeat is an incredible bonding experience.
Maternal Health Reminders: Wear comfortable shoes and clothing to accommodate your changing body and support healthy circulation.
Baby Size: Your baby is about 7 millimeters long, the size of a blueberry.
Baby Milestones: The embryo's heart is now divided into chambers and beats regularly, pumping blood through the developing body.
`,

  "32": `Your baby's tiny fingers and toes are starting to form, although still webbed. It's important to keep hydrated and maintain a balanced diet rich in vitamins and minerals. 
Tips for Mom: Stay active with gentle exercises like walking or swimming. Regular activity supports a healthy pregnancy.
Tips for Dad: Help your partner stay active by offering to go on walks or attend prenatal fitness classes together.
Maternal Health Reminders: Practice good hygiene, especially regular handwashing, to minimize the risk of infections that could harm the baby.
Baby Size: Your baby is about 8 millimeters long, the size of a kidney bean.
Baby Milestones: The embryo's arms and legs are developing, with fingers and toes beginning to form.
`,

  "33": `The baby's body is beginning to straighten out. Be mindful of your posture and consider prenatal yoga or stretching exercises to alleviate discomfort.
Tips for Mom: Use a supportive pillow to maintain proper alignment while sleeping. A body pillow can help you find a comfortable position.
Tips for Dad: Offer to give your partner a gentle back or foot massage to ease tension and promote relaxation.
Maternal Health Reminders: Stay aware of your posture throughout the day. Stand and sit tall, and avoid slouching to prevent back pain.
Baby Size: Your baby is about 9 millimeters long, the size of a grape.
Baby Milestones: The embryo's body is beginning to uncurl and straighten. The neck is starting to form.
`,

  "34": `The embryo's eyes are developing, though still closed. Ensure your diet includes foods rich in Vitamin A, such as sweet potatoes and carrots, to support healthy eye development.
Tips for Mom: Incorporate beta-carotene rich foods into your meals, like spinach, kale, and bell peppers, to support your baby's eye health.
Tips for Dad: Help your partner prepare nutritious meals that include a variety of colorful fruits and vegetables.
Maternal Health Reminders: Avoid excessive Vitamin A intake from supplements, as this can be harmful. Focus on getting Vitamin A from whole food sources.
Baby Size: Your baby is about 10 millimeters long, the size of a kumquat.
Baby Milestones: The embryo's eyes are forming, with the optic cups developing on the sides of the head.
`,

  "35": `The baby's ears are starting to form, and the inner ear begins to develop. Engaging in calm, soothing activities can benefit both you and your baby.
Tips for Mom: Listen to calming music, read a book, or practice deep breathing exercises to promote a peaceful environment for your baby.
Tips for Dad: Join your partner in quiet, relaxing activities. Talk, sing, or read to your baby to start forming early bonds.
Maternal Health Reminders: Avoid loud, prolonged noises which can cause stress. If you work in a noisy environment, consider using ear protection.
Baby Size: Your baby is about 11 millimeters long, the size of a fig.
Baby Milestones: The embryo's ears are beginning to form, with the inner ear structures developing first.
`,

  "36": `Your baby's skeletal system, including the jawbone, spine, and ribs, is forming. Calcium-rich foods like dairy, leafy greens, and almonds are vital for bone development.
Tips for Mom: Include calcium-rich foods in your diet, aiming for about 1000mg of calcium per day. If you're dairy-free, consider fortified plant milks and dark leafy greens.
Tips for Dad: Encourage your partner to eat calcium-rich foods. Prepare meals and snacks that feature these ingredients.
Maternal Health Reminders: If you're not getting enough calcium from your diet, talk to your healthcare provider about a possible supplement.
Baby Size: Your baby is about 12 millimeters long, the size of a plum.
Baby Milestones: The embryo's skeleton is beginning to form, with the jawbone, spine, and ribs developing.
`,

  "37": `The baby's circulatory system is now functioning, with the heart pumping blood. Gentle exercise, like walking, can support your circulation and overall well-being.
Tips for Mom: Incorporate regular, gentle exercise into your routine. Aim for about 30 minutes of moderate activity most days of the week.
Tips for Dad: Offer to go on walks with your partner or engage in other low-impact activities together, like swimming or prenatal yoga.
Maternal Health Reminders: Listen to your body and don't overexert yourself. If you feel dizzy, short of breath, or experience pain, stop and rest.
Baby Size: Your baby is about 13 millimeters long, the size of a lemon.
Baby Milestones: The embryo's circulatory system is functioning, with the heart pumping blood through the developing blood vessels.
`,

  "38": `Muscle and nerve connections are establishing, allowing for early movements. It's a great time to start talking to your baby, as they're developing the ability to sense vibrations.
Tips for Mom: Talk, sing, or read to your baby regularly. Even though they can't understand the words, they can sense the vibrations and rhythm of your voice.
Tips for Dad: Join in on the conversation with your baby. Share stories, express your love, and create a calming auditory environment.
Maternal Health Reminders: Avoid prolonged periods of sitting or standing. Take breaks to move around and promote healthy circulation.
Baby Size: Your baby is about 14 millimeters long, the size of a peach.
Baby Milestones: The embryo's muscles and nerves are starting to connect, allowing for some early reflex movements.
`,

  "39": `Your baby's liver and spleen are hard at work producing blood cells. Foods rich in iron, like spinach and red meat, can support healthy blood development.
Tips for Mom: Include iron-rich foods in your diet, such as lean meats, legumes, and dark leafy greens. Pair these with Vitamin C sources for better absorption.
Tips for Dad: Help your partner meet her increased iron needs by preparing meals that feature iron-rich ingredients.
Maternal Health Reminders: If you're vegetarian or vegan, talk to your healthcare provider about an iron supplement to prevent deficiency.
Baby Size: Your baby is about 15 millimeters long, the size of a lime.
Baby Milestones: The embryo's liver and spleen are beginning to produce blood cells, supporting the developing circulatory system.
`,

  "40": `The baby's digestive system continues to develop, and the body starts to form a more defined shape. Staying relaxed and stress-free supports your baby's growth.
Tips for Mom: Practice stress-management techniques like deep breathing, meditation, or prenatal yoga to maintain a calm environment for your baby.
Tips for Dad: Offer emotional support to your partner. Listen to her concerns, provide reassurance, and help create a peaceful home environment.
Maternal Health Reminders: Avoid stress where possible. Delegate tasks, set boundaries, and make time for self-care activities that help you relax.
Baby Size: Your baby is about 16 millimeters long, the size of an avocado pit.
Baby Milestones: The embryo's digestive system is developing, and the body is starting to take on a more baby-like shape.
`,

  "41": `Limb buds are growing rapidly, soon to become arms and legs. Maintaining a nutritious diet supports the rapid growth happening now.
Tips for Mom: Focus on eating a balanced diet with plenty of fruits, vegetables, whole grains, and lean proteins to support your baby's growth.
Tips for Dad: Assist with meal planning and preparation to ensure your partner is getting the nutrients she needs.
Maternal Health Reminders: Stay hydrated by drinking water throughout the day. Adequate hydration supports healthy fetal development.
Baby Size: Your baby is about 17 millimeters long, the size of a pomegranate seed.
Baby Milestones: The embryo's limb buds are growing rapidly, with the arms and legs beginning to take shape.
`,

  "42": `Your baby's facial features, such as the nose, mouth, and eyes, are becoming more distinct. Ensure you're getting enough omega-3 fatty acids to support cognitive development.
Tips for Mom: Include omega-3 rich foods in your diet, like fatty fish (salmon, sardines), walnuts, and flaxseeds. These support your baby's brain development.
Tips for Dad: Learn about the importance of omega-3s for fetal brain health. Help your partner incorporate these nutrients into her meals.
Maternal Health Reminders: If you don't eat fish, consider talking to your healthcare provider about an omega-3 supplement suitable for pregnancy.
Baby Size: Your baby is about 18 millimeters long, the size of a prune.
Baby Milestones: The embryo's facial features are becoming more defined, with the eyes, nose, and mouth more distinct.
`,

  "43": `The intestines are forming, and the pancreas begins producing insulin. Eating small, frequent meals can help manage blood sugar levels and support digestive health.
Tips for Mom: Eat smaller, more frequent meals throughout the day to maintain stable blood sugar levels and ease digestive discomfort.
Tips for Dad: Help your partner prepare and pack healthy snacks she can eat throughout the day to maintain her energy levels.
Maternal Health Reminders: If you have a history of diabetes or develop gestational diabetes, work closely with your healthcare provider to manage your blood sugar.
Baby Size: Your baby is about 19 millimeters long, the size of a cherry.
Baby Milestones: The embryo's intestines are developing, and the pancreas is beginning to produce insulin.
`,

  "44": `The baby's tiny heart now has four chambers, and blood circulation is well-established. Monitoring your heart rate during exercise is important to stay within a healthy range.
Tips for Mom: When exercising, aim to keep your heart rate below 140 beats per minute. Use the "talk test" - you should be able to carry on a conversation while active.
Tips for Dad: If your partner is active, offer to join her in low-impact exercises like walking or swimming.
Maternal Health Reminders: Stay aware of warning signs during exercise, such as dizziness, chest pain, or vaginal bleeding. Stop and consult your healthcare provider if these occur.
Baby Size: Your baby is about 20 millimeters long, the size of a strawberry.
Baby Milestones: The embryo's heart now has four distinct chambers, and blood circulation is well-established.
`,

  "45": `Your baby's reproductive organs are developing, although it's still too early to determine the sex. Continuing to avoid harmful substances is crucial for healthy development.
Tips for Mom: Maintain a toxin-free environment by avoiding cigarette smoke, alcohol, and unnecessary chemicals in food and personal care products.
Tips for Dad: Support your partner in creating a healthy environment. Don't smoke around her, and help choose toxin-free household and personal care items.
Maternal Health Reminders: Attend all prenatal appointments and discuss any concerns or questions you have with your healthcare provider.
Baby Size: Your baby is about 21 millimeters long, the size of a grape.
Baby Milestones: The embryo's reproductive organs are beginning to develop, although external genitalia have not yet formed.
`,

  "46": `The embryo is now officially considered a fetus. This marks the beginning of a rapid growth phase. Ensuring you're consuming enough calories supports this growth.
Tips for Mom: Eat when you're hungry, focusing on nutrient-dense foods to support your growing baby. Avoid empty calories from processed snacks.
Tips for Dad: Encourage your partner to listen to her hunger cues and eat when she needs to. Keep healthy snacks readily available.
Maternal Health Reminders: Expect to gain about 1-2 pounds per week in the second trimester. If you're over or under this range, talk to your healthcare provider.
Baby Size: Your baby is about 22 millimeters long, the size of a fig.
Baby Milestones: The embryo is now considered a fetus. This marks the beginning of the fetal period of development.
`,

  "47": `The fetus's external ears are forming, and the eyes have moved to the front of the face. Listening to soothing music can be a relaxing activity for you both.
Tips for Mom: Create a playlist of calming music to listen to during pregnancy. This can help you relax and expose your baby to different sounds.
Tips for Dad: Share in the music listening experience with your partner. Sing or talk to your baby - they're starting to hear your voice!
Maternal Health Reminders: If you're experiencing headaches, try relaxation techniques, proper hydration, and rest. Contact your healthcare provider if headaches are severe or persistent.
Baby Size: Your baby is about 23 millimeters long, the size of a small plum.
Baby Milestones: The fetus's external ears are developing, and the eyes have moved from the sides to the front of the face.
`,

  "48": `Fingernails and toenails are beginning to form. Incorporating biotin-rich foods into your diet can support nail health.
Tips for Mom: Eat biotin-rich foods like eggs, nuts, and legumes to support your baby's nail development and your own nail health.
Tips for Dad: Prepare meals that include biotin-rich ingredients to ensure your partner is getting enough of this important nutrient.
Maternal Health Reminders: If you notice any changes in your vision, like blurriness, report this to your healthcare provider, as it could be a sign of gestational diabetes.
Baby Size: Your baby is about 24 millimeters long, the size of a lime.
Baby Milestones: The fetus's fingernails and toenails are beginning to develop.
`,

	"49": "Your baby's skin is still translucent, but it will soon start to thicken. Drinking plenty of water helps keep your skin hydrated and healthy.",
	"50": `The fetus is now swallowing small amounts of amniotic fluid, which is essential for the development of the digestive system. Continuing to eat a balanced diet supports your amniotic fluid quality.
Tips for Mom: Focus on a diet rich in fruits, vegetables, whole grains, and lean proteins. These nutrient-dense foods support healthy amniotic fluid.
Tips for Dad: Help your partner maintain a balanced diet by grocery shopping together and preparing nutritious meals.
Maternal Health Reminders: If you notice any unusual vaginal discharge or itching, inform your healthcare provider, as these could be signs of infection.
Baby Size: Your baby is about 25 millimeters long, the size of a raspberry.
Baby Milestones: The fetus is now swallowing small amounts of amniotic fluid, which aids in the development of the digestive system.
`,

  "51": `Fine hair called lanugo starts to cover the fetus, helping to regulate body temperature. It's important to keep your environment neither too hot nor too cold.
Tips for Mom: Dress in layers to easily adjust to temperature changes. Aim to keep your environment around 70°F (21°C) for optimal comfort.
Tips for Dad: Help maintain a comfortable temperature at home. Adjust the thermostat as needed and ensure your partner has appropriate clothing.
Maternal Health Reminders: Avoid hot tubs, saunas, and very hot baths, as excessive heat can be harmful to fetal development.
Baby Size: Your baby is about 26 millimeters long, the size of a green olive.
Baby Milestones: Fine hair called lanugo is beginning to cover the fetus's body, helping to regulate its body temperature.
`,

  "52": `Your baby's sense of taste is developing. Eating a variety of foods can expose your baby to different flavors through the amniotic fluid.
Tips for Mom: Include a wide range of flavors in your diet, from sweet to savory. Your baby can taste these flavors in the amniotic fluid.
Tips for Dad: Encourage your partner to try new, healthy foods. Cook meals together that feature diverse flavors.
Maternal Health Reminders: As your uterus grows, you may feel some aches and pains. Gentle stretching and rest can help alleviate discomfort.
Baby Size: Your baby is about 27 millimeters long, the size of a gummy bear.
Baby Milestones: The fetus's sense of taste is developing, and it can detect different flavors in the amniotic fluid.
`,

  "53": `The fetus is beginning to practice breathing movements, although the lungs are not yet fully developed. Practicing deep breathing can be beneficial for your health and relaxation.
Tips for Mom: Incorporate deep breathing exercises into your daily routine. This can help manage stress and prepare for labor breathing techniques.
Tips for Dad: Join your partner in deep breathing exercises. This can be a bonding activity and help you both relax.
Maternal Health Reminders: As your belly grows, you may notice some skin itching. Moisturizing and staying hydrated can help alleviate this.
Baby Size: Your baby is about 28 millimeters long, the size of a grape tomato.
Baby Milestones: The fetus is starting to practice breathing movements, although the lungs are not yet fully developed.
`,

  "54": `The baby's body is growing, and the head is still disproportionately large compared to the rest of the body. Maintaining good posture can help alleviate back pain.
Tips for Mom: Be mindful of your posture. Stand up straight, sit with your shoulders back, and use a supportive pillow when sleeping.
Tips for Dad: Encourage your partner to maintain good posture. Offer to give gentle back rubs or provide supportive pillows.
Maternal Health Reminders: As your center of gravity shifts, you may feel off-balance. Take your time when standing up or walking to avoid falls.
Baby Size: Your baby is about 29 millimeters long, the size of a Brussels sprout.
Baby Milestones: The fetus's body is growing, but the head is still disproportionately large compared to the rest of the body.
`,

  "55": `Eyelids are now fully formed but remain closed. Ensuring that your diet is rich in Vitamin E supports eye health and development.
Tips for Mom: Include Vitamin E-rich foods in your diet, such as nuts, seeds, and vegetable oils. These nutrients support your baby's eye development.
Tips for Dad: Help your partner incorporate Vitamin E-rich foods into her meals. Sprinkle nuts or seeds on salads or yogurt for an easy nutrient boost.
Maternal Health Reminders: You may start to feel your baby's movements, called "quickening," around this time. This feels like flutters or butterflies in your belly.
Baby Size: Your baby is about 30 millimeters long, the size of a small plum.
Baby Milestones: The fetus's eyelids are now fully formed but remain closed. The eyes are continuing to develop underneath.
`,

  "56": `The skeletal system continues to harden from cartilage to bone. Vitamin D is essential for this process, so consider spending some time outdoors for natural sunlight exposure.
Tips for Mom: Spend some time outside each day, as sunlight helps your body produce Vitamin D. Just remember to protect your skin from excessive sun exposure.
Tips for Dad: Offer to go on outdoor walks or activities with your partner. The fresh air and sunlight are good for you both.
Maternal Health Reminders: If you're experiencing leg cramps, stay hydrated, stretch regularly, and consider a magnesium supplement with your healthcare provider's approval.
Baby Size: Your baby is about 31 millimeters long, the size of a large blueberry.
Baby Milestones: The fetus's skeletal system is continuing to harden from soft cartilage to bone.
`,

  "57": `The fetus is becoming more active, though you might not feel its movements yet. Staying active yourself can improve your physical well-being and mood.
Tips for Mom: Continue engaging in regular, moderate exercise. Swimming, walking, and prenatal yoga are great low-impact options.
Tips for Dad: Encourage your partner to stay active by joining her in exercise routines. Go on walks or attend prenatal fitness classes together.
Maternal Health Reminders: As your blood volume increases, you may feel warmer than usual. Dress in breathable layers and stay hydrated to stay comfortable.
Baby Size: Your baby is about 32 millimeters long, the size of a kiwi fruit.
Baby Milestones: The fetus is becoming more active, with increased movements in the amniotic fluid.
`,

  "58": `Your baby's brain is developing rapidly, with new neurons forming every minute. Foods high in DHA, such as salmon, can support brain health.
Tips for Mom: Include DHA-rich foods in your diet, like fatty fish, fortified eggs, or algae-based supplements. These support your baby's brain development.
Tips for Dad: Learn about the importance of DHA for fetal brain health. Help your partner incorporate these nutrients into her meals.
Maternal Health Reminders: If you're feeling forgetful or experiencing "pregnancy brain," don't worry. This is a common symptom due to hormonal changes.
Baby Size: Your baby is about 33 millimeters long, the size of a small fig.
Baby Milestones: The fetus's brain is developing rapidly, with new neurons forming every minute.
`,

  "59": `The muscles continue to develop, and the fetus will soon start making more noticeable movements. Staying flexible through stretching or prenatal yoga can be beneficial.
Tips for Mom: Incorporate gentle stretching into your daily routine. This can help alleviate muscle aches and improve flexibility.
Tips for Dad: Join your partner in stretching exercises or prenatal yoga classes. This can be a bonding experience and help you both stay flexible.
Maternal Health Reminders: As your uterus expands, you may feel some round ligament pain. This is normal but contact your healthcare provider if the pain is severe.
Baby Size: Your baby is about 34 millimeters long, the size of a small peach.
Baby Milestones: The fetus's muscles are developing, and it will soon start making more noticeable movements.
`,

  "60": `The fetus's skin is developing layers, including the dermis and epidermis. Keeping hydrated supports healthy skin development for both of you.
Tips for Mom: Drink plenty of water throughout the day to stay hydrated. Aim for at least 8-10 glasses daily.
Tips for Dad: Encourage your partner to stay hydrated by offering water frequently and keeping a filled water bottle nearby.
Maternal Health Reminders: As your skin stretches, you may develop itchy, red marks called stretch marks. Keeping your skin moisturized can help reduce their appearance.
Baby Size: Your baby is about 35 millimeters long, the size of a small lemon.
Baby Milestones: The fetus's skin is developing layers, including the dermis and epidermis.
`,
"61": `The fetus's liver begins producing bile, which plays a crucial role in digestion. Ensuring your diet includes balanced nutrients supports your baby's organ development. Tips for Mom: Eat a balanced diet with a variety of fruits, vegetables, whole grains, lean proteins, and healthy fats to provide the nutrients your baby needs. Tips for Dad: Help your partner maintain a balanced diet by preparing nutritious meals and snacks. Offer healthy food options and encourage her to listen to her body's needs. Maternal Health Reminders: As your uterus grows, you may feel some discomfort or pressure in your pelvis. Resting with your feet elevated can help alleviate this. Baby Size: Your baby is about 36 millimeters long, the size of a small lime. Baby Milestones: The fetus's liver is beginning to produce bile, which plays a crucial role in digestion.`,

"62": `The fetus's taste buds are developing, and it can now detect sweet flavors in the amniotic fluid. Indulging in the occasional sweet treat is okay, but focus on overall healthy eating habits. Tips for Mom: While it's okay to enjoy some sweet treats, prioritize nutrient-dense foods that provide the building blocks for your baby's growth and development. Tips for Dad: Support your partner's healthy eating habits by keeping nutritious snacks readily available and joining her in making balanced food choices. Maternal Health Reminders: You may experience some nasal congestion due to increased blood flow. Using a humidifier or saline nasal spray can help provide relief. Baby Size: Your baby is about 37 millimeters long, the size of a small apricot. Baby Milestones: The fetus's taste buds are developing, and it can now detect sweet flavors in the amniotic fluid.` ,

"63": `The fetus's fingerprints are forming, making each tiny hand unique. Taking time for self-care, like a hand massage, can be a relaxing and bonding activity. Tips for Mom: Take a moment to massage your hands, focusing on each finger. This can be a calming and mindful practice during pregnancy. Tips for Dad: Offer to give your partner a gentle hand massage. This can be a bonding experience and a way to show your support and affection. Maternal Health Reminders: As your blood volume increases, you may feel some dizziness or lightheadedness. Take your time when standing up and stay hydrated to help minimize these sensations. Baby Size: Your baby is about 38 millimeters long, the size of a small pear. Baby Milestones: The fetus's fingerprints are forming, making each tiny hand unique.` ,

"64": `The fetus's ears are developing, and it can now hear muffled sounds from the outside world. Talking, singing, or playing music can be a way to bond with your baby. Tips for Mom: Talk, sing, or play music to your baby. Even though the sounds are muffled, your baby can hear and begin to recognize your voice. Tips for Dad: Join in the bonding experience by talking, singing, or playing music to your partner's belly. Your baby will start to recognize your voice too. Maternal Health Reminders: You may start to experience some back pain as your uterus grows and your center of gravity shifts. Gentle stretching, good posture, and supportive shoes can help alleviate discomfort. Baby Size: Your baby is about 39 millimeters long, the size of a small plum. Baby Milestones: The fetus's ears are developing, and it can now hear muffled sounds from the outside world.`, 
"65": `The fetus's hair is starting to grow, though the color and texture may change after birth. Prenatal vitamins with folic acid support healthy hair growth for both you and your baby. Tips for Mom: Continue taking your prenatal vitamins as directed by your healthcare provider. The folic acid helps support your baby's hair growth and overall development. Tips for Dad: Remind your partner to take her prenatal vitamins daily. You can also help by ensuring she has a consistent supply of the vitamins. Maternal Health Reminders: Your growing uterus may start to put pressure on your bladder, leading to more frequent bathroom trips. This is normal but stay hydrated and empty your bladder regularly to avoid discomfort. Baby Size: Your baby is about 40 millimeters long, the size of a small nectarine. Baby Milestones: The fetus's hair is starting to grow, though the color and texture may change after birth.` ,

"66": `The fetus's eyebrows and eyelashes are forming, adding detail to its cute little face. Taking breaks to rest your eyes can help alleviate strain and dryness. Tips for Mom: Remember to give your eyes a break, especially if you spend a lot of time looking at screens. Follow the 20-20-20 rule: every 20 minutes, look at something 20 feet away for 20 seconds. Tips for Dad: Encourage your partner to take eye breaks and offer to help with tasks that may cause eye strain, like reading or computer work. Maternal Health Reminders: You may notice some changes in your vision due to hormonal shifts. This is usually temporary, but consult your healthcare provider if you have concerns. Baby Size: Your baby is about 41 millimeters long, the size of a small apple. Baby Milestones: The fetus's eyebrows and eyelashes are forming, adding detail to its cute little face.` ,

"67": `The fetus's sucking reflex is developing, preparing for feeding after birth. Practicing self-soothing techniques like deep breathing can be beneficial for both you and your baby. Tips for Mom: Practice deep breathing exercises to help calm and center yourself. This can be a useful skill for managing stress and discomfort during pregnancy and labor. Tips for Dad: Join your partner in deep breathing exercises. This can be a bonding experience and help you both develop coping strategies for stress. Maternal Health Reminders: You may start to experience Braxton Hicks contractions, which are practice contractions that help prepare your uterus for labor. These are normal but contact your healthcare provider if they become regular or painful. Baby Size: Your baby is about 42 millimeters long, the size of a small mango. Baby Milestones: The fetus's sucking reflex is developing, preparing for feeding after birth.` ,

"68": `The fetus's immune system is developing, with antibodies being passed from you to your baby. Maintaining a balanced diet and staying up-to-date with recommended vaccines supports both of your immune health. Tips for Mom: Continue eating a balanced diet rich in fruits, vegetables, and lean proteins to support your immune system and your baby's developing immune system. Tips for Dad: Support your partner's immune health by preparing nutritious meals and snacks. Also, ensure you're up-to-date with your own recommended vaccines to help protect your growing family. Maternal Health Reminders: As your uterus grows, you may experience some rib pain or discomfort. Gentle stretching and using a support pillow can help alleviate this. Baby Size: Your baby is about 43 millimeters long, the size of a small avocado. Baby Milestones: The fetus's immune system is developing, with antibodies being passed from the mother to the baby.` ,

"69": `The fetus's brain is developing rapidly, with new neural connections forming every day. Engaging in mentally stimulating activities, like reading or puzzles, can support your own brain health. Tips for Mom: Keep your mind active by engaging in mentally stimulating activities you enjoy, such as reading, puzzles, or learning a new skill. Tips for Dad: Join your partner in mentally engaging activities. You could read together, work on a puzzle, or learn something new as a couple. Maternal Health Reminders: You may start to experience some forgetfulness or "pregnancy brain." This is normal due to hormonal changes and can be managed with lists, reminders, and support from your partner. Baby Size: Your baby is about 44 millimeters long, the size of a small peach. Baby Milestones: The fetus's brain is developing rapidly, with new neural connections forming every day.` ,

"70": `The fetus's eyes are becoming more sensitive to light, though the eyelids remain closed. Exposing yourself to natural daylight can help regulate your sleep-wake cycle and improve your mood. Tips for Mom: Spend some time outdoors in natural daylight each day, as this can help regulate your circadian rhythm and boost your mood. Tips for Dad: Encourage your partner to get some natural light exposure daily. Offer to go on outdoor walks or enjoy meals together outside when weather permits. Maternal Health Reminders: As your skin stretches to accommodate your growing baby, you may experience some itching. Keeping your skin moisturized and staying hydrated can help alleviate this discomfort. Baby Size: Your baby is about 45 millimeters long, the size of a small potato. Baby Milestones: The fetus's eyes are becoming more sensitive to light, though the eyelids remain closed.` ,

"71": `The fetus's digestive system is practicing contractions, preparing for processing food after birth. Supporting your own digestive health with fiber-rich foods and staying hydrated is important. Tips for Mom: Include plenty of fiber-rich foods in your diet, such as fruits, vegetables, and whole grains, to support healthy digestion for both you and your baby. Tips for Dad: Help your partner maintain good digestive health by preparing meals that include fiber-rich ingredients and ensuring she stays well-hydrated. Maternal Health Reminders: You may experience some heartburn or acid reflux as your growing uterus puts pressure on your stomach. Eating smaller, more frequent meals and avoiding triggering foods can help manage these symptoms. Baby Size: Your baby is about 46 millimeters long, the size of a small pear. Baby Milestones: The fetus's digestive system is practicing contractions, preparing for processing food after birth.` ,

"72": `The fetus's skin is developing a protective coating called vernix caseosa, which helps shield the skin from the amniotic fluid. Keeping your own skin moisturized can help prevent itching and discomfort. Tips for Mom: Use a gentle, fragrance-free moisturizer to keep your skin hydrated and reduce itching as it stretches to accommodate your growing baby. Tips for Dad: Help your partner apply moisturizer to hard-to-reach areas, like her lower back or feet. This can be a bonding moment and a way to show your support. Maternal Health Reminders: You may notice some changes in your skin, such as darkening of the nipples or a line down your abdomen (linea nigra). These changes are normal and usually fade after delivery. Baby Size: Your baby is about 47 millimeters long, the size of a small banana. Baby Milestones: The fetus's skin is developing a protective coating called vernix caseosa, which helps shield the skin from the amniotic fluid.` ,

"73": `The fetus's bones are hardening, although the skull remains soft to allow for passage through the birth canal. Ensuring your diet includes calcium-rich foods supports both your and your baby's bone health. Tips for Mom: Include calcium-rich foods in your diet, such as dairy products, leafy greens, and fortified plant-based milks, to support your baby's developing bones and your own bone health. Tips for Dad: Encourage your partner to eat calcium-rich foods and join her in making bone-healthy food choices. You can also help by ensuring she takes her prenatal vitamins, which include calcium. Maternal Health Reminders: As your posture changes to accommodate your growing baby, you may experience some back or pelvic pain. Gentle exercises, stretching, and using a support belt can help alleviate discomfort. Baby Size: Your baby is about 48 millimeters long, the size of a small bell pepper. Baby Milestones: The fetus's bones are hardening, although the skull remains soft to allow for passage through the birth canal.` ,

"74": `The fetus's lungs are developing rapidly, with the branches of the respiratory tree forming. Practicing deep breathing exercises can help prepare you for labor and delivery. Tips for Mom: Incorporate deep breathing exercises into your daily routine. This can help you stay calm, manage discomfort, and prepare for breathwork during labor. Tips for Dad: Join your partner in practicing deep breathing exercises. This can be a bonding activity and help you both feel more prepared for the birthing process. Maternal Health Reminders: You may start to experience some swelling in your feet and ankles due to increased blood volume and pressure from your growing uterus. Elevating your feet, staying active, and wearing comfortable shoes can help reduce swelling. Baby Size: Your baby is about 49 millimeters long, the size of a small orange. Baby Milestones: The fetus's lungs are developing rapidly, with the branches of the respiratory tree forming.` ,

"75": `The fetus's hair is growing longer, and it may even have a distinct color. Taking prenatal vitamins with biotin can support healthy hair growth for both you and your baby. Tips for Mom: Continue taking your prenatal vitamins, which include biotin for healthy hair growth. You may also want to use a gentle, nourishing shampoo and conditioner to keep your own hair healthy. Tips for Dad: Offer support and encouragement if your partner is experiencing any hair changes, such as increased shedding or dryness. Help her feel confident and beautiful during this time of change. Maternal Health Reminders: As your baby grows, you may find it more challenging to find a comfortable sleeping position. Using a pregnancy pillow or placing a pillow between your knees can help align your hips and provide comfort. Baby Size: Your baby is about 50 millimeters long, the size of a small grapefruit. Baby Milestones: The fetus's hair is growing longer, and it may even have a distinct color.` ,

"76": `The fetus's sleep patterns are becoming more regular, with periods of activity and rest. Establishing your own consistent sleep routine can help you feel more rested and energized. Tips for Mom: Create a relaxing bedtime routine to help signal to your body that it's time to sleep. This may include a warm bath, reading, or gentle stretching. Tips for Dad: Support your partner's healthy sleep habits by maintaining a calm, quiet environment in the evenings and helping with any nighttime discomforts she may experience. Maternal Health Reminders: You may experience some shortness of breath as your growing uterus puts pressure on your diaphragm. Practicing good posture and taking slow, deep breaths can help manage this symptom. Baby Size: Your baby is about 51 millimeters long, the size of a small corn cob. Baby Milestones: The fetus's sleep patterns are becoming more regular, with periods of activity and rest.` ,

"77": `The fetus's movements are becoming stronger and more coordinated. Taking time to bond with your baby through gentle belly touches and talking can be a special experience. Tips for Mom: Take a few moments each day to connect with your baby. Gently touch your belly, talk to your little one, or play soothing music. Tips for Dad: Join in the bonding experience by talking to the baby and feeling for kicks and movements. This can be a special time for you to connect with your growing child. Maternal Health Reminders: As your baby grows, you may find that you need to urinate more frequently. This is normal but be sure to stay hydrated and empty your bladder regularly to avoid discomfort or urinary tract infections. Baby Size: Your baby is about 52 millimeters long, the size of a small cucumber. Baby Milestones: The fetus's movements are becoming stronger and more coordinated.` ,

"78": `The fetus's brain is developing rapidly, with the connections between nerve cells becoming more complex. Engaging in mentally stimulating activities can support your own brain health during pregnancy. Tips for Mom: Keep your mind active by engaging in activities you enjoy, such as reading, puzzles, or learning a new skill. This can help support your own brain health and cognitive function. Tips for Dad: Encourage your partner to engage in mentally stimulating activities and join her when possible. You can also support her by taking on tasks that may be mentally taxing or stressful. Maternal Health Reminders: As your center of gravity shifts, you may experience some balance changes. Take your time when changing positions and wear supportive shoes to reduce the risk of falls. Baby Size: Your baby is about 53 millimeters long, the size of a small zucchini. Baby Milestones: The fetus's brain is developing rapidly, with the connections between nerve cells becoming more complex.` ,

"79": `The fetus's taste buds are fully developed, and it can now taste different flavors in the amniotic fluid. Eating a variety of healthy foods can expose your baby to diverse tastes. Tips for Mom: Include a range of flavors in your diet, from sweet to savory, to expose your baby to different tastes through the amniotic fluid. Focus on healthy, nutrient-dense foods for optimal nutrition. Tips for Dad: Support your partner's healthy eating habits by preparing meals that include a variety of flavors and nutrients. Encourage her to try new, healthy foods and join her in expanding your own palate. Maternal Health Reminders: You may experience some leg cramps, especially at night. Stretching, staying hydrated, and engaging in regular physical activity can help alleviate these cramps. Baby Size: Your baby is about 54 millimeters long, the size of a small eggplant. Baby Milestones: The fetus's taste buds are fully developed, and it can now taste different flavors in the amniotic fluid.` ,

"80": `The fetus's fingernails and toenails are growing, adding the final touches to those tiny digits. Taking care of your own nails can be a relaxing self-care activity during pregnancy. Tips for Mom: Take some time for self-care by giving yourself a gentle manicure or pedicure. Use non-toxic, pregnancy-safe nail products and avoid cutting your nails too short to prevent ingrown nails. Tips for Dad: Offer to help your partner with nail care, especially if she's having difficulty reaching her toes. This can be a bonding activity and a way to show your support. Maternal Health Reminders: As your skin stretches, you may develop stretch marks on your belly, breasts, or thighs. While these are a normal part of pregnancy, keeping your skin moisturized can help reduce their appearance. Baby Size: Your baby is about 55 millimeters long, the size of a small sweet potato. Baby Milestones: The fetus's fingernails and toenails are growing, adding the final touches to those tiny digits.`,
	"81": "Your baby's skin is starting to become less transparent as it develops more layers. Staying well-nourished helps support this growth.",
	"82": "The fetus is sleeping in regular cycles, which might not match your own. Creating a calm, relaxing bedtime routine can be beneficial for your sleep too.",
	"83": "Your baby's brain is forming the grooves and indentations that characterize a mature brain. Foods rich in DHA support this complex development.",
	"84": "The fetus's taste buds are fully formed, and it can taste the food you eat through the amniotic fluid. A varied diet can stimulate your baby's senses.",
	"85": "The baby is now producing meconium, which will become its first bowel movement. Eating fiber-rich foods can support your digestive health and prepare you for postpartum recovery.",
	"86": "Your baby's fingernails have reached the tips of its fingers. Monitoring your iron intake can help prevent anemia and support healthy nail growth.",
	"87": "The fetus's lungs are developing surfactant, which will help them inflate after birth. Gentle breathing exercises can support your lung health and relaxation.",
	"88": "The baby's brain and nervous system are developing rapidly. Ensuring you get enough sleep supports your brain health and mood.",
	"89": "Your baby's muscles are strengthening, and it may begin to turn and kick more forcefully. Keeping active with appropriate exercises can help manage discomfort.",
	"90": "The fetus's skin is starting to smooth out as it gains fat. Maintaining a balanced diet with healthy fats supports both your health and your baby's development.",
	"91": "The baby's cartilage is turning into bone, a process known as ossification. Foods rich in calcium and vitamin D support this important development.",
	"92": "Your baby's stomach is starting to produce digestive juices, preparing for life outside the womb. Eating a well-balanced diet aids in the development of the baby's digestive system.",
	"93": "The fetus's kidneys are now able to produce urine, which is released into the amniotic fluid. Staying hydrated is important for both your and your baby's kidney function.",
	"94": "Your baby's body is beginning to store fat, which will help regulate body temperature after birth. Including healthy fats in your diet, like avocados and nuts, is beneficial.",
	"95": "The baby's hair continues to grow, and you might be able to see its color on an ultrasound. Consuming sufficient protein supports healthy hair growth.",
	"96": "Your baby can now blink, and its eyelashes have formed. Ensuring you get plenty of rest helps your body manage the changes of pregnancy.",
	"97": "The fetus's sense of hearing is improving, and it may startle at loud sounds. Speaking and playing music can stimulate your baby's auditory development.",
	"98": "Your baby's skin is thickening and developing layers, including the epidermis and dermis. Eating foods high in vitamins C and E supports skin health.",
	"99": "The baby's lungs are developing branches of the respiratory tree and cells that produce surfactant. Practicing deep breathing exercises can benefit your respiratory system.",
	"100": "The fetus can grasp with its hands now, practicing movements it will use after birth. Engaging in activities that relax you can also soothe your baby.",
	"101": "Your baby's body is growing rounder as fat accumulates. This fat is essential for temperature regulation and metabolism after birth.",
	"102": "The baby's irises can now dilate and contract in response to light. Regular sleep patterns can help regulate your circadian rhythms, beneficial for both of you.",
	"103": "Your baby's fingernails now cover the fingertips and may soon need a trim after birth. Consuming biotin-rich foods can support nail health.",
	"104": "The fetus's sense of taste is fully developed. Eating a varied diet can expose your baby to different flavors through the amniotic fluid.",
	"105": "The baby's brain is very active, as are its hearing and sight, even though the eyes are often closed. Reading and talking to your baby can be beneficial.",
	"106": "Your baby's bones are continuing to harden, with the skull remaining soft and pliable for birth. Calcium-rich foods are vital for bone development.",
	"107": "The fetus is practicing breathing by inhaling and exhaling small amounts of amniotic fluid. This helps develop the diaphragm for breathing after birth.",
	"108": "Your baby's fat layers are thickening, which will help keep them warm after birth. Omega-3 fatty acids are important for this development.",
	"109": "The baby's muscle tone is improving, making movements more coordinated. Gentle movement on your part, like walking, can stimulate fetal activity.",
	"110": "The fetus's skin is less wrinkly as more fat accumulates. Staying hydrated helps maintain your skin elasticity and supports fetal growth.",
	"111": "Your baby can now turn its head and make sucking movements with its mouth. These reflexes prepare the baby for feeding after birth.",
	"112": "The baby's digestive system is continuing to mature, although it won’t be fully functional until after birth. Eating fiber-rich foods supports your digestive health.",
	"113": "The fetus is growing more hair on its head, and you might be curious about its color and texture. Your nutrition can impact your baby's overall health, including hair growth.",
	"114": "Your baby's lungs are maturing and getting ready for breathing outside the womb. The baby responds to rhythms, so listening to music can be soothing for both.",
	"115": "The baby's sleep now includes the REM phase, which means it could be dreaming! Establishing a calming bedtime routine might improve your sleep quality too.",
	"116": "Your baby's fingernails have reached the ends of their fingers and may extend beyond them. Ensuring a diet rich in vitamins and minerals supports healthy growth.",
	"117": "The baby is gaining weight rapidly, which is crucial for healthy development. Balanced meals with plenty of nutrients are important at this stage.",
	"118": "The fetus's brain is forming more complex structures, facilitating learning and memory. Foods high in omega-3 fatty acids support this brain development.",
	"119": "Your baby's skin is becoming softer and smoother as fat accumulates underneath. Moisturizing regularly can help keep your skin comfortable as it stretches.",
	"120": "The baby is becoming increasingly active, with kicks and movements more noticeable. Regular physical activity is beneficial for your health and prepares you for childbirth.",	
	"121": "The baby's body continues to fill out with fat, making the skin less transparent. Eating a diet with healthy fats, like avocados and olive oil, is beneficial.",
	"122": "Your baby's taste buds are fully developed now, and they might even show a preference for certain tastes. Variety in your diet can introduce your baby to different flavors.",
	"123": "The baby's brain is rapidly growing, increasing in size and complexity. Foods rich in DHA, like salmon, support this cognitive development.",
	"124": "The fetus's hair, including eyebrows and eyelashes, is becoming thicker. Protein-rich foods support the growth of healthy hair and tissues.",
	"125": "Your baby's movements are becoming stronger and more frequent. These movements are important for muscle and bone strengthening.",
	"126": "The baby's lungs are developing alveoli, essential for oxygen exchange after birth. Maintaining good air quality in your home is important for both of you.",
	"127": "Your baby's ears are now fully functional, and they can react to sounds. Playing music and talking to your baby can stimulate their auditory development.",
	"128": "The fetus is practicing swallowing, which is crucial for digestion and lung development. Staying hydrated helps maintain a healthy level of amniotic fluid.",
	"129": "The baby's skin is starting to produce melanin, which gives skin its color. Vitamin D from sunlight exposure is beneficial, but remember to protect your skin.",
	"130": "Your baby's fingernails may now reach the tips of the fingers. A balanced diet supports healthy nail growth and overall fetal development.",
	"131": "The fetus's fat layers continue to develop, which will help regulate body temperature and metabolism after birth. Consuming omega-3 fatty acids is beneficial for fat development.",
	"132": "Your baby can now detect light and may move away or towards it. This response to light is a sign of healthy eye development.",
	"133": "The baby's skeleton is hardening, with the bones of the arms and legs strengthening. Calcium-rich foods are crucial for bone development.",
	"134": "The fetus's brain development is intensive, with billions of neurons forming. Eating foods high in omega-3 fatty acids supports brain health.",
	"135": "Your baby's immune system is developing, with the thymus gland producing T cells. A diet rich in fruits and vegetables can boost your immune system.",
	"136": "The baby's circulatory system is now capable of adapting to life outside the womb. Gentle exercise can improve your circulation and overall health.",
	"137": "The fetus is growing more hair on its head, and body fat continues to accumulate. Ensure you're getting enough protein to support this growth.",
	"138": "Your baby's reflexes, such as sucking and swallowing, are developing. These reflexes are crucial for feeding after birth.",
	"139": "The baby's respiratory system continues to mature, with the development of the bronchioles and alveoli. Breathing exercises can be beneficial for your lung health.",
	"140": "The fetus's movements are becoming more coordinated. These movements are essential for muscle strength and joint flexibility.",
	"141": "Your baby's liver and spleen are hard at work producing blood cells. Foods high in iron, like spinach and lean meats, can support healthy blood development.",
	"142": "The baby's sense of touch is developing, and they may start to explore their face and body with their hands. Gentle rubbing of your belly can sometimes prompt a response.",
	"143": "The fetus is accumulating more fat, leading to smoother, less wrinkled skin. Hydration and balanced nutrition support skin health.",
	"144": "Your baby's bone marrow has started to produce red blood cells, a function it will take over completely after birth. Iron-rich foods are important for red blood cell production.",
	"145": "The baby's eyes are still fused shut but will begin to open soon. Vitamin A-rich foods support healthy eye development.",
	"146": "The fetus's ears are better developed, and it may respond to your voice by moving or increasing its heartbeat. Talking to your baby can enhance bonding.",
	"147": "Your baby's body is continuing to grow, and movements are more noticeable. Monitoring your baby's activity patterns can be an enjoyable way to bond.",
	"148": "The baby's nervous system is maturing, with ongoing development of the brain and nerves. A calm and stress-free environment benefits both you and your baby.",
	"149": "The fetus's skin is developing a protective coating, called vernix caseosa, to protect it from the amniotic fluid. Eating healthy fats supports skin health.",
	"150": "Your baby's muscular and skeletal systems are getting stronger, making their movements more powerful. Regular physical activity is good for your health and prepares you for childbirth.",
	"151": "Your baby's skin is becoming less translucent as it continues to develop layers and accumulate fat. A balanced diet rich in vitamins and minerals supports your baby's skin health.",
	"152": "The fetus is now developing a regular sleep and wake cycle, which might not align with yours. Rest when you can to ensure you both get the sleep you need.",
	"153": "Your baby's movements are becoming stronger and more frequent, a sign of healthy development. Documenting these movements can help you bond with your baby.",
	"154": "The baby's skeletal structure continues to strengthen, with the bones absorbing more calcium. Dairy products, leafy greens, and fortified foods can boost your calcium intake.",
	"155": "The fetus's ears are now fully developed, and it can hear a wide range of sounds. Speaking, reading aloud, and playing music can stimulate your baby's hearing.",
	"156": "Your baby's eyes are developing, and while the eyelids remain closed, the eyes can move. Ensuring you get enough omega-3 fatty acids supports eye health.",
	"157": "The baby's body is starting to prepare for breathing air. Its chest moves as it practices inhaling and exhaling. Practicing breathing exercises can be beneficial for you too.",
	"158": "The fetus's hair continues to grow, and you might start wondering about its color and texture. A healthy diet supports all aspects of your baby's development.",
	"159": "Your baby's sense of taste is developing further, and it may react to strong flavors in the amniotic fluid. Eating a varied diet can introduce your baby to different tastes.",
	"160": "The baby's fingernails have fully formed, and they might even scratch themselves. Keeping your own nails trimmed and smooth can remind you to care for your baby's nails after birth.",
	"161": "The fetus's brain is rapidly developing, forming the structures that will support thinking and memory. Foods rich in DHA, like salmon, are great for brain development.",
	"162": "Your baby's skin is forming a protective layer called vernix caseosa, which prevents wrinkling from the amniotic fluid. Staying hydrated helps maintain healthy skin for both of you.",
	"163": "The baby is gaining weight, mostly in the form of muscle and bone density, along with increasing fat stores. Eating protein-rich foods supports this growth.",
	"164": "The fetus's lungs are maturing, and it practices breathing movements more frequently. Gentle exercise, like walking, can improve your cardiovascular health.",
	"165": "Your baby's movements may begin to follow a pattern as its central nervous system matures. Paying attention to these movements can help you understand your baby's active and rest times.",
	"166": "The vernix caseosa thickens, providing more protection for your baby's skin. Including healthy fats in your diet supports your baby's fat development.",
	"167": "The baby's cartilage is increasingly being replaced by bone, a process that will continue after birth. Calcium-rich foods are essential for strong bone development.",
	"168": "Your baby's digestive system continues to mature, preparing for feeding after birth. Consuming probiotic-rich foods like yogurt can benefit your digestive system.",
	"169": "The fetus's sense of smell is developing, even though it's still in the womb. Keeping your environment free from strong or harmful odors is a good practice.",
	"170": "The baby's brain and nerve endings are developed enough to process touch sensations. Massaging your belly gently can sometimes prompt a response from your baby.",
	"171": "The baby's body is starting to store vital minerals, such as iron and calcium, in preparation for birth. Foods rich in iron, like red meat and spinach, are important in the third trimester.",
	"172": "Your baby's eyebrows and eyelashes are well-defined, and more hair is growing on the head. Eating well supports the health of both your and your baby's hair.",
	"173": "The fetus is becoming plumper as fat deposits continue to build, helping with temperature regulation and metabolism. Balanced meals support healthy weight gain.",
	"174": "Your baby's adrenal glands are developing, which will produce hormones that support metabolism and stress response. Managing stress and eating nutrient-rich foods are beneficial.",
	"175": "The baby's hearing is fully developed, and it may react to sounds by moving or kicking. Regular conversations can help you bond with your baby.",
	"176": "The fetus's movements are more forceful now, and you might even see your belly move. Tracking these movements can be an exciting way to connect with your baby.",
	"177": "Your baby's sleep now includes the REM phase, important for brain development. Ensuring you get plenty of rest supports your health and well-being.",
	"178": "The baby's immune system is developing, preparing to function outside the womb. Eating foods rich in vitamins C and E can support your immune system.",
	"179": "The fetus is growing rapidly, requiring lots of energy. Ensure your diet is high in nutrients to meet both your needs.",
	"180": "Your baby's body is increasingly proportionate, though the head remains large relative to the body. Gentle stretching can help relieve discomfort as your body adjusts to accommodate your growing baby.",
	"181": "The baby's movements are now strong and coordinated. Light exercises can help you stay comfortable and manage the increased activity inside.",
	"182": "Your baby's skin is getting smoother as fat continues to accumulate underneath. Maintaining a healthy diet with plenty of hydration supports skin health.",
	"183": "The fetus's nervous system is maturing, enhancing its ability to regulate body temperature. Dressing in layers can help you manage your own fluctuating body temperature.",
	"184": "Your baby's inner ear is fully developed, which helps with balance. The sense of equilibrium might make them more responsive to your movements.",
	"185": "The vernix caseosa on the baby's skin thickens, offering greater protection. Consuming essential fatty acids can support the development of healthy skin.",
	"186": "Your baby's eyelids can now open and close, and they may blink in response to light. Ensuring you get enough Vitamin A supports your baby's vision development.",
	"187": "The fetus is practicing sucking and swallowing, preparing for life outside the womb. Staying hydrated helps maintain amniotic fluid levels for these practices.",
	"188": "Your baby's taste buds are fully developed, and they may prefer sweet flavors, detectable in the amniotic fluid. A varied diet can expose your baby to different tastes.",
	"189": "The baby's brain is undergoing rapid growth, requiring plenty of omega-3 fatty acids for optimal development. Foods like walnuts and flaxseed are great sources of nutrition.",
	"190": "Your baby's body is now better proportioned, and they continue to gain weight. Eating a balanced diet helps support this healthy growth.",
	"191": "The fetus's hair continues to grow, and you might be able to see its color and texture during an ultrasound. Consuming biotin-rich foods supports hair health.",
	"192": "Your baby's fingernails have grown to reach the tips of their fingers. Including calcium in your diet supports nail and bone development.",
	"193": "The baby's movements may feel different as they grow larger and have less room to move. Keeping a movement diary can help you track patterns and share them with your healthcare provider.",
	"194": "The fetus is accumulating more fat, which helps with temperature regulation after birth. Foods rich in healthy fats are beneficial for both of you.",
	"195": "Your baby's liver and pancreas are further developing, preparing to process nutrients and regulate blood sugar after birth. A diet low in processed sugars can benefit both of you.",
	"196": "The baby's immune system is developing, helped by the antibodies passed through the placenta. Eating foods rich in vitamins C and E can support immune health.",
	"197": "The fetus's skin is becoming less wrinkled as more fat is stored beneath it. Keeping your skin moisturized can help you feel more comfortable.",
	"198": "Your baby's lungs are maturing, preparing for the first breath after birth. Practicing breathing exercises can be beneficial for your delivery preparation.",
	"199": "The baby's brain is forming millions of neurons, with significant brain growth occurring. Eating foods high in DHA supports cognitive development.",
	"200": "The fetus can hear and may respond to sounds by moving or kicking. Playing calm music and speaking softly can be soothing for both of you.",
	"201": "Your baby's digestive system is continuing to develop, although it won't be fully functional until after birth. Including fiber in your diet can aid your digestive health.",
	"202": "The baby's movements are becoming more predictable. Sharing these moments with family members can be a wonderful bonding experience.",
	"203": "The vernix caseosa is thick and covers most of the baby's skin, protecting it in the womb. Consuming plenty of water ensures healthy skin and amniotic fluid levels.",
	"204": "Your baby's skeletal system continues to harden, but the skull remains soft and flexible for delivery. Calcium intake is crucial during this time.",
	"205": "The fetus's eyes are developing, with the ability to track light through the womb. Ensuring a diet rich in Vitamin E supports eye health.",
	"206": "The baby is gaining weight rapidly, an essential step for a healthy birth weight. Balanced, nutrient-rich meals are important for supporting this growth.",
	"207": "Your baby's brain development is intense during this period, necessitating good nutrition, especially omega-3 fatty acids, for optimal development.",
	"208": "The fetus's fat layers continue to build, providing energy and helping with temperature regulation after birth. Eating healthy fats supports this process.",
	"209": "Your baby's growth is making your womb quite crowded, changing the nature of their movements. Gentle stretching and movement on your part can help manage discomfort.",
	"210": "The baby's sleep cycles include more periods of REM sleep, which is important for brain development. Restful sleep is beneficial for both of you, so try to create a calming bedtime routine.",
	"211": "The baby's skin is becoming more opaque and taking on a pinkish tone as fat accumulates. Eating foods rich in omega-3 and omega-6 fatty acids can support healthy skin development.",
	"212": "Your baby's movements, while still frequent, may feel different as they grow larger and space becomes tighter. Gentle yoga can help you stay comfortable as your body adapts.",
	"213": "The fetus's brain continues to develop rapidly, with increased connections between neurons. Foods high in iron, like leafy greens and lean meats, can support brain development.",
	"214": "The baby's taste buds are fully formed, and they can taste various flavors from the amniotic fluid. A varied diet introduces your baby to a range of tastes.",
	"215": "Your baby's lungs are maturing, with the air sacs beginning to produce surfactant, helping the lungs stay open after birth. Staying hydrated is important for amniotic fluid levels.",
	"216": "The fetus's eyes are developing, and they may start to open and close. Vitamin A-rich foods like sweet potatoes and carrots support eye health.",
	"217": "Your baby is gaining weight, which is crucial for health after birth. Including plenty of protein in your diet can support this growth.",
	"218": "The baby's brain is forming the grooves and ridges that are characteristic of the brain's surface. Eating foods rich in omega-3 fatty acids, like fish and flaxseeds, supports brain health.",
	"219": "The fetus's hearing is fully developed, and it might react to sounds by kicking or moving. Speaking and playing music to your baby can be a way to bond.",
	"220": "Your baby's hair may be growing thicker on their head. A diet rich in biotin, found in eggs, nuts, and legumes, supports healthy hair and nail growth.",
	"221": "The baby's fingernails have grown to the ends of their fingers, and they might even need a trim shortly after birth. Consuming calcium supports nail and bone health.",
	"222": "The fetus is developing a layer of fat, which will help regulate body temperature after birth. Foods high in healthy fats, such as avocados, are beneficial.",
	"223": "Your baby's movements are a sign of health and vitality. Tracking movement patterns can help you understand your baby's active and rest times.",
	"224": "The baby's skin is coated with vernix caseosa, protecting it from the amniotic fluid. Staying well-hydrated helps maintain healthy amniotic fluid levels.",
	"225": "The fetus's immune system is developing, preparing it for life outside the womb. Eating foods rich in vitamin C supports immune health.",
	"226": "Your baby's bones are hardening, although the skull remains soft and flexible for birth. Ensure your diet includes enough calcium for bone development.",
	"227": "The baby's digestive system continues to develop, and it swallows amniotic fluid, practicing for feeding after birth. A balanced diet supports your baby's growth.",
	"228": "The fetus's brain development is significant, with an increase in brain size and complexity. A diet rich in DHA is important for neural development.",
	"229": "Your baby's sleep now includes REM sleep, which is important for brain development. Ensuring you get enough rest is also crucial for your health.",
	"230": "The baby's fat layers are thickening, which will help them maintain body temperature after birth. Including omega-3 fatty acids in your diet is beneficial for fat development.",
	"231": "The fetus's movements may decrease slightly as it grows bigger and has less room to move. However, you should still feel regular movement.",
	"232": "Your baby's senses are fully developed, and they can respond to stimuli such as light and sound. Talking to your baby can help with bonding before birth.",
	"233": "The baby's lungs are nearly fully developed, although they will continue to mature until birth. Practicing breathing exercises can be beneficial for your own lung capacity.",
	"234": "The fetus is gaining weight rapidly, an important part of development. Nutrient-rich foods support this growth and your overall health.",
	"235": "Your baby's movements are stronger, and you may notice patterns in their activity. Sharing these moments with loved ones can be a joyful experience.",
	"236": "The baby's skin is smoothing out as more fat is deposited under the skin. Ensuring a diet rich in vitamins and hydration supports skin health.",
	"237": "The fetus's nervous system is maturing, which helps regulate body functions. Foods rich in omega-3 fatty acids support nervous system development.",
	"238": "Your baby is practicing breathing by inhaling and exhaling small amounts of amniotic fluid. This helps develop the muscles needed for breathing after birth.",
	"239": "The baby's skeletal structure is solidifying, but the bones will remain flexible for delivery. A diet high in calcium and vitamin D supports bone health.",
	"240": "Your baby's hair may now be thick enough to be visible on an ultrasound. A balanced diet supports healthy fetal development.",		
	"241": "Your baby's brain continues to develop rapidly, forming intricate neural networks. Foods rich in omega-3 fatty acids are crucial for this development.",
	"242": "The fetus's body is preparing for birth, turning head-down in most cases. Gentle stretches and prenatal yoga can help your body prepare for the birth process.",
	"243": "Your baby's digestive system is maturing, ready to handle breast milk or formula. Consuming probiotics can support your digestive health and, subsequently, your baby's.",
	"244": "The baby's lungs are further maturing, with increased production of surfactant to help the air sacs inflate after birth. Maintaining a clean, dust-free home environment supports respiratory health.",
	"245": "Your baby's movements may feel different as they grow bigger and space in the womb becomes tighter. Monitoring movement patterns is still important.",
	"246": "The fetus's skin is shedding the lanugo, the fine hair that covered its body, as fat accumulation increases. Hydration is key to supporting skin health.",
	"247": "Your baby's fat layers continue to develop, providing energy and helping with temperature regulation after birth. Eating a balanced diet with healthy fats is beneficial.",
	"248": "The baby's bones are hardening, except for the skull, which remains soft and flexible for birth. Including calcium-rich foods in your diet supports bone development.",
	"249": "Your baby's nails may extend beyond the fingertips and may need trimming soon after birth. Nutrient-rich foods support healthy nail growth.",
	"250": "The fetus is gaining weight steadily, an important factor for a healthy birth weight. Balanced meals support this growth.",
	"251": "Your baby's brain is forming the connections needed for sensory experiences. A calm and stimulating environment can be beneficial for fetal development.",
	"252": "The baby's sleep now includes more periods of REM sleep, essential for brain development. Restful sleep is also crucial for your health.",
	"253": "Your baby's movements, while possibly less vigorous, should remain frequent. Contact your healthcare provider if you notice significant changes in movement patterns.",
	"254": "The fetus's hair may be growing longer, and you might wonder about its color. Your diet impacts your baby's development, including hair growth.",
	"255": "The baby's lungs are nearly fully developed, though they will continue to mature until birth. Foods high in antioxidants can support lung health.",
	"256": "Your baby's reflexes, such as sucking and grasping, are further developing. These reflexes are crucial for the first moments after birth.",
	"257": "The fetus's immune system benefits from the antibodies passed through the placenta. Eating foods high in vitamins C and E can bolster immune health.",
	"258": "Your baby is accumulating fat, leading to smoother, less wrinkled skin. Omega-3 fatty acids, found in fish and flaxseeds, support healthy fat development.",
	"259": "The baby's eyesight continues to develop, although vision will be limited at birth. Ensuring your diet is rich in Vitamin A supports eye health.",
	"260": "Your baby's brain development is in a crucial phase, with significant growth and maturation occurring. Foods rich in DHA support cognitive development.",
	"261": "The fetus's size and position may make your breathing feel more constrained. Practicing good posture can help alleviate discomfort.",
	"262": "The baby's skeleton is solidifying, with calcium from your diet playing a key role. Dairy products, fortified foods, and leafy greens are great sources of calcium.",
	"263": "Your baby's movements can be a response to external stimuli, such as sounds and light. Interacting with your baby can be a rewarding experience.",
	"264": "The fetus is gaining weight, preparing for a healthy birth. Eating a balanced, nutrient-rich diet supports fetal growth and your well-being.",
	"265": "The baby's nervous system is maturing, enabling them to experience taste, smell, and touch. A calm and nurturing environment benefits both of you.",
	"266": "Your baby's lungs are almost fully developed, ready for the first breath after birth. Staying active with gentle exercises can support your respiratory system.",
	"267": "The fetus's body continues to grow, with fat accumulation making the skin pink and smooth. Staying hydrated and maintaining a healthy diet are key.",
	"268": "Your baby's position may begin to shift, getting ready for the birth process. Gentle movements and stretches can help you manage physical changes.",
	"269": "The baby's brain and other organs are nearly fully developed, though growth will continue after birth. Ensuring you're getting enough folic acid is important for neural development.",
	"270": "Your baby is now considered full-term, and birth could happen at any time. Preparing your mind and environment for the arrival is important.",
	"271": "The baby's movements may become less pronounced as space is limited, but they should remain frequent. Monitoring these movements is still crucial.",
	"272": "The fetus's hair and nails continue to grow. Eating a balanced diet supports these final stages of development.",
	"273": "Your body is preparing for labor, which could include the baby dropping lower in your abdomen. Gentle exercise and relaxation techniques can help.",
	"274": "The baby's brain continues to develop, with neural connections forming rapidly. A diet high in omega-3 fatty acids supports this brain growth.",
	"275": "Your baby's immune system is developing, with antibodies being transferred from you to help fight infections after birth. Consuming vitamin-rich foods supports immune health.",
	"276": "The baby's digestive system is maturing, ready to digest breast milk or formula. Eating a well-balanced diet helps prepare your body for breastfeeding.",
	"277": "The fetus's lungs are nearly ready for breathing air. The countdown to meeting your baby is getting shorter every day.",
	"278": "Your baby is continuing to gain weight, preparing for a healthy start. Eating small, frequent meals can help you maintain energy.",
	"279": "The baby's development is nearly complete, and they're getting ready for birth. Rest and relaxation are important as you approach labor.",
	"280": "Congratulations! You've reached day 280, the end of a full-term pregnancy. It's an incredible journey, and you're about to meet your baby. Remember, delivery won't necessarily happen today, but you're fully prepared for when it does. Wishing you a smooth delivery and a healthy, happy baby."				
	}


/* export const pregnancyDailyMessages = {
    "0": "Preconception: It's a period of preparation. Now is the time to focus on a healthy lifestyle, maintaining a balanced diet, and considering prenatal vitamins based on healthcare advice.",
    "1": "Preconception: Continue focusing on your health. Regular exercise and a balanced diet can improve your fertility and prepare your body for pregnancy.",
    "2": "Preconception: Stress management is key. Find relaxation techniques that work for you, such as meditation, yoga, or reading.",
    "3": "Preconception: If you haven't already, schedule a preconception visit with your healthcare provider to discuss any necessary lifestyle adjustments or supplements.",
    "4": "Preconception: Consider tracking your menstrual cycle to identify your fertile window and increase your chances of conceiving.",
    "5": "Preconception: A balanced diet rich in fruits, vegetables, whole grains, and lean proteins is essential. Consider adding foods high in folic acid to your diet.",
    "6": "Preconception: Staying hydrated is important for overall health and can support fertility. Aim for eight glasses of water a day.",
    "7": "Preconception: Ensure you're getting enough sleep. Adequate rest can improve your mood and energy levels, contributing to a healthier conception environment.",
    "8": "Preconception: If you smoke or drink alcohol, now is the time to quit. These substances can affect fertility and the health of a future pregnancy.",
    "9": "Preconception: Begin taking prenatal vitamins, especially folic acid, to reduce the risk of birth defects when you do conceive.",
    "10": "Preconception: Continue to maintain a healthy weight through exercise and nutrition. Being overweight or underweight can affect your fertility.",
    "11": "Preconception: Check your vaccinations to ensure they are up to date for a healthy pregnancy environment.",
    "12": "Preconception: Limit your caffeine intake. High levels of caffeine can affect fertility and the early stages of pregnancy.",
    "13": "Preconception: Explore relaxation and stress-reduction techniques. High stress levels can impact your ability to conceive.",
    "14": "Preconception: Reflect on your current medications with your healthcare provider to ensure they are safe for pregnancy.",
    "15": "Congratulations on beginning your pregnancy journey! Conception has just occurred. It's crucial to continue focusing on your health, take prenatal vitamins, and maintain a balanced diet.",
    "16": "The fertilized egg is making its way to implant in the uterus. Rest well, stay hydrated, and keep up with a nutritious diet to support your body.",
    "17": "Implantation may be happening soon. While you might not notice any physical changes, your body is preparing for pregnancy. Continue to avoid harmful substances and stay well-nourished.",
    "18": "The blastocyst begins to implant in the uterine lining. This can cause implantation bleeding. It's a critical time for the embryo's development, so continue focusing on healthy lifestyle choices.",
    "19": "Implantation continues. You may experience some spotting, which is normal. Focus on gentle exercise and staying relaxed.",
    "20": "The blastocyst completes implantation. Your body starts producing hCG, which is detectable by pregnancy tests soon. Continue eating a well-balanced diet and taking prenatal vitamins.",
    "21": "With implantation complete, the embryonic stage begins. Though it's still early, ensuring you're getting enough folic acid is crucial for the baby's development.",
    "22": "The embryo is very small but growing rapidly. You might not feel different, but your body is doing incredible work. Stay hydrated and rested.",
    "23": "The embryo begins to form structures that will become its organs. It's more important than ever to maintain a nutritious diet and avoid harmful environments.",
    "24": "The foundations of the baby's nervous system are being laid. Omega-3 fatty acids, found in fish oil and flaxseeds, are beneficial for brain development.",
    "25": "Tiny blood vessels are forming, and the heart will soon start beating. This is a good time to start a gentle exercise routine, if you haven't already.",
    "26": "The embryo is developing its primitive placenta, which will provide nutrients and oxygen. Continue to focus on nutrient-rich foods to support both of your needs.",
    "27": "The baby's heart begins to beat, though it's too soon to hear it. Taking time for relaxation and bonding with your partner can be comforting.",
    "28": "The embryo is now the size of a small seed. Even if it's not visible from the outside, incredible changes are happening inside. Reflect on the miraculous process happening within.",
    "29": "The baby's facial features begin to form, including the mouth, nostrils, and ears. Continue to prioritize a balanced diet and hydration for healthy development.",
    "30": "Your baby's brain, spinal cord, and heart are developing. It's essential to continue avoiding any harmful substances and to maintain a healthy lifestyle.",
	"31": "The embryo's heart is becoming more complex and now has divided into right and left chambers. Continue your routine of healthy eating, staying active, and regular prenatal check-ups.",
	"32": "Your baby's tiny fingers and toes are starting to form, although still webbed. It's important to keep hydrated and maintain a balanced diet rich in vitamins and minerals.",
	"33": "The baby's body is beginning to straighten out. Be mindful of your posture and consider prenatal yoga or stretching exercises to alleviate discomfort.",
	"34": "The embryo's eyes are developing, though still closed. Ensure your diet includes foods rich in Vitamin A, such as sweet potatoes and carrots, to support healthy eye development.",
	"35": "The baby's ears are starting to form, and the inner ear begins to develop. Engaging in calm, soothing activities can benefit both you and your baby.",
	"36": "Your baby's skeletal system, including the jawbone, spine, and ribs, is forming. Calcium-rich foods like dairy, leafy greens, and almonds are vital for bone development.",
	"37": "The circulatory system is now functioning, with the heart pumping blood. Gentle exercise, like walking, can support your circulation and overall well-being.",
	"38": "Muscle and nerve connections are establishing, allowing for early movements. It's a great time to start talking to your baby, as they're developing the ability to sense vibrations.",
	"39": "Your baby's liver and spleen are hard at work producing blood cells. Foods rich in iron, like spinach and red meat, can support healthy blood development.",
	"40": "The baby's digestive system continues to develop, and the body starts to form a more defined shape. Staying relaxed and stress-free supports your baby's growth.",
	"41": "Limb buds are growing rapidly, soon to become arms and legs. Maintaining a nutritious diet supports the rapid growth happening now.",
	"42": "Your baby's facial features, such as the nose, mouth, and eyes, are becoming more distinct. Ensure you're getting enough omega-3 fatty acids to support cognitive development.",
	"43": "The intestines are forming, and the pancreas begins producing insulin. Eating small, frequent meals can help manage blood sugar levels and support digestive health.",
	"44": "The baby's tiny heart now has four chambers, and blood circulation is well-established. Monitoring your heart rate during exercise is important to stay within a healthy range.",
	"45": "Your baby's reproductive organs are developing, although it's still too early to determine the sex. Continuing to avoid harmful substances is crucial for healthy development.",
	"46": "The embryo is now officially considered a fetus. This marks the beginning of a rapid growth phase. Ensuring you're consuming enough calories supports this growth.",
	"47": "The fetus's external ears are forming, and the eyes have moved to the front of the face. Listening to soothing music can be a relaxing activity for you both.",
	"48": "Fingernails and toenails are beginning to form. Incorporating biotin-rich foods like eggs and nuts into your diet can support nail health.",
	"49": "Your baby's skin is still translucent, but it will soon start to thicken. Drinking plenty of water helps keep your skin hydrated and healthy.",
	"50": "The fetus is now swallowing small amounts of amniotic fluid, which is essential for the development of the digestive system. Continuing to eat a balanced diet supports your amniotic fluid quality.",
	"51": "Fine hair called lanugo starts to cover the fetus, helping to regulate body temperature. It's important to keep your environment neither too hot nor too cold.",
	"52": "Your baby's sense of taste is developing. Eating a variety of foods can expose your baby to different flavors through the amniotic fluid.",
	"53": "The fetus is beginning to practice breathing movements, although the lungs are not yet fully developed. Practicing deep breathing can be beneficial for your health and relaxation.",
	"54": "The baby's body is growing, and the head is still disproportionately large compared to the rest of the body. Maintaining good posture can help alleviate back pain.",
	"55": "Eyelids are now fully formed but remain closed. Ensuring that your diet is rich in Vitamin E supports eye health and development.",
	"56": "The skeletal system continues to harden from cartilage to bone. Vitamin D is essential for this process, so consider spending some time outdoors for natural sunlight exposure.",
	"57": "The fetus is becoming more active, though you might not feel its movements yet. Staying active yourself can improve your physical well-being and mood.",
	"58": "Your baby's brain is developing rapidly, with new neurons forming every minute. Foods high in DHA, such as salmon, can support brain health.",
	"59": "The muscles continue to develop, and the fetus will soon start making more noticeable movements. Staying flexible through stretching or prenatal yoga can be beneficial.",
	"60": "The fetus's skin is developing layers, including the dermis and epidermis. Keeping hydrated supports healthy skin development for both of you.",
	"61": "Your baby's heartbeats may now be detectable by a Doppler device during prenatal visits. It's a heartwarming moment many parents look forward to.",
	"62": "The baby's eyelids are fused shut but will begin to reopen in a few weeks. Eating foods rich in beta-carotene supports healthy eye development.",
	"63": "The fetus is starting to form its own unique fingerprints on its tiny fingers. Ensuring that you’re staying calm can help with fetal development.",
	"64": "Your baby's limbs are getting longer and more proportionate to the rest of the body. Remember to keep up with your prenatal appointments.",
	"65": "The fetus's taste buds continue to develop, and it can now taste sweet and bitter flavors from the amniotic fluid. Enjoying a variety of healthy foods can introduce your baby to different tastes.",
	"66": "Your baby is becoming more active, though you may not feel much yet if this is your first pregnancy. Gentle exercise can be beneficial for both of you.",
	"67": "The fetus's skin is very thin and transparent, allowing veins to be visible through the skin. Hydration and proper nutrition are key for healthy skin development.",
	"68": "Your baby's bones are continuing to harden, except for the skull, which remains soft and pliable for delivery. Calcium intake is crucial at this stage.",
	"69": "The baby's nervous system is maturing, with the brain developing rapidly. Foods high in omega-3 fatty acids are beneficial for brain health.",
	"70": "The fetus can now yawn, stretch, and make facial expressions. Talking and singing to your baby can be a way to bond.",
	"71": "Fine hair called lanugo continues to cover the baby's body, helping to regulate body temperature. Maintaining a comfortable and consistent temperature in your environment is beneficial.",
	"72": "Your baby's liver is starting to process some waste products. A balanced diet supports your liver's health and, consequently, the baby's.",
	"73": "The baby's pancreas is developing steadily, important for hormone production. Keeping your blood sugar levels stable is beneficial for both of you.",
	"74": "The fetus is now starting to develop its own immune system, though it will continue to rely on yours until birth. Consuming vitamin C-rich foods can support immune health.",
	"75": "Your baby's reproductive system continues to develop, but it will be a few more weeks before sex can be determined through ultrasound.",
	"76": "The fetus's eyes are still sealed shut, but it can perceive light and dark. Maintaining a regular sleep schedule can help regulate your circadian rhythms and potentially affect the baby's.",
	"77": "The baby's muscle tone is improving, and it will soon start practicing breathing by inhaling amniotic fluid. Practicing relaxation and breathing exercises can be beneficial for labor.",
	"78": "Your baby's skeleton is hardening, with bones becoming stronger but still somewhat flexible. A diet rich in vitamin D and calcium supports skeletal health.",
	"79": "The fetus's hearing is developing, and it may start to respond to sounds. Playing soft music or reading aloud can be comforting for both of you.",
	"80": "The baby is growing more hair, including eyebrows and eyelashes. Eating a healthy diet supports the development of healthy skin and hair.",
	"81": "Your baby's skin is starting to become less transparent as it develops more layers. Staying well-nourished helps support this growth.",
	"82": "The fetus is sleeping in regular cycles, which might not match your own. Creating a calm, relaxing bedtime routine can be beneficial for your sleep too.",
	"83": "Your baby's brain is forming the grooves and indentations that characterize a mature brain. Foods rich in DHA support this complex development.",
	"84": "The fetus's taste buds are fully formed, and it can taste the food you eat through the amniotic fluid. A varied diet can stimulate your baby's senses.",
	"85": "The baby is now producing meconium, which will become its first bowel movement. Eating fiber-rich foods can support your digestive health and prepare you for postpartum recovery.",
	"86": "Your baby's fingernails have reached the tips of its fingers. Monitoring your iron intake can help prevent anemia and support healthy nail growth.",
	"87": "The fetus's lungs are developing surfactant, which will help them inflate after birth. Gentle breathing exercises can support your lung health and relaxation.",
	"88": "The baby's brain and nervous system are developing rapidly. Ensuring you get enough sleep supports your brain health and mood.",
	"89": "Your baby's muscles are strengthening, and it may begin to turn and kick more forcefully. Keeping active with appropriate exercises can help manage discomfort.",
	"90": "The fetus's skin is starting to smooth out as it gains fat. Maintaining a balanced diet with healthy fats supports both your health and your baby's development.",
	"91": "The baby's cartilage is turning into bone, a process known as ossification. Foods rich in calcium and vitamin D support this important development.",
	"92": "Your baby's stomach is starting to produce digestive juices, preparing for life outside the womb. Eating a well-balanced diet aids in the development of the baby's digestive system.",
	"93": "The fetus's kidneys are now able to produce urine, which is released into the amniotic fluid. Staying hydrated is important for both your and your baby's kidney function.",
	"94": "Your baby's body is beginning to store fat, which will help regulate body temperature after birth. Including healthy fats in your diet, like avocados and nuts, is beneficial.",
	"95": "The baby's hair continues to grow, and you might be able to see its color on an ultrasound. Consuming sufficient protein supports healthy hair growth.",
	"96": "Your baby can now blink, and its eyelashes have formed. Ensuring you get plenty of rest helps your body manage the changes of pregnancy.",
	"97": "The fetus's sense of hearing is improving, and it may startle at loud sounds. Speaking and playing music can stimulate your baby's auditory development.",
	"98": "Your baby's skin is thickening and developing layers, including the epidermis and dermis. Eating foods high in vitamins C and E supports skin health.",
	"99": "The baby's lungs are developing branches of the respiratory tree and cells that produce surfactant. Practicing deep breathing exercises can benefit your respiratory system.",
	"100": "The fetus can grasp with its hands now, practicing movements it will use after birth. Engaging in activities that relax you can also soothe your baby.",
	"101": "Your baby's body is growing rounder as fat accumulates. This fat is essential for temperature regulation and metabolism after birth.",
	"102": "The baby's irises can now dilate and contract in response to light. Regular sleep patterns can help regulate your circadian rhythms, beneficial for both of you.",
	"103": "Your baby's fingernails now cover the fingertips and may soon need a trim after birth. Consuming biotin-rich foods can support nail health.",
	"104": "The fetus's sense of taste is fully developed. Eating a varied diet can expose your baby to different flavors through the amniotic fluid.",
	"105": "The baby's brain is very active, as are its hearing and sight, even though the eyes are often closed. Reading and talking to your baby can be beneficial.",
	"106": "Your baby's bones are continuing to harden, with the skull remaining soft and pliable for birth. Calcium-rich foods are vital for bone development.",
	"107": "The fetus is practicing breathing by inhaling and exhaling small amounts of amniotic fluid. This helps develop the diaphragm for breathing after birth.",
	"108": "Your baby's fat layers are thickening, which will help keep them warm after birth. Omega-3 fatty acids are important for this development.",
	"109": "The baby's muscle tone is improving, making movements more coordinated. Gentle movement on your part, like walking, can stimulate fetal activity.",
	"110": "The fetus's skin is less wrinkly as more fat accumulates. Staying hydrated helps maintain your skin elasticity and supports fetal growth.",
	"111": "Your baby can now turn its head and make sucking movements with its mouth. These reflexes prepare the baby for feeding after birth.",
	"112": "The baby's digestive system is continuing to mature, although it won’t be fully functional until after birth. Eating fiber-rich foods supports your digestive health.",
	"113": "The fetus is growing more hair on its head, and you might be curious about its color and texture. Your nutrition can impact your baby's overall health, including hair growth.",
	"114": "Your baby's lungs are maturing and getting ready for breathing outside the womb. The baby responds to rhythms, so listening to music can be soothing for both.",
	"115": "The baby's sleep now includes the REM phase, which means it could be dreaming! Establishing a calming bedtime routine might improve your sleep quality too.",
	"116": "Your baby's fingernails have reached the ends of their fingers and may extend beyond them. Ensuring a diet rich in vitamins and minerals supports healthy growth.",
	"117": "The baby is gaining weight rapidly, which is crucial for healthy development. Balanced meals with plenty of nutrients are important at this stage.",
	"118": "The fetus's brain is forming more complex structures, facilitating learning and memory. Foods high in omega-3 fatty acids support this brain development.",
	"119": "Your baby's skin is becoming softer and smoother as fat accumulates underneath. Moisturizing regularly can help keep your skin comfortable as it stretches.",
	"120": "The baby is becoming increasingly active, with kicks and movements more noticeable. Regular physical activity is beneficial for your health and prepares you for childbirth.",	
	"121": "The baby's body continues to fill out with fat, making the skin less transparent. Eating a diet with healthy fats, like avocados and olive oil, is beneficial.",
	"122": "Your baby's taste buds are fully developed now, and they might even show a preference for certain tastes. Variety in your diet can introduce your baby to different flavors.",
	"123": "The baby's brain is rapidly growing, increasing in size and complexity. Foods rich in DHA, like salmon, support this cognitive development.",
	"124": "The fetus's hair, including eyebrows and eyelashes, is becoming thicker. Protein-rich foods support the growth of healthy hair and tissues.",
	"125": "Your baby's movements are becoming stronger and more frequent. These movements are important for muscle and bone strengthening.",
	"126": "The baby's lungs are developing alveoli, essential for oxygen exchange after birth. Maintaining good air quality in your home is important for both of you.",
	"127": "Your baby's ears are now fully functional, and they can react to sounds. Playing music and talking to your baby can stimulate their auditory development.",
	"128": "The fetus is practicing swallowing, which is crucial for digestion and lung development. Staying hydrated helps maintain a healthy level of amniotic fluid.",
	"129": "The baby's skin is starting to produce melanin, which gives skin its color. Vitamin D from sunlight exposure is beneficial, but remember to protect your skin.",
	"130": "Your baby's fingernails may now reach the tips of the fingers. A balanced diet supports healthy nail growth and overall fetal development.",
	"131": "The fetus's fat layers continue to develop, which will help regulate body temperature and metabolism after birth. Consuming omega-3 fatty acids is beneficial for fat development.",
	"132": "Your baby can now detect light and may move away or towards it. This response to light is a sign of healthy eye development.",
	"133": "The baby's skeleton is hardening, with the bones of the arms and legs strengthening. Calcium-rich foods are crucial for bone development.",
	"134": "The fetus's brain development is intensive, with billions of neurons forming. Eating foods high in omega-3 fatty acids supports brain health.",
	"135": "Your baby's immune system is developing, with the thymus gland producing T cells. A diet rich in fruits and vegetables can boost your immune system.",
	"136": "The baby's circulatory system is now capable of adapting to life outside the womb. Gentle exercise can improve your circulation and overall health.",
	"137": "The fetus is growing more hair on its head, and body fat continues to accumulate. Ensure you're getting enough protein to support this growth.",
	"138": "Your baby's reflexes, such as sucking and swallowing, are developing. These reflexes are crucial for feeding after birth.",
	"139": "The baby's respiratory system continues to mature, with the development of the bronchioles and alveoli. Breathing exercises can be beneficial for your lung health.",
	"140": "The fetus's movements are becoming more coordinated. These movements are essential for muscle strength and joint flexibility.",
	"141": "Your baby's liver and spleen are hard at work producing blood cells. Foods high in iron, like spinach and lean meats, can support healthy blood development.",
	"142": "The baby's sense of touch is developing, and they may start to explore their face and body with their hands. Gentle rubbing of your belly can sometimes prompt a response.",
	"143": "The fetus is accumulating more fat, leading to smoother, less wrinkled skin. Hydration and balanced nutrition support skin health.",
	"144": "Your baby's bone marrow has started to produce red blood cells, a function it will take over completely after birth. Iron-rich foods are important for red blood cell production.",
	"145": "The baby's eyes are still fused shut but will begin to open soon. Vitamin A-rich foods support healthy eye development.",
	"146": "The fetus's ears are better developed, and it may respond to your voice by moving or increasing its heartbeat. Talking to your baby can enhance bonding.",
	"147": "Your baby's body is continuing to grow, and movements are more noticeable. Monitoring your baby's activity patterns can be an enjoyable way to bond.",
	"148": "The baby's nervous system is maturing, with ongoing development of the brain and nerves. A calm and stress-free environment benefits both you and your baby.",
	"149": "The fetus's skin is developing a protective coating, called vernix caseosa, to protect it from the amniotic fluid. Eating healthy fats supports skin health.",
	"150": "Your baby's muscular and skeletal systems are getting stronger, making their movements more powerful. Regular physical activity is good for your health and prepares you for childbirth.",
	"151": "Your baby's skin is becoming less translucent as it continues to develop layers and accumulate fat. A balanced diet rich in vitamins and minerals supports your baby's skin health.",
	"152": "The fetus is now developing a regular sleep and wake cycle, which might not align with yours. Rest when you can to ensure you both get the sleep you need.",
	"153": "Your baby's movements are becoming stronger and more frequent, a sign of healthy development. Documenting these movements can help you bond with your baby.",
	"154": "The baby's skeletal structure continues to strengthen, with the bones absorbing more calcium. Dairy products, leafy greens, and fortified foods can boost your calcium intake.",
	"155": "The fetus's ears are now fully developed, and it can hear a wide range of sounds. Speaking, reading aloud, and playing music can stimulate your baby's hearing.",
	"156": "Your baby's eyes are developing, and while the eyelids remain closed, the eyes can move. Ensuring you get enough omega-3 fatty acids supports eye health.",
	"157": "The baby's body is starting to prepare for breathing air. Its chest moves as it practices inhaling and exhaling. Practicing breathing exercises can be beneficial for you too.",
	"158": "The fetus's hair continues to grow, and you might start wondering about its color and texture. A healthy diet supports all aspects of your baby's development.",
	"159": "Your baby's sense of taste is developing further, and it may react to strong flavors in the amniotic fluid. Eating a varied diet can introduce your baby to different tastes.",
	"160": "The baby's fingernails have fully formed, and they might even scratch themselves. Keeping your own nails trimmed and smooth can remind you to care for your baby's nails after birth.",
	"161": "The fetus's brain is rapidly developing, forming the structures that will support thinking and memory. Foods rich in DHA, like salmon, are great for brain development.",
	"162": "Your baby's skin is forming a protective layer called vernix caseosa, which prevents wrinkling from the amniotic fluid. Staying hydrated helps maintain healthy skin for both of you.",
	"163": "The baby is gaining weight, mostly in the form of muscle and bone density, along with increasing fat stores. Eating protein-rich foods supports this growth.",
	"164": "The fetus's lungs are maturing, and it practices breathing movements more frequently. Gentle exercise, like walking, can improve your cardiovascular health.",
	"165": "Your baby's movements may begin to follow a pattern as its central nervous system matures. Paying attention to these movements can help you understand your baby's active and rest times.",
	"166": "The vernix caseosa thickens, providing more protection for your baby's skin. Including healthy fats in your diet supports your baby's fat development.",
	"167": "The baby's cartilage is increasingly being replaced by bone, a process that will continue after birth. Calcium-rich foods are essential for strong bone development.",
	"168": "Your baby's digestive system continues to mature, preparing for feeding after birth. Consuming probiotic-rich foods like yogurt can benefit your digestive system.",
	"169": "The fetus's sense of smell is developing, even though it's still in the womb. Keeping your environment free from strong or harmful odors is a good practice.",
	"170": "The baby's brain and nerve endings are developed enough to process touch sensations. Massaging your belly gently can sometimes prompt a response from your baby.",
	"171": "The baby's body is starting to store vital minerals, such as iron and calcium, in preparation for birth. Foods rich in iron, like red meat and spinach, are important in the third trimester.",
	"172": "Your baby's eyebrows and eyelashes are well-defined, and more hair is growing on the head. Eating well supports the health of both your and your baby's hair.",
	"173": "The fetus is becoming plumper as fat deposits continue to build, helping with temperature regulation and metabolism. Balanced meals support healthy weight gain.",
	"174": "Your baby's adrenal glands are developing, which will produce hormones that support metabolism and stress response. Managing stress and eating nutrient-rich foods are beneficial.",
	"175": "The baby's hearing is fully developed, and it may react to sounds by moving or kicking. Regular conversations can help you bond with your baby.",
	"176": "The fetus's movements are more forceful now, and you might even see your belly move. Tracking these movements can be an exciting way to connect with your baby.",
	"177": "Your baby's sleep now includes the REM phase, important for brain development. Ensuring you get plenty of rest supports your health and well-being.",
	"178": "The baby's immune system is developing, preparing to function outside the womb. Eating foods rich in vitamins C and E can support your immune system.",
	"179": "The fetus is growing rapidly, requiring lots of energy. Ensure your diet is high in nutrients to meet both your needs.",
	"180": "Your baby's body is increasingly proportionate, though the head remains large relative to the body. Gentle stretching can help relieve discomfort as your body adjusts to accommodate your growing baby.",
	"181": "The baby's movements are now strong and coordinated. Light exercises can help you stay comfortable and manage the increased activity inside.",
	"182": "Your baby's skin is getting smoother as fat continues to accumulate underneath. Maintaining a healthy diet with plenty of hydration supports skin health.",
	"183": "The fetus's nervous system is maturing, enhancing its ability to regulate body temperature. Dressing in layers can help you manage your own fluctuating body temperature.",
	"184": "Your baby's inner ear is fully developed, which helps with balance. The sense of equilibrium might make them more responsive to your movements.",
	"185": "The vernix caseosa on the baby's skin thickens, offering greater protection. Consuming essential fatty acids can support the development of healthy skin.",
	"186": "Your baby's eyelids can now open and close, and they may blink in response to light. Ensuring you get enough Vitamin A supports your baby's vision development.",
	"187": "The fetus is practicing sucking and swallowing, preparing for life outside the womb. Staying hydrated helps maintain amniotic fluid levels for these practices.",
	"188": "Your baby's taste buds are fully developed, and they may prefer sweet flavors, detectable in the amniotic fluid. A varied diet can expose your baby to different tastes.",
	"189": "The baby's brain is undergoing rapid growth, requiring plenty of omega-3 fatty acids for optimal development. Foods like walnuts and flaxseed are great sources of nutrition.",
	"190": "Your baby's body is now better proportioned, and they continue to gain weight. Eating a balanced diet helps support this healthy growth.",
	"191": "The fetus's hair continues to grow, and you might be able to see its color and texture during an ultrasound. Consuming biotin-rich foods supports hair health.",
	"192": "Your baby's fingernails have grown to reach the tips of their fingers. Including calcium in your diet supports nail and bone development.",
	"193": "The baby's movements may feel different as they grow larger and have less room to move. Keeping a movement diary can help you track patterns and share them with your healthcare provider.",
	"194": "The fetus is accumulating more fat, which helps with temperature regulation after birth. Foods rich in healthy fats are beneficial for both of you.",
	"195": "Your baby's liver and pancreas are further developing, preparing to process nutrients and regulate blood sugar after birth. A diet low in processed sugars can benefit both of you.",
	"196": "The baby's immune system is developing, helped by the antibodies passed through the placenta. Eating foods rich in vitamins C and E can support immune health.",
	"197": "The fetus's skin is becoming less wrinkled as more fat is stored beneath it. Keeping your skin moisturized can help you feel more comfortable.",
	"198": "Your baby's lungs are maturing, preparing for the first breath after birth. Practicing breathing exercises can be beneficial for your delivery preparation.",
	"199": "The baby's brain is forming millions of neurons, with significant brain growth occurring. Eating foods high in DHA supports cognitive development.",
	"200": "The fetus can hear and may respond to sounds by moving or kicking. Playing calm music and speaking softly can be soothing for both of you.",
	"201": "Your baby's digestive system is continuing to develop, although it won't be fully functional until after birth. Including fiber in your diet can aid your digestive health.",
	"202": "The baby's movements are becoming more predictable. Sharing these moments with family members can be a wonderful bonding experience.",
	"203": "The vernix caseosa is thick and covers most of the baby's skin, protecting it in the womb. Consuming plenty of water ensures healthy skin and amniotic fluid levels.",
	"204": "Your baby's skeletal system continues to harden, but the skull remains soft and flexible for delivery. Calcium intake is crucial during this time.",
	"205": "The fetus's eyes are developing, with the ability to track light through the womb. Ensuring a diet rich in Vitamin E supports eye health.",
	"206": "The baby is gaining weight rapidly, an essential step for a healthy birth weight. Balanced, nutrient-rich meals are important for supporting this growth.",
	"207": "Your baby's brain development is intense during this period, necessitating good nutrition, especially omega-3 fatty acids, for optimal development.",
	"208": "The fetus's fat layers continue to build, providing energy and helping with temperature regulation after birth. Eating healthy fats supports this process.",
	"209": "Your baby's growth is making your womb quite crowded, changing the nature of their movements. Gentle stretching and movement on your part can help manage discomfort.",
	"210": "The baby's sleep cycles include more periods of REM sleep, which is important for brain development. Restful sleep is beneficial for both of you, so try to create a calming bedtime routine.",
	"211": "The baby's skin is becoming more opaque and taking on a pinkish tone as fat accumulates. Eating foods rich in omega-3 and omega-6 fatty acids can support healthy skin development.",
	"212": "Your baby's movements, while still frequent, may feel different as they grow larger and space becomes tighter. Gentle yoga can help you stay comfortable as your body adapts.",
	"213": "The fetus's brain continues to develop rapidly, with increased connections between neurons. Foods high in iron, like leafy greens and lean meats, can support brain development.",
	"214": "The baby's taste buds are fully formed, and they can taste various flavors from the amniotic fluid. A varied diet introduces your baby to a range of tastes.",
	"215": "Your baby's lungs are maturing, with the air sacs beginning to produce surfactant, helping the lungs stay open after birth. Staying hydrated is important for amniotic fluid levels.",
	"216": "The fetus's eyes are developing, and they may start to open and close. Vitamin A-rich foods like sweet potatoes and carrots support eye health.",
	"217": "Your baby is gaining weight, which is crucial for health after birth. Including plenty of protein in your diet can support this growth.",
	"218": "The baby's brain is forming the grooves and ridges that are characteristic of the brain's surface. Eating foods rich in omega-3 fatty acids, like fish and flaxseeds, supports brain health.",
	"219": "The fetus's hearing is fully developed, and it might react to sounds by kicking or moving. Speaking and playing music to your baby can be a way to bond.",
	"220": "Your baby's hair may be growing thicker on their head. A diet rich in biotin, found in eggs, nuts, and legumes, supports healthy hair and nail growth.",
	"221": "The baby's fingernails have grown to the ends of their fingers, and they might even need a trim shortly after birth. Consuming calcium supports nail and bone health.",
	"222": "The fetus is developing a layer of fat, which will help regulate body temperature after birth. Foods high in healthy fats, such as avocados, are beneficial.",
	"223": "Your baby's movements are a sign of health and vitality. Tracking movement patterns can help you understand your baby's active and rest times.",
	"224": "The baby's skin is coated with vernix caseosa, protecting it from the amniotic fluid. Staying well-hydrated helps maintain healthy amniotic fluid levels.",
	"225": "The fetus's immune system is developing, preparing it for life outside the womb. Eating foods rich in vitamin C supports immune health.",
	"226": "Your baby's bones are hardening, although the skull remains soft and flexible for birth. Ensure your diet includes enough calcium for bone development.",
	"227": "The baby's digestive system continues to develop, and it swallows amniotic fluid, practicing for feeding after birth. A balanced diet supports your baby's growth.",
	"228": "The fetus's brain development is significant, with an increase in brain size and complexity. A diet rich in DHA is important for neural development.",
	"229": "Your baby's sleep now includes REM sleep, which is important for brain development. Ensuring you get enough rest is also crucial for your health.",
	"230": "The baby's fat layers are thickening, which will help them maintain body temperature after birth. Including omega-3 fatty acids in your diet is beneficial for fat development.",
	"231": "The fetus's movements may decrease slightly as it grows bigger and has less room to move. However, you should still feel regular movement.",
	"232": "Your baby's senses are fully developed, and they can respond to stimuli such as light and sound. Talking to your baby can help with bonding before birth.",
	"233": "The baby's lungs are nearly fully developed, although they will continue to mature until birth. Practicing breathing exercises can be beneficial for your own lung capacity.",
	"234": "The fetus is gaining weight rapidly, an important part of development. Nutrient-rich foods support this growth and your overall health.",
	"235": "Your baby's movements are stronger, and you may notice patterns in their activity. Sharing these moments with loved ones can be a joyful experience.",
	"236": "The baby's skin is smoothing out as more fat is deposited under the skin. Ensuring a diet rich in vitamins and hydration supports skin health.",
	"237": "The fetus's nervous system is maturing, which helps regulate body functions. Foods rich in omega-3 fatty acids support nervous system development.",
	"238": "Your baby is practicing breathing by inhaling and exhaling small amounts of amniotic fluid. This helps develop the muscles needed for breathing after birth.",
	"239": "The baby's skeletal structure is solidifying, but the bones will remain flexible for delivery. A diet high in calcium and vitamin D supports bone health.",
	"240": "Your baby's hair may now be thick enough to be visible on an ultrasound. A balanced diet supports healthy fetal development.",		
	"241": "Your baby's brain continues to develop rapidly, forming intricate neural networks. Foods rich in omega-3 fatty acids are crucial for this development.",
	"242": "The fetus's body is preparing for birth, turning head-down in most cases. Gentle stretches and prenatal yoga can help your body prepare for the birth process.",
	"243": "Your baby's digestive system is maturing, ready to handle breast milk or formula. Consuming probiotics can support your digestive health and, subsequently, your baby's.",
	"244": "The baby's lungs are further maturing, with increased production of surfactant to help the air sacs inflate after birth. Maintaining a clean, dust-free home environment supports respiratory health.",
	"245": "Your baby's movements may feel different as they grow bigger and space in the womb becomes tighter. Monitoring movement patterns is still important.",
	"246": "The fetus's skin is shedding the lanugo, the fine hair that covered its body, as fat accumulation increases. Hydration is key to supporting skin health.",
	"247": "Your baby's fat layers continue to develop, providing energy and helping with temperature regulation after birth. Eating a balanced diet with healthy fats is beneficial.",
	"248": "The baby's bones are hardening, except for the skull, which remains soft and flexible for birth. Including calcium-rich foods in your diet supports bone development.",
	"249": "Your baby's nails may extend beyond the fingertips and may need trimming soon after birth. Nutrient-rich foods support healthy nail growth.",
	"250": "The fetus is gaining weight steadily, an important factor for a healthy birth weight. Balanced meals support this growth.",
	"251": "Your baby's brain is forming the connections needed for sensory experiences. A calm and stimulating environment can be beneficial for fetal development.",
	"252": "The baby's sleep now includes more periods of REM sleep, essential for brain development. Restful sleep is also crucial for your health.",
	"253": "Your baby's movements, while possibly less vigorous, should remain frequent. Contact your healthcare provider if you notice significant changes in movement patterns.",
	"254": "The fetus's hair may be growing longer, and you might wonder about its color. Your diet impacts your baby's development, including hair growth.",
	"255": "The baby's lungs are nearly fully developed, though they will continue to mature until birth. Foods high in antioxidants can support lung health.",
	"256": "Your baby's reflexes, such as sucking and grasping, are further developing. These reflexes are crucial for the first moments after birth.",
	"257": "The fetus's immune system benefits from the antibodies passed through the placenta. Eating foods high in vitamins C and E can bolster immune health.",
	"258": "Your baby is accumulating fat, leading to smoother, less wrinkled skin. Omega-3 fatty acids, found in fish and flaxseeds, support healthy fat development.",
	"259": "The baby's eyesight continues to develop, although vision will be limited at birth. Ensuring your diet is rich in Vitamin A supports eye health.",
	"260": "Your baby's brain development is in a crucial phase, with significant growth and maturation occurring. Foods rich in DHA support cognitive development.",
	"261": "The fetus's size and position may make your breathing feel more constrained. Practicing good posture can help alleviate discomfort.",
	"262": "The baby's skeleton is solidifying, with calcium from your diet playing a key role. Dairy products, fortified foods, and leafy greens are great sources of calcium.",
	"263": "Your baby's movements can be a response to external stimuli, such as sounds and light. Interacting with your baby can be a rewarding experience.",
	"264": "The fetus is gaining weight, preparing for a healthy birth. Eating a balanced, nutrient-rich diet supports fetal growth and your well-being.",
	"265": "The baby's nervous system is maturing, enabling them to experience taste, smell, and touch. A calm and nurturing environment benefits both of you.",
	"266": "Your baby's lungs are almost fully developed, ready for the first breath after birth. Staying active with gentle exercises can support your respiratory system.",
	"267": "The fetus's body continues to grow, with fat accumulation making the skin pink and smooth. Staying hydrated and maintaining a healthy diet are key.",
	"268": "Your baby's position may begin to shift, getting ready for the birth process. Gentle movements and stretches can help you manage physical changes.",
	"269": "The baby's brain and other organs are nearly fully developed, though growth will continue after birth. Ensuring you're getting enough folic acid is important for neural development.",
	"270": "Your baby is now considered full-term, and birth could happen at any time. Preparing your mind and environment for the arrival is important.",
	"271": "The baby's movements may become less pronounced as space is limited, but they should remain frequent. Monitoring these movements is still crucial.",
	"272": "The fetus's hair and nails continue to grow. Eating a balanced diet supports these final stages of development.",
	"273": "Your body is preparing for labor, which could include the baby dropping lower in your abdomen. Gentle exercise and relaxation techniques can help.",
	"274": "The baby's brain continues to develop, with neural connections forming rapidly. A diet high in omega-3 fatty acids supports this brain growth.",
	"275": "Your baby's immune system is developing, with antibodies being transferred from you to help fight infections after birth. Consuming vitamin-rich foods supports immune health.",
	"276": "The baby's digestive system is maturing, ready to digest breast milk or formula. Eating a well-balanced diet helps prepare your body for breastfeeding.",
	"277": "The fetus's lungs are nearly ready for breathing air. The countdown to meeting your baby is getting shorter every day.",
	"278": "Your baby is continuing to gain weight, preparing for a healthy start. Eating small, frequent meals can help you maintain energy.",
	"279": "The baby's development is nearly complete, and they're getting ready for birth. Rest and relaxation are important as you approach labor.",
	"280": "Congratulations! You've reached day 280, the end of a full-term pregnancy. It's an incredible journey, and you're about to meet your baby. Remember, delivery won't necessarily happen today, but you're fully prepared for when it does. Wishing you a smooth delivery and a healthy, happy baby."				
	}
	*/

	export const pregnancyTips = [
		"Stay hydrated by drinking at least 8-10 glasses of water daily to support your body and your growing baby.",
		"Eat a balanced diet rich in fruits, vegetables, lean proteins, and whole grains to provide essential nutrients for your baby's development.",
		"Take prenatal vitamins as directed by your healthcare provider to ensure you and your baby receive important vitamins and minerals.",
		"Engage in regular, moderate exercise such as walking, swimming, or prenatal yoga to maintain physical fitness and manage stress.",
		"Get enough rest and sleep, aiming for 7-9 hours per night, to support your physical and emotional well-being during pregnancy.",
		"Attend all scheduled prenatal checkups to monitor your health and your baby's development throughout your pregnancy.",
		"Avoid smoking, alcohol, and illicit drugs, as these can harm your baby's health and development.",
		"Wear sunscreen and protective clothing when spending time outdoors to prevent skin damage and reduce the risk of melasma.",
		"Practice good oral hygiene, brushing and flossing daily, to maintain healthy teeth and gums during pregnancy.",
		"Avoid consuming raw or undercooked meats, fish, and eggs to reduce the risk of foodborne illnesses.",
		"Limit caffeine intake to less than 200mg per day, as excessive caffeine consumption may be harmful to your baby.",
		"Practice stress-management techniques, such as deep breathing, meditation, or talking with a supportive friend or family member.",
		"Wear comfortable, supportive shoes to alleviate back pain and reduce the risk of falls as your center of gravity changes.",
		"Sleep on your left side to improve circulation and reduce swelling in your legs and feet.",
		"Avoid hot tubs, saunas, and very hot baths, as excessive heat can be harmful to fetal development.",
		"Incorporate fiber-rich foods into your diet to prevent constipation, a common pregnancy symptom.",
		"Use a pregnancy pillow or support pillows to find a comfortable sleeping position as your belly grows.",
		"Moisturize your skin regularly to help prevent itching and reduce the appearance of stretch marks.",
		"Engage in pelvic floor exercises (Kegels) to strengthen the muscles that support your uterus, bladder, and bowels.",
		"Avoid lifting heavy objects or engaging in activities that put you at risk for falls or injury.",
		"Wear loose, comfortable clothing made from breathable fabrics to accommodate your changing body and regulate body temperature.",
		"Take short breaks throughout the day to rest and elevate your feet, reducing fatigue and swelling.",
		"Drink ginger tea or eat ginger biscuits to help alleviate morning sickness and nausea.",
		"Avoid cleaning cat litter boxes to reduce the risk of toxoplasmosis, a parasitic infection that can harm your baby.",
		"Wash your hands frequently, especially before eating or preparing food, to reduce the risk of infections.",
		"Educate yourself about the signs of labor and when to contact your healthcare provider.",
		"Plan and prepare for your baby's arrival by attending childbirth classes and creating a birth plan.",
		"Communicate openly with your partner about your feelings, concerns, and needs during your pregnancy.",
		"Join a pregnancy support group or connect with other expectant mothers to share experiences and receive support.",
		"Take time for yourself and engage in activities that bring you joy and relaxation, such as reading or taking a warm bath.",
		"Keep your doctor informed of any medications you are taking to ensure they are safe during pregnancy.",
		"Avoid exposure to harmful chemicals, such as those found in certain cleaning products or paint fumes.",
		"Use a seat belt with the lap portion positioned under your belly and the shoulder portion crossing your chest.",
		"Avoid changing cat litter, as it may contain toxoplasmosis which can harm the fetus.",
		"Get the flu vaccine to protect yourself and your baby from influenza complications.",
		"Consume adequate amounts of iron-rich foods or take iron supplements to prevent anemia.",
		"Practice good posture by standing up straight and using a support belt to alleviate back pain.",
		"Avoid processed and junk foods high in sugar, salt, and unhealthy fats.",
		"Consume adequate calcium through dairy, leafy greens, or supplements for your baby's bone development.",
		"Take frequent breaks and stretch your legs to prevent blood clots, especially during long periods of sitting.",
		"Use a humidifier to help alleviate nasal congestion, a common pregnancy symptom.",
		"Avoid contact with people who have contagious illnesses to reduce the risk of infections.",
		"Consume enough vitamin C through citrus fruits, berries, or supplements to support your immune system.",
		"Practice relaxation techniques like yoga, meditation, or deep breathing to manage stress and anxiety.",
		"Avoid consuming unpasteurized dairy products to reduce the risk of listeria infection.",
		"Wear a supportive bra to alleviate breast tenderness and prevent back pain.",
		"Consume omega-3 fatty acids through fish, nuts, or supplements for your baby's brain development.",
		"Avoid exposure to secondhand smoke, as it can be harmful to you and your baby.",
		"Stay active by incorporating low-impact exercises like swimming or stationary cycling into your routine.",
		"Use a pregnancy-safe insect repellent to protect against mosquito-borne illnesses like Zika virus.",
		"Consume adequate vitamin D through sunlight exposure, fortified foods, or supplements for bone health.",
		"Avoid contact with rodents and their droppings to reduce the risk of infections like lymphocytic choriomeningitis virus.",
		"Take frequent bathroom breaks to avoid urinary tract infections and bladder discomfort.",
		"Use a pregnancy-safe sunscreen with at least SPF 30 to protect your skin from sun damage.",
		"Consume adequate amounts of folic acid through leafy greens, fortified foods, or supplements to prevent birth defects.",
		"Avoid consuming large amounts of vitamin A, as it can be harmful to your baby in high doses.",
		"Stay informed about your baby's development by reading pregnancy books or reliable online resources.",
		"Avoid exposure to lead, commonly found in old paint, as it can harm your baby's development.",
		"Consume adequate protein through lean meats, fish, beans, or dairy for your baby's growth.",
		"Practice good sleep hygiene by maintaining a consistent sleep schedule and creating a relaxing bedtime routine.",
		"Avoid consuming raw sprouts, as they may contain harmful bacteria like salmonella.",
		"Use a pregnancy-safe acne treatment to manage hormonal breakouts and avoid products with retinoids.",
		"Consume adequate zinc through lean meats, seafood, or supplements for your baby's immune system development.",
		"Avoid exposure to X-rays, unless absolutely necessary and approved by your healthcare provider.",
		"Wear compression stockings to improve circulation and reduce swelling in your legs and feet.",
		"Consume adequate vitamin B6 through poultry, fish, or supplements to alleviate morning sickness.",
		"Avoid consuming excessive amounts of liver, as it contains high levels of vitamin A which can be harmful.",
		"Use a pregnancy-safe hair dye or highlight formula if you choose to color your hair during pregnancy.",
		"Consume adequate magnesium through nuts, seeds, or supplements to support your baby's bone and teeth development.",
		"Avoid exposure to pesticides and herbicides, as they may be harmful to your baby's development.",
		"Use a pregnancy-safe stretch mark cream or oil to keep your skin moisturized and reduce itching.",
		"Consume adequate potassium through bananas, potatoes, or supplements to maintain healthy blood pressure.",
		"Avoid consuming raw honey, as it may contain botulism spores that can harm your baby.",
		"Use a pregnancy-safe massage oil or lotion when getting a prenatal massage to relax and alleviate muscle tension.",
		"Consume adequate choline through eggs, liver, or supplements for your baby's brain and spinal cord development.",
		"Avoid exposure to mercury by limiting your intake of high-mercury fish like shark, swordfish, and king mackerel.",
		"Use a pregnancy-safe toothpaste and mouthwash to maintain good oral hygiene and prevent gum disease.",
		"Consume adequate iodine through iodized salt, seafood, or supplements for your baby's thyroid function.",
		"Avoid consuming excessive amounts of caffeine, as it can increase the risk of miscarriage and low birth weight.",
		"Use a pregnancy-safe body lotion or oil to keep your skin hydrated and reduce itching as your belly grows.",
		"Consume adequate selenium through brazil nuts, seafood, or supplements for your baby's cognitive development.",
		"Avoid exposure to bisphenol A (BPA) by choosing BPA-free bottles, containers, and canned goods.",
		"Use a pregnancy-safe shampoo and conditioner to manage hair changes and maintain healthy locks.",
		"Consume adequate copper through nuts, seeds, or supplements for your baby's heart, blood vessels, and immune system.",
		"Avoid consuming raw or undercooked eggs to reduce the risk of salmonella infection.",
		"Use a pregnancy-safe facial moisturizer to keep your skin hydrated and manage hormonal acne.",
		"Consume adequate manganese through nuts, seeds, or supplements for your baby's bone and cartilage development.",
		"Avoid exposure to triclosan, an antibacterial agent found in some toothpastes and soaps, as it may disrupt hormones.",
		"Use a pregnancy-safe body scrub or exfoliant to manage dry, flaky skin and promote circulation.",
		"Consume adequate chromium through broccoli, potatoes, or supplements for your baby's glucose metabolism.",
		"Avoid consuming raw or unpasteurized milk and cheese to reduce the risk of listeria infection.",
		"Use a pregnancy-safe hand cream or lotion to keep your hands moisturized and prevent dryness.",
		"Consume adequate molybdenum through legumes, grains, or supplements for your baby's enzyme function.",
		"Avoid exposure to toluene, a chemical found in some paints and nail polish, as it may harm fetal development.",
		"Use a pregnancy-safe belly balm or oil to soothe stretched skin and reduce itching as your belly grows.",
		"Consume adequate fluoride through drinking water, toothpaste, or supplements for your baby's tooth development.",
		"Avoid consuming excessive amounts of processed meats, as they may contain harmful preservatives and increase the risk of gestational diabetes.",
		"Use a pregnancy-safe foot cream or balm to alleviate dry, cracked skin and promote relaxation.",
		"Avoid exposure to phthalates, chemicals found in some plastics and personal care products, as they may disrupt hormones.",
		"Take time to bond with your baby by talking, singing, or reading to your belly, as your baby can hear and recognize your voice.",
		"Trust your instincts and don't hesitate to reach out to your healthcare provider with any concerns or questions throughout your pregnancy."
	  ];