import React, { useEffect } from 'react';

export default function PaymentSuccess() {
  useEffect(() => {
    // Google AdWords conversion tracking script
    const conversionScript = document.createElement('script');
    conversionScript.innerHTML = `
      gtag('event', 'conversion', {
        'send_to': 'AW-16549435740/RjRSCPjq66sZENyysdM9',
        'value': 1.0,
        'currency': 'USD',
        'transaction_id': ''
      });
    `;
    document.body.appendChild(conversionScript);

    // Stripe customer tracking
    localStorage.setItem("stripeCustomer", "true");
    localStorage.setItem("stripeCustomerJustPaid", "true");

    // Redirect to dashboard after 2 seconds
    setTimeout(() => {
      window.location.href = '/dashboard';
    }, 2000);
  }, []);

  return <div>Payment Success</div>;
}