import React, { useState, useEffect } from "react";
import "./App.css";
import logo from './media/logo_black_transparent.png'
import "@aws-amplify/ui-react/styles.css";
import { getCurrentUser } from '@aws-amplify/auth';
import {
  Button,
  Flex,
  Heading,
  TextField,
  View,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import axios from "axios";
import stripe from 'stripe';
import { listAppUsers } from "./graphql/queries";
import { createAppUser, updateAppUser } from "./graphql/mutations";
import { listAppUserChildren } from './graphql/queries';
import { updateAppUserChildren, createAppUserChildren, deleteAppUserChildren } from './graphql/mutations';
import { createAffiliates } from './graphql/mutations';

import { generateClient } from 'aws-amplify/api';
import { pregnancyDailyMessages } from './data/PregnancyMessages';
import { ParenthoodMessages } from './data/ParenthoodMessages';
import { sendText, sendRandomTip, sendReferralEmail, getAIResponse, handlesendSignupNotificationToTeam } from './Functions'

import babyImage from './mom1.png';
import OnboardingModal from "./OnboardingModal";

import PublicNav from "./pages/PublicNav";




/*
List:
Twilio filing
Enable support STOP and STOPEMAIL inbound messages
Backup twilio phone number
Make backup Twilio account asap for phone number
Check when user churns, turn to inactive; add in timestamp when they subscribed so it becomes inactive later
Maybe redo the cron job for naming, delete the not working system payments API
Way to cancel subscription
Better referrals. After referral sent, it provides alert but could cleasr text field too
Email / Text Me this button from the My Journey Tab
Prevent past due dates       
Known issue - intl numbers not able to text back and have to use web app mainly; inbound is key so need to explore further. Maybe Whatsapp.

*/

const client = generateClient();
let user_email;

const PregnancyDayContent = React.memo(({ currentDay, week, trimester, daysPregnantJourney, daysLeft, weeksLeft, weeksLeftDayRemainder, userDueDate, remainder }) => {
const dailyMessageCustom = pregnancyDailyMessages[currentDay.toString()] || "Information for this day is not available.";


  return (
    <div>
      <h2>{week} weeks ({trimester})</h2>
      <div dangerouslySetInnerHTML={{ __html: `
        You are ${daysPregnantJourney} days (${week} weeks and ${remainder} days)  pregnant. <br /> <br />
        You are ${daysLeft} days (${weeksLeft} weeks and ${weeksLeftDayRemainder} days) away from your due date on ${userDueDate}.<br /> <br />
        ${dailyMessageCustom}
      ` }} />
    </div>
  );
});

function calculatePregnancyDetails(userDueDate, currentDay) {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const fullTermWeeks = 40;
  const dueDate = new Date(userDueDate);
  const startDate = new Date(dueDate.getTime() - fullTermWeeks * 7 * 24 * 60 * 60 * 1000);
  const daysPregnant = Math.floor((today - startDate) / (24 * 60 * 60 * 1000));
  const week = Math.floor(currentDay / 7);
  const remainder = currentDay % 7;
  const daysLeft = 280 - currentDay;
  const weeksLeft = 40 - week;
  const weeksLeftDayRemainder = daysLeft % 7;

  let trimester;
  if (week >= 1 && week <= 12) {
    trimester = 'First Trimester';
  } else if (week >= 13 && week <= 24) {
    trimester = 'Second Trimester';
  } else {
    trimester = 'Third Trimester';
  }

  return {
    daysPregnantJourney: currentDay,
    week,
    trimester,
    daysLeft,
    weeksLeft,
    weeksLeftDayRemainder,
    remainder
  };
}


const ParenthoodJourney = ({ appUser, switchTab}) => {
  const [children, setChildren] = useState([]);

  useEffect(() => {
    fetchChildren();
  }, []);

  const fetchChildren = async () => {
    try {
      const response = await client.graphql({
        query: listAppUserChildren,
        variables: {
          filter: { appuserid: { eq: appUser.id } }
        }
      });

      if (response.data && response.data.listAppUserChildren && response.data.listAppUserChildren.items) {
        const sortedChildren = response.data.listAppUserChildren.items.sort((a, b) => new Date(b.birthday) - new Date(a.birthday));
        /*
        setAppUser(prevState => ({
          ...prevState,
          due_date: dueDateValue,
        }));
        */
        setChildren(sortedChildren.map(child => ({
          ...child,
          currentDay: calculateChildAge(child.birthday)
        })));
      } else {
        console.error('Invalid response format:', response);
        setChildren([]);
      }
    } catch (error) {
      console.error('Error fetching children:', error);
      setChildren([]);
    }
  };

  const calculateChildAge = (birthday) => {
    const today = new Date();
    const birthDate = new Date(birthday);
    const ageDays = Math.floor((today - birthDate) / (24 * 60 * 60 * 1000));
    return ageDays;
  };

  const formatAge = (days) => {
    const years = Math.floor(days / 365);
    const months = Math.floor((days % 365) / 30);
    const weeks = Math.floor(((days % 365) % 30) / 7);

    const formattedAge = [];
    if (years > 0) formattedAge.push(`${years} year${years > 1 ? 's' : ''}`);
    if (months > 0) formattedAge.push(`${months} month${months > 1 ? 's' : ''}`);
    if (weeks > 0) formattedAge.push(`${weeks} week${weeks > 1 ? 's' : ''}`);

    return formattedAge.join(', ');
  };

  const handlePreviousDay = (childId) => {
    setChildren(prevChildren =>
      prevChildren.map(child =>
        child.id === childId ? { ...child, currentDay: Math.max(child.currentDay - 1, 0) } : child
      )
    );
  };

  const handleNextDay = (childId) => {
    setChildren(prevChildren =>
      prevChildren.map(child =>
        child.id === childId ? { ...child, currentDay: child.currentDay + 1 } : child
      )
    );
  };


  return (
    <div>
      <h2>Parenthood</h2>
      {children.length === 0 ? (
        
        <p>
        You haven't added any children yet.<button onClick={() => switchTab("MyProfile")} className="link-button">Add them in your profile</button>to see pregnancy journey details.
      </p>
      ) : (
        children.map(child => {
          const dailyMessage = ParenthoodMessages[child.currentDay.toString()] || "Information for this day is not available.";
          const formattedAge = formatAge(child.currentDay);

          return (
            <div key={child.id}>
              <h3>{child.name}</h3>
              <p>Birthday: {child.birthday}</p>
              <p>Gender: {child.gender}</p>
              <p>Age: {child.currentDay} days ({formattedAge})</p>
              <div>
                <button className="navigation-button" onClick={() => handlePreviousDay(child.id)}>← Previous Day</button>
                <button className="navigation-button" onClick={() => handleNextDay(child.id)}>Next Day →</button>
              </div>
              <br />
              <div dangerouslySetInnerHTML={{ __html: dailyMessage }} />
              <hr />
            </div>
          );
        })
      )}
    </div>
  );
};



const App = ({ signOut }) => {
  const [appUser, setAppUser] = useState({});
  const [activeTab, setActiveTab] = useState("MyJourney");
  const [currentDay, setCurrentDay] = useState(null);
  const [alert, setAlert] = useState({ message: '', type: '' });
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [fromEmail, setFromEmail] = useState('');
  const [toEmail, setToEmail] = useState('');
  const [loadingUser, setLoadingUser] = useState(false);
  let started = false;

  const [showOnboardingModal, setShowOnboardingModal] = useState(false);

  const [existingName, setExistingName] = useState('');
  const [existingPhoneNumber, setExistingPhoneNumber] = useState('');
  const [existingYourGender, setExistingYourGender] = useState('');
  const [existingDueDate, setExistingDueDate] = useState('');

  const [isUserDataLoaded, setIsUserDataLoaded] = useState(false);

  const [isNewUser, setIsNewUser] = useState(false);  

  const [hasChildren, setHasChildren] = useState(null);

  //5/1/24 - weird quirk due to some complexity - just runs this 1x after the child data loads. If the user changes the child data or other data, it will not run again until page reload, which is fine.
  const [handledOnboardingModal, setHandledOnboardingModal] = useState(false);


  const handleShowOnboardingModal = () => {
    const hasPhoneNumber = !!appUser.phone_number;
    const hasFullName = !!appUser.full_name;
    const hasDueDate = !!appUser.due_date;
    const hasYourGender = !!appUser.your_gender;
    const hasChildrenBool = hasChildren === 'true';
    const hasChild = hasChildren === 'true';

    console.log(hasPhoneNumber, hasFullName, hasDueDate, hasChildrenBool, hasChild);
  
    //if (!hasPhoneNumber || !hasFullName || (!hasDueDate && !hasChildrenBool)) {
      if (!hasPhoneNumber || (!hasDueDate && hasChild!=true)) {
    setShowOnboardingModal(true); // Show the OnboardingModal for existing users with missing information
    } else {
    setShowOnboardingModal(false); // Hide the OnboardingModal for existing users with complete information
    }
  }; 
  
  
  const handleOnboardingSubmit = async (userData) => {
    try {
      // Update the appUser state with the submitted data
      setAppUser((prevState) => ({
        ...prevState,
        full_name: userData.name,
        phone_number: userData.phoneNumber,
        your_gender: userData.yourGender,
        due_date: userData.dueDate,
      }));
  
      // Update the user data in the database
      const updatedUserResponse = await client.graphql({
        query: updateAppUser,
        variables: {
          input: {
            id: appUser.id,
            full_name: userData.name,
            phone_number: userData.phoneNumber.replace(/\D/g, ''),
            your_gender: userData.yourGender,
            due_date: userData.dueDate,
            // ... (other fields to update)
          }
        }
      });
      console.log('User updated successfully:', updatedUserResponse.data.updateAppUser);
  
      // If there's child data, create the child information
      if (userData.childData) {
        const childData = {
          name: userData.childData.name,
          birthday: userData.childData.birthday,
          gender: userData.childData.gender,
          appuserid: updatedUserResponse.data.updateAppUser.id,
          appuseremail: updatedUserResponse.data.updateAppUser.email_address,
        };
  
        await client.graphql({
          query: createAppUserChildren,
          variables: {
            input: childData
          }
        });
        console.log('Child information added successfully');
      }
    } catch (error) {
      console.error('Error updating user or adding child:', error);
    }
  };
  

  useEffect(() => {
    if (appUser.due_date) {
      setDueDateValue(appUser.due_date);
    }
  }, [appUser.due_date]);

  const [dueDateValue, setDueDateValue] = useState('');

  const switchTab = (tabName) => {
    setActiveTab(tabName);
  };

  const sendAIQuery = () => {
    const aiResponseContainer = document.getElementById('aiResponseContainer');
    const loader = aiResponseContainer.querySelector('.loader');
    const responseText = aiResponseContainer.querySelector('.response-text');
    const initialText = aiResponseContainer.querySelector('.initial-text');
  
    // Hide the initial text
    if (initialText) {
      initialText.style.display = 'none';
    }
  
    // Clear previous response text
    if (responseText) {
      responseText.innerHTML = '';
    } else {
      // Create response text element if it doesn't exist
      const newResponseText = document.createElement('div');
      newResponseText.classList.add('response-text');
      aiResponseContainer.appendChild(newResponseText);
    }
  
    // Show the loader
    loader.style.display = 'flex';
  
    const aiQuery = document.querySelector('input[name="aiQueryInput"]').value;

    getAIResponse(aiQuery)
      .then(data => {
        console.log("Query success!", data);
        // Hide the loader
        loader.style.display = 'none';
        // Display response in the response text element
        responseText.innerHTML = data.response;
      })
      .catch(error => {
        console.error("Failed query", error);
        // Hide the loader
        loader.style.display = 'none';
        // Display error message in the response text element
        responseText.innerHTML = 'Error occurred. Please try again.';
      });
  };

  const submitAIQuery = (query) => {
    const aiQueryInput = document.querySelector('input[name="aiQueryInput"]');
    aiQueryInput.value = query;
    sendAIQuery();
  };

    //let aiQuery;
    //ADMINQUERY
/*
<div>
             <br />
             <center>
             <div id='AdminaiResponseContainer'>
               <i>Your answer will appear here...
               </i>
             </div>
             <input type='text' id='AdminaiQueryInput' name='AdminaiQueryInput' placeholder='Ask me anything' />
             <button className='aibutton' onClick={sendAdminAIQuery}>Ask AI Assistant
             </button>
             </center>
             </div>
*/             

  const sendAdminAIQuery = () => {

    const aiResponseContainer1 = document.getElementById('AdminaiResponseContainer');
    aiResponseContainer1.innerHTML = "<img src='./Infinity-1s-200px.gif' alt='Loading...' />";

    /*
    const toEmailValue = document.querySelector('input[name="toEmailValue"]').value;
    const fromEmailValue = appUser.email_address;
    */
    
  var aiQuery = document.querySelector('input[name="AdminaiQueryInput"]').value;
   if(aiQuery === '' || aiQuery === null || aiQuery === undefined || aiQuery === 'undefined' || aiQuery === 'null'){
    aiQuery = 'Hello';
   }

    getAIResponse(aiQuery)
      .then(data => { console.log("Query success!", data); const aiResponseContainer = document.getElementById('AdminaiResponseContainer');
      aiResponseContainer.innerHTML = data.response; })
      .catch(error => console.error("Failed query", error));
  };

  const handleSendReferralEmail = () => {
    const toEmailValue = document.querySelector('input[name="toEmailValue"]').value;
    const fromEmailValue = appUser.email_address;
    sendReferralEmail(fromEmailValue, toEmailValue)
      .then(data => { console.log("Email sent successfully!", data); showAlert('Referral sent successfully. \u{1F680}', 'success'); })
      .catch(error => console.error("Failed to send email", error));
  };

  const handleFromEmailChange = (e) => setFromEmail(e.target.value);
  const handleToEmailChange = (e) => setToEmail(e.target.value);

  const showAlert = (message, type) => {
    setAlert({ message, type });
    setTimeout(() => {
      setAlert({ message: '', type: '' });
    }, 3000);
  };

  const showAlertSubscription = (message, type) => {
    setAlert({ message, type });
    setTimeout(() => {
      setAlert({ message: '', type: '' });
    }, 30000);
  };

  const updateSubscriptionStatus = async (userId) => {
    try {
      await client.graphql({
        query: updateAppUser,
        variables: {
          input: {
            id: userId,
            paid_subscription_status: true,
          },
        },
      });
      localStorage.removeItem("stripeCustomerJustPaid");
      window.location.reload();
      //    navigate(0); // Navigate to the current path, effectively reloading the page
    } catch (error) {
      console.error('Error updating subscription status:', error);
    }
  };

  const updateSubscriptionStatusFailed = async (userId) => {
    try {
      await client.graphql({
        query: updateAppUser,
        variables: {
          input: {
            id: userId,
            paid_subscription_status: false,
          },
        },
      });
      localStorage.removeItem("stripeCustomerJustPaid");
    } catch (error) {
      console.error('Error updating subscription status:', error);
    }
  };

  const loadCurrentUser = async () => {
    if (loadingUser) return;

    try {
      setLoadingUser(true);
      const currentUserInfo = await getCurrentUser();
      const loginId = currentUserInfo.signInDetails.loginId;
      user_email = loginId;

      const response = await client.graphql({
        query: listAppUsers,
        variables: {
          filter: { email_address: { eq: loginId } }
        }
      });

      const items = response.data.listAppUsers.items;

      if (items.length === 0 && started === false) {
        started = true;
        //localStorage.setItem("onboardingModal", 'true');
        setIsNewUser(true); // Set isNewUser to true for new users
        const newUserResponse = await client.graphql({
          query: createAppUser,
          variables: {
            input: {
              full_name: '',
              email_address: loginId,
              phone_number: null,
              due_date: null,
              days_pregnant_value: 0,
              communication_frequency: 'daily',
              email_notification_status: 'true',
              phone_notification_status: 'true'
            }
          }
        });
        const newUser = newUserResponse.data.createAppUser;
        handlesendSignupNotificationToTeam(loginId);
        setAppUser({
          full_name: newUser.full_name,
          email_address: loginId,
          phone_number: newUser.phone_number,
          id: newUser.id,
          due_date: newUser.due_date,
          days_pregnant_value: 0,
          communication_frequency: newUser.communication_frequency,
          email_notification_status: newUser.email_notification_status,
          phone_notification_status: newUser.phone_notification_status
        });
        setShowOnboardingModal(true); // Show the OnboardingModal
        if(localStorage.getItem('partnerLastTouch') != null){

          let partnerLastTouch = localStorage.getItem('partnerLastTouch');
          let email_address = loginId; // assuming loginId is the email address; can remove this if you want since you use loginId below      

          const affiliateUserResponse = await client.graphql({
            query: createAffiliates,
            variables: {
              input: {
                email_address: loginId,
                affiliate_value: partnerLastTouch
              }
            }
          });
          const affiliateUser = affiliateUserResponse.data.createAffiliates;
        }
      } else {
        if (started !== true) {
          const existingUser = items[0];
          setAppUser({
            full_name: existingUser.full_name,
            email_address: existingUser.email_address,
            phone_number: existingUser.phone_number,
            id: existingUser.id,
            due_date: existingUser.due_date,
            days_pregnant_value: existingUser.days_pregnant_value,
            communication_frequency: existingUser.communication_frequency,
            email_notification_status: existingUser.email_notification_status,
            phone_notification_status: existingUser.phone_notification_status,
            paid_subscription_status: existingUser.paid_subscription_status,
            your_gender: existingUser.your_gender, // Add this line
          });

          // Store the existing user's data in separate state variables
  setExistingName(existingUser.full_name);
  setExistingPhoneNumber(existingUser.phone_number);
  setExistingYourGender(existingUser.your_gender);
  setExistingDueDate(existingUser.due_date);

  //await fetchChildren(); // Call fetchChildrenData with the user's id


  setIsUserDataLoaded(true); // Set isUserDataLoaded to true after setting the existing user's data

  //handleShowOnboardingModal(); // Call the function here

  // Check if the existing user has a phone number, full name, and due date
  
  /*
  const hasPhoneNumber = !!existingUser.phone_number;
  const hasFullName = !!existingUser.full_name;
  const hasDueDate = !!existingUser.due_date;
  const hasChildren = hasChildren === 'true'; // Convert the hasChildren state to a boolean

  if (!hasPhoneNumber || !hasFullName || (!hasDueDate && !hasChildren)) {
    setShowOnboardingModal(true); // Show the OnboardingModal for existing users with missing information
  } else {
    setShowOnboardingModal(false); // Hide the OnboardingModal for existing users with complete information
  }
*/

// Store the existing user's data in separate state variables
/*
  setExistingName(existingUser.full_name);
  setExistingPhoneNumber(existingUser.phone_number);
  setExistingDueDate(existingUser.due_date);

          // Check if the existing user has a phone number, due date, or at least one child
      const hasPhoneNumber = !!existingUser.phone_number;
      const hasDueDate = !!existingUser.due_date;
      //const hasChildren = existingUser.appUserChildrenItems && existingUser.appUserChildrenItems.length > 0;

      var hasChildren = 'false';

      try {
        const response = await client.graphql({
          query: listAppUserChildren,
          variables: {
            filter: { appuserid: { eq: appUser.id } }
          }
        });
    
        console.log(appUser.id);
        console.log(response.data);
    
        if (response.data && response.data.listAppUserChildren && response.data.listAppUserChildren.items.length > 0) {
          hasChildren = 'true';
        }
      } catch (error) {
        console.log(error);
      }
    
      if (!hasPhoneNumber || (!hasDueDate && hasChildren === 'false')) {
        setShowOnboardingModal(true); // Show the OnboardingModal
      } else {
        setShowOnboardingModal(false); // Hide the OnboardingModal
      }
*/
      

          if(existingUser.paid_subscription_status!=='true'){
            {showAlertSubscription('You do not have a valid subscription. Please subscribe now.', 'error');}          
            switchTab("MySubscription");
            }
        }
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoadingUser(false);
    }
  };

  useEffect(() => {
    loadCurrentUser();
  }, []);


  //TODO - weird quirk 5/1/24 - it runs the onboarding check once the children data loads/changes but checks phone, due date, etc. This is bc the children data loads separately/later from the original user data
  useEffect(() => {
    const handleOnboardingModalOnce = () => {
      handleShowOnboardingModal();
      setHandledOnboardingModal(true);
    };
  
    if (!handledOnboardingModal && hasChildren !== null) {
      handleOnboardingModalOnce();
    }
  }, [hasChildren]);

useEffect(() => {


  const fetchChildrenData = async () => {
    if (appUser && appUser.id) {
      try {
        const response = await client.graphql({
          query: listAppUserChildren,
          variables: {
            filter: { appuserid: { eq: appUser.id } }
          }
        });


        if (response.data && response.data.listAppUserChildren && response.data.listAppUserChildren.items.length > 0) {
          setHasChildren('true');
          //hasChildren = 'true';
        } else {
          //hasChildren = 'false';
          setHasChildren('false');
        }
//        handleShowOnboardingModal(); // Call the function here after updating hasChildren

      } catch (error) {
        console.log(error);
        setHasChildren('false');

      }
      
      /*
      if (appUser && appUser.id) {
        fetchChildrenData(appUser.id);
        handleShowOnboardingModal(); // Call the function to handle showing/hiding the OnboardingModal
      }
      */
      
    }
  };

  fetchChildrenData();
  

  // Store the existing user's data in separate state variables
  
  /*
  setExistingName(appUser.full_name);
  setExistingPhoneNumber(appUser.phone_number);
  setExistingDueDate(appUser.due_date);
  */
  //console.log(appUser.full_name);

  //setIsUserDataLoaded(true); // Set isUserDataLoaded to true after setting the existing user's data

  // Check if the existing user has a phone number, due date, or at least one child
  const hasPhoneNumber = !!appUser.phone_number;
  const hasDueDate = !!appUser.due_date;

//  if (!hasPhoneNumber || (!hasDueDate && hasChildren === 'false')) {
  /*
  if (!hasPhoneNumber || (!hasDueDate && hasChildren === 'false') || appUser.full_name === '') {
    setShowOnboardingModal(true); // Show the OnboardingModal
  } else {
    if(localStorage.getItem("active_modal") !== 'true'){
    setShowOnboardingModal(false); // Hide the OnboardingModal
    }
    //}
  }
  */

  
/*
  if (appUser.paid_subscription_status !== 'true') {
    showAlertSubscription('You do not have a valid subscription. Please subscribe now.', 'error');
    switchTab("MySubscription");
  }
  */
}, [appUser]);
  

  useEffect(() => {
    const paymentSuccess = localStorage.getItem("stripeCustomerJustPaid");
    if (paymentSuccess === "true" && appUser.id) {
      updateSubscriptionStatus(appUser.id);
      localStorage.removeItem("stripeCustomerJustPaid");
      showAlert('Subscription payment is complete! Thank you and good luck on your journey. \u{1F680}', 'success');
    } else if (paymentSuccess === "false" && appUser.id) {
      updateSubscriptionStatusFailed(appUser.id);
      localStorage.removeItem("stripeCustomerJustPaid");
      localStorage.removeItem("stripeCustomerJustCanceledPayment");
    }
  });

  useEffect(() => {
    if (appUser.due_date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const fullTermWeeks = 40;
      const dueDate = new Date(appUser.due_date);
      const startDate = new Date(dueDate.getTime() - fullTermWeeks * 7 * 24 * 60 * 60 * 1000);
      const daysPregnant = Math.floor((today - startDate) / (24 * 60 * 60 * 1000));
      setCurrentDay(daysPregnant);
    }
  }, [appUser.due_date]);

  const handleUserSubmit = async (event) => {
    event.preventDefault();
  
    const formData = new FormData(event.target);
    const formValues = Object.fromEntries(formData.entries());
  
    try {
      const updatedUserResponse = await client.graphql({
        query: updateAppUser,
        variables: {
          input: {
            id: appUser.id,
            full_name: appUser.full_name,
            email_address: appUser.email_address,
            //phone_number: appUser.phone_number.replace(/\D/g, '') : null,
            //phone_number: appUser.phone_number ?appUser.phone_number.replace(/\D/g, '') : null, - worked as of 5-14-24
            //phone_number: appUser.phone_number, //removed the replace because it happens in another function handlechange; to support intl numbers
            phone_number: appUser.phone_number || null, // Allow null value for phone number
            due_date: appUser.due_date,
            days_pregnant_value: parseInt(appUser.days_pregnant_value, 10),
            communication_frequency: appUser.communication_frequency,
            email_notification_status: appUser.email_notification_status,
            phone_notification_status: appUser.phone_notification_status,
            your_gender: appUser.your_gender, // Add this line

          }
        }
      });
      const updatedUser = updatedUserResponse.data.updateAppUser;
      console.log('User updated successfully:', updatedUser);
      showAlert('Profile updated successfully! \u{1F680}', 'success');
    } catch (error) {
      console.error('Error updating user:', error);
      showAlert('Failed to update profile! \u{1F641} Check your info including country codes (e.g. YYYY-MM-DD and +1 555 123 1234).', 'error');
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    let updatedValue = value;

    switch (name) {
      case 'full_name':
      case 'email_address':
      case 'phone_number':
        //updatedValue = value ? value.replace(/\D/g, '') : null; //Cleans phone number input or sets it to null
        updatedValue = value.replace(/[^0-9+]/g, '');
      case 'communication_frequency':
      case 'paid_subscription_type':
      case 'paid_subscription_status':
      case 'paid_subscription_last_modified':
      case 'email_notification_status':
      case 'phone_notification_status':
      case 'gift_subscription_status':
      case 'gift_subscription_referred':
        updatedValue = value !== '' ? String(value) : '';
        break;
      case 'days_pregnant_value':
        updatedValue = value !== '' ? parseInt(value, 10) : '';
        break;
        case 'your_gender': // Add this case
      updatedValue = value;
      break;
      default:
        updatedValue = value;
        break;
    }

    setAppUser(prevState => ({
      ...prevState,
      [name]: updatedValue,
    }));
  };

  /*
  const handleExpectingChildrenChange = (event) => {
    const { name, value } = event.target;
    setAppUser(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };
  */
  const handleDueDateChange = (event) => {
    setDueDateValue(event.target.value);
  };

  const handleSaveDueDate = async () => {
    
    var dueDateValueInput = dueDateValue;

        if (dueDateValue) {
      dueDateValueInput = dueDateValue;
    } else {
      dueDateValueInput = null;
    }
    

    try {
      const updatedUserResponse = await client.graphql({
        query: updateAppUser,
        variables: {
          input: {
            id: appUser.id,
            //due_date: dueDateValue,
            due_date: dueDateValueInput,
          }
        }
      });
      const updatedUser = updatedUserResponse.data.updateAppUser;
      setAppUser(prevState => ({
        ...prevState,
        due_date: dueDateValue,
      }));
      console.log('Due date updated successfully:', updatedUser);
      showAlert('Due date updated successfully! \u{1F680}', 'success');
    } catch (error) {
      console.error('Error updating due date:', error);
      showAlert('Failed to update due date! \u{1F641} Check your info and formatting (e.g. YYYY-MM-DD).', 'error');
    }
  };

  const handlePreviousDay = () => {
    setCurrentDay((prevDay) => Math.max(prevDay - 1, 0));
  };

  const handleNextDay = () => {
    setCurrentDay((prevDay) => Math.min(prevDay + 1, 279));
  };

  const handleBackToToday = () => {
    if (appUser.due_date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const fullTermWeeks = 40;
      const dueDate = new Date(appUser.due_date);
      const startDate = new Date(dueDate.getTime() - fullTermWeeks * 7 * 24 * 60 * 60 * 1000);
      const daysPregnant = Math.floor((today - startDate) / (24 * 60 * 60 * 1000));
      setCurrentDay(daysPregnant);
    }
  };

const ChildrenInfo = ({ appUser, hasChildren, setHasChildren }) => {
  const [children, setChildren] = useState([]);
  const [name, setName] = useState('');
  const [birthday, setBirthday] = useState('');
  const [gender, setGender] = useState('');
  const [editingChildId, setEditingChildId] = useState(null);

  const calculateChildAge = (birthday) => {
    const today = new Date();
    const birthDate = new Date(birthday);
    const ageDays = Math.floor((today - birthDate) / (24 * 60 * 60 * 1000));
    return ageDays;
  };


  useEffect(() => {
    fetchChildren();
  }, []);

  const fetchChildren = async () => {
    try {
      const response = await client.graphql({
        query: listAppUserChildren,
        variables: {
          filter: { appuserid: { eq: appUser.id } }
        }
      });
  
      if (response.data && response.data.listAppUserChildren && response.data.listAppUserChildren.items) {
        setChildren(response.data.listAppUserChildren.items);

        
        //globalHasChildren='true';
      } else {
        console.error('Invalid response format:', response);
        // Handle the case when the response format is invalid
        // You can set a default value for children or display an error message
        setChildren([]);
      }
    } catch (error) {
      console.error('Error fetching children:', error);
      // Handle the error case
      // You can set a default value for children or display an error message
      setChildren([]);
    }
  };

//  handleShowOnboardingModal(); // Call the function here

  const handleSubmitChild = async (e) => {
    e.preventDefault();
    try {
      const childData = {
        name,
        birthday,
        gender,
        appuserid: appUser.id,
        appuseremail: appUser.email_address,
      };
      if (editingChildId) {
        await client.graphql({
          query: updateAppUserChildren,
          variables: {
            input: { ...childData, id: editingChildId }
          }
        });
        setEditingChildId(null);
      } else {
        await client.graphql({
          query: createAppUserChildren,
          variables: {
            input: childData
          }
        });
      }
      setName('');
      setBirthday('');
      setGender('');
      fetchChildren();
      showAlert('Your info about your child has been updated! \u{1F680}', 'success');
    } catch (error) {
      console.error('Error adding/updating child:', error);
    }
  };

  const handleEdit = (child) => {
    setEditingChildId(child.id);
    setName(child.name);
    setBirthday(child.birthday);
    setGender(child.gender);
  };

  const handleDeleteChild = async (childId) => {
    try {
      await client.graphql({
        query: deleteAppUserChildren,
        variables: {
          input: { id: childId }
        }
      });
      fetchChildren();
      showAlert('Your info about your child has been updated.', 'success');      
    } catch (error) {
      console.error('Error deleting child:', error);
    }
  };

  return (
    <div>
  <h3>Children</h3>
  <ul className="children-list">
    {children.map((child) => (
      <li key={child.id} className="child-item">
        <span className="child-info">
          {child.name} - {child.birthday} - {child.gender}
        </span> &nbsp;&nbsp;
        <div className="child-actions">
          <button onClick={() => handleEdit(child)} className="edit-button">Edit</button>
          <button onClick={() => handleDeleteChild(child.id)} className="delete-button">Delete</button>
        </div>
      </li>
    ))}
  </ul>
  <h4>{editingChildId ? 'Edit Child' : 'Add Child'}</h4>
  <form onSubmit={handleSubmitChild} className="child-form">
    <input
      type="text"
      placeholder="Name"
      value={name}
      onChange={(e) => setName(e.target.value)}
      className="child-input"
    />
    <input
      type="date"
      placeholder="Birthday"
      value={birthday}
      onChange={(e) => setBirthday(e.target.value)}
      className="child-input"
    />
    <select
      value={gender}
      onChange={(e) => setGender(e.target.value)}
      className="child-select"
    >
      <option value="">Select Gender</option>
      <option value="Boy">Boy</option>
      <option value="Girl">Girl</option>
    </select>
    <button type="submit" className="submit-button primaryButton homepageButton">{editingChildId ? 'Update' : 'Add'}</button>
    {editingChildId && (
      <button type="button" onClick={() => setEditingChildId(null)} className="cancel-button">Cancel</button>
    )}
  </form>
</div>
  );  

}

/*
useEffect(() => {
  //console.log("name"+appUser.full_name)
  console.log(localStorage.getItem("onboardingModal"));
  if (localStorage.getItem("onboardingModal") === "true") {
    setShowOnboardingModal(true);
   localStorage.setItem("onboardingModal", "false");
  }
}, 
[]);
*/



  return (
    <View className="App">
   <PublicNav />
      {
        alert.message && (
          <div className={`alert ${alert.type}`}>
            {alert.message}
          </div>
        )
      }
{((isNewUser && showOnboardingModal) || (showOnboardingModal && isUserDataLoaded)) && (
  <OnboardingModal
    isOpen={showOnboardingModal}
    onClose={() => setShowOnboardingModal(false)}
    onSubmit={handleOnboardingSubmit}
    existingName={existingName}
    existingPhoneNumber={existingPhoneNumber}
    existingYourGender={appUser.your_gender} // Add this line
    existingDueDate={existingDueDate}
    hasChildren={hasChildren}
    babyImage={babyImage}
  />
)}
      <div style={{ width: '80%', margin: '0 auto' }}>
        <br />
        <Heading level={1} style={{ fontWeight: 'bold' }}>Welcome to Kintext</Heading>
        Breathe easy. We will text and email you every week with your pregnancy and/or parenthood updates. You can reply to them with questions or thoughts, and our AI Assistant
        will respond to you with answers. We are here for you.
        <br /><br />
        <hr />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
          <div className="tab-links">
          <button onClick={() => switchTab("MyProfile")} className={activeTab === "MyProfile" ? "active" : ""}>Profile</button>
            <button onClick={() => switchTab("MyJourney")} className={activeTab === "MyJourney" ? "active" : ""}>Pregnancy</button>
            <button onClick={() => switchTab("MyParenthoodJourney")} className={activeTab === "MyParenthoodJourney" ? "active" : ""}>Parenthood</button>
            <button onClick={() => switchTab("AIAssistant")} className={activeTab === "AIAssistant" ? "active" : ""}>AI Assistant</button>
            <button onClick={() => switchTab("Referrals")} className={activeTab === "Referrals" ? "active" : ""}>Referrals</button>
            <button onClick={() => switchTab("MySubscription")} className={activeTab === "MySubscription" ? "active" : ""}>Subscription</button>            
            <button onClick={signOut}>Sign Out</button>
          </div>
          {activeTab === "MyJourney" && (
            <div>
              <br />
              <center>
              <button className="navigation-button" onClick={handlePreviousDay}>← Previous Day</button>
              <button className="navigation-button" onClick={handleBackToToday}>Today</button>
              <button className="navigation-button" onClick={handleNextDay}>Next Day →</button>
              {appUser.due_date ? (
        currentDay !== null && (
                <PregnancyDayContent
                  currentDay={currentDay}
                  {...calculatePregnancyDetails(appUser.due_date, currentDay)}
                  userDueDate={appUser.due_date}
                />
        )
      ) : (
        <p>
          Please<button onClick={() => switchTab("MyProfile")} className="link-button">set your due date</button>to see pregnancy journey details.
        </p>
              )}
              <br />
              <Button onClick={() => sendRandomTip(appUser.phone_number, 'true', appUser.email_address, 'true')}>Text Me a Tip Now</Button>
              </center>
            </div>
          )}
           {activeTab === "MyParenthoodJourney" && (
        <div>
          <br />
          <ParenthoodJourney appUser={appUser} switchTab={switchTab} />
            </div>
          )}      
          {activeTab === "AIAssistant" && (
          <div>
          <div>
            <br />
            <center>
            <div id='aiResponseContainer'>
          <i className="initial-text">Your answer will appear here...</i>
          <div className="loader">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
          <div className="response-text"></div>
            </div>
            <input type='text' id='aiQueryInput' name='aiQueryInput' placeholder='Ask me anything' />
            <br /><br />
        <button className='aibutton' onClick={sendAIQuery}>Ask AI Assistant</button>
        <div className="suggestedQueries">
          <button className="suggestedQuery" type="button" onClick={(e) => { e.preventDefault(); submitAIQuery("My 8-week-old newborn won't sleep. Tips?") }}>My 8-week-old newborn won't sleep. Tips?</button>
          <button className="suggestedQuery" type="button" onClick={(e) => { e.preventDefault(); submitAIQuery("What are signs of postpartum depression?") }}>What are signs of postpartum depression?</button>
          <button className="suggestedQuery" type="button" onClick={(e) => { e.preventDefault(); submitAIQuery("When should I start baby proofing my home?") }}>When should I start baby proofing my home?</button>
        </div>
            </center>
            </div>   
            </div>
          )}
          {activeTab === "MyProfile" && (
            <div>
              <br />
              <h2>Edit Profile</h2>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%', paddingBottom: '10px' }}>
                <View as="form" onSubmit={handleUserSubmit}>
                  <Flex direction="column" gap="1rem" style={{}}>
                    <TextField
                      name="full_name"
                      placeholder="Full Name"
                      label="Full Name"
                      value={appUser.full_name}
                      onChange={handleChange}
                    />
                <div>
  <label>Parent</label>
  <div className="radio-group">
    <label className="radio-label">
      <input
        type="radio"
        name="your_gender"
        value="mom"
        checked={appUser.your_gender === 'mom'}
        onChange={handleChange}
      />
      <span className="checkmark"></span>
      Mom
    </label>
    <label className="radio-label">
      <input
        type="radio"
        name="your_gender"
        value="dad"
        checked={appUser.your_gender === 'dad'}
        onChange={handleChange}
      />
      <span className="checkmark"></span>
      Dad
    </label>
  </div>
</div>
                    <TextField
                      name="email_address"
                      placeholder="Email Address"
                      label="Email"
                      value={appUser.email_address}
                      onChange={handleChange}
                      readOnly
                    />
        <TextField
          name="phone_number"
          type="tel"
                  placeholder="+1 555-123-1234"
                  //label="Phone Number (Numbers Only e.g. 5551231234)"
                  label="Phone Number (include country code, e.g. +1 for US)"
          value={appUser.phone_number}
          onChange={handleChange}
        />
      <label>
          Communication Frequency:&nbsp;
          <select
            name="communication_frequency"
            value={appUser.communication_frequency}
            onChange={handleChange}
          >
            <option value="daily">Daily</option>
            <option value="semiweekly">Semi-weekly</option>
            <option value="weekly">Weekly</option>
          </select>
        </label>
        {/* Convert email_notification_status to a checkbox */}
        <label>
          Email Notifications:
          <input
            type="checkbox"
            checked={appUser.email_notification_status === 'true'}
            onChange={() => handleChange({ target: { name: 'email_notification_status', value: appUser.email_notification_status === 'true' ? 'false' : 'true' } })}
          />
        </label>

        {/* Convert phone_notification_status to a checkbox */}
        <label>
          Phone Notifications:
          <input
            type="checkbox"
            checked={appUser.phone_notification_status === 'true'}
            onChange={() => handleChange({ target: { name: 'phone_notification_status', value: appUser.phone_notification_status === 'true' ? 'false' : 'true' } })}
          />
        </label>
        <Button type="submit" className='primaryButton homepageButton'>Update Profile</Button>
      </Flex>
    </View>
    </div>
    <div>
            <h3>Expecting Children</h3>
            <TextField
              name="due_date"
              placeholder="Due Date"
              type="date"
              label="Due Date"
              value={dueDateValue}
              onChange={handleDueDateChange}
            />
            <br />
            <Button style={{width:'100%'}}className='primaryButton homepageButton' onClick={handleSaveDueDate}>Save Due Date</Button>
          </div>
    <div>
    <ChildrenInfo appUser={appUser} hasChildren={hasChildren} setHasChildren={setHasChildren} />

    </div>
    <div>
  <h2>Your Subscription</h2>
  <div id='subscriptionStatusWrapperParent'>
    {appUser.paid_subscription_status === 'true' ? (
      <div style={{ display: 'block', width: '100%' }}>
        ✅ Active Subscription - thank you!
        </div>
    ) : (
      <span>
        You do not have a valid subscription and will lose access soon unless you subscribe now.
        <span className='subscribeButtonModifier'>
          <form className='plan'   style={{ backgroundColor: 'transparent', boxShadow: 'none', padding: '0px', borderRadius: '0px' }} action="https://wq1tohfcpb.execute-api.us-east-2.amazonaws.com/staging/kintextsystempaymentsapi/create-checkout-session" method="POST">
            <button className='plan'  style={{marginBottom: '10px'}} type="submit">
              Subscribe Now
            </button>
          </form>
        </span>
      </span>
    )}
  </div>
  </div>
  </div>
  )}
     {activeTab === "MySubscription" && (
            
            <div>
  <br /> <center>           {appUser.paid_subscription_status === 'true' ? (
      <span>
        ✅ You have an active subscription - thank you!
      </span>
    ) : (<span></span>
    )}
    </center>
             <div className="subscription-plans">
  <div className="plan">
  
    <h2>&#x1f476;<br />Basic Plan</h2>
    <center>$20/month</center>
    <ul>
      <li>✔ Daily Updates</li>
      <li>✔ Expert Advice</li>
      <li>✖ No AI Assistant </li>
      <form className='plan'   style={{ backgroundColor: 'transparent', boxShadow: 'none', padding: '0px', borderRadius: '0px', width: '20%', minWidth: '100px' }} action="https://wq1tohfcpb.execute-api.us-east-2.amazonaws.com/staging/kintextsystempaymentsapi/create-checkout-session" method="POST">
            <button className='plan'  style={{marginBottom: '10px'}} type="submit">
              Subscribe to Basic
            </button>
          </form>
    </ul>
  </div>
  <div className="plan">
  <h2>&#x1f476; +  &#x1F916;<br />Premium Plan</h2>
  <center>$50/month</center>
    <ul>
      <li>✔ All Basic Features</li>
      <li>✔ 24/7 AI Assistant</li>
      <li>✔ Priority Email Support</li>
  
      <form className='plan'   style={{ backgroundColor: 'transparent', boxShadow: 'none', padding: '0px', borderRadius: '0px', width: '20%', minWidth: '100px' }} action="https://wq1tohfcpb.execute-api.us-east-2.amazonaws.com/staging/kintextsystempaymentsapi/create-checkout-session-premium" method="POST">
            <button className='plan'  style={{marginBottom: '10px'}} type="submit">
              Subscribe to Premium
            </button>
          </form>
    </ul>
  </div>
  <div className="plan">
  <h2>&#x1f476; +  &#x1F916; + &#127873;<br />Gift Plan</h2>
  <center>$95/year</center>
    <ul>
      <li>✔ All Premium Features</li>
      <li>✔ 1 Year Gift Subscription</li>
      <li>✔ Significant Discount</li>
      <button className='plan'><a style={{ textDecoration: 'none', color: 'white' }} href='/gift'>Gift a Subscription</a></button>
    </ul>
  </div>
</div>

            </div>
          )}
     {activeTab === "Referrals" && (
            <div>
              <p>Refer a Friend</p>
              <input
                style={{ padding: '8px' }}
                type="email"
                name="toEmailValue"
                placeholder="your-friend@gmail.com"
                value={toEmail}
                onChange={handleToEmailChange}
              />
              <br />
              <br />
              <Button className='homepageButton' style={{width: '100%'}} onClick={handleSendReferralEmail}>Send Referral</Button>
            
            </div>
          )}
      </div>
      </div>
      <br />
      <div>
      <center>
    <i>Feedback or questions? Email team@kintext.com and we'll reply immediately.</i>
     </center>
    </div>      
      </View>
    );
  };


//  export default withAuthenticator(App);
export default withAuthenticator(App, {
  initialAuthState: 'signup',
});
