import React, { useState, useEffect, useRef } from 'react';
import './main.css'; // Import the CSS file
//import logo from '../media/logo_black_transparent.png'
import { handlePhoneNumberSubmit, getAIResponse} from '../Functions';
import PublicNav from './PublicNav';

/*
// Import the new components for the footer content
import MorningSicknessTips from "./ArticleMorningSicknessTips.js";
import NutritionDuringPregnancy from "./ArticleNutritionDuringPregnancy.js";
import PreparingForLabor from "./ArticlePreparingForLabor.js";
import SupportingYourPartner from "./ArticleSupportingYourPartner.js";
import PreparingForFatherhood from "./ArticlePreparingForFatherhood.js";
import BondingWithYourBaby from "./ArticleBondingWithYourBaby.js";
*/

export default function Home() {

  const sendAIQuery = () => {
    const aiResponseContainer = document.getElementById('aiResponseContainer');
    const loader = aiResponseContainer.querySelector('.loader');
    const responseText = aiResponseContainer.querySelector('.response-text');
    const initialText = aiResponseContainer.querySelector('.initial-text');
  
    // Hide the initial text
    if (initialText) {
      initialText.style.display = 'none';
    }
  
    // Clear previous response text
    if (responseText) {
      responseText.innerHTML = '';
    } else {
      // Create response text element if it doesn't exist
      const newResponseText = document.createElement('div');
      newResponseText.classList.add('response-text');
      aiResponseContainer.appendChild(newResponseText);
    }
  
    // Show the loader
    loader.style.display = 'flex';

    const aiQuery = document.querySelector('input[name="aiQueryInput"]').value;

    getAIResponse(aiQuery)
      .then(data => {
        //console.log("Query success!", data);
        // Hide the loader
        loader.style.display = 'none';
        // Display response in the response text element
        responseText.innerHTML = data.response;
      })
      .catch(error => {
        //console.error("Failed query", error);
        // Hide the loader
        loader.style.display = 'none';
        // Display error message in the response text element
        responseText.innerHTML = 'Error occurred. Please try again.';
      });
  };

  let openFaqItem = null;

  const toggleAnswer = (event) => {
    const questionWrapper = event.currentTarget;
    const faqItem = questionWrapper.closest('.faq-item');
  
    // Close the previously open FAQ item, if any
    if (openFaqItem && openFaqItem !== faqItem) {
      openFaqItem.classList.remove('active');
    }
  
    // Toggle the current FAQ item
    faqItem.classList.toggle('active');
    openFaqItem = faqItem.classList.contains('active') ? faqItem : null;
  };
  
  const faqItems = document.querySelectorAll('.faq-item');
  
  faqItems.forEach(item => {
    const questionWrapper = item.querySelector('.faq-question-wrapper');
    questionWrapper.addEventListener('click', toggleAnswer);
  });

  function handleGettingStartedFormSubmit(event) {
    event.preventDefault(); // Prevent the default form submission behavior
  
    const phoneNumber = document.getElementById('phoneNumber').value;
  
    // Call your handlePhoneNumberSubmit function here
    handlePhoneNumberSubmit(phoneNumber);
  
    // Redirect to the dashboard page with the phone number as a query parameter
    //window.location.href = `/dashboard?phoneNumber=${encodeURIComponent(phoneNumber)}`;
  }

  const texts = [
    "Week 6: Congratulations. Brain and nervous system are developing quickly. Your baby's heart will begin to beat around now.",
    "Week 8: Fingers and toes are just beginning to form this week.",
    "Week 10: Baby's vital organs have been formed and are starting to work together. Tooth buds form inside the mouth. First prenatal visit around this time.",
    "Week 14: By this week, some fine hairs have developed on your baby's face. You may consider amniocentesis or chorionic villus sampling (CVS) to screen for genetic conditions.",
    "Week 20: Halfway there! Baby's growth will put pressure on your lungs, stomach, bladder, and kidneys.",
    "Week 26: Baby's eyes are beginning to open. Baby's lungs are developing and will continue to do so until birth. You may experience Braxton Hicks contractions.",
    "Week 30: The folds and grooves of your baby's brain are still developing and expanding, and your baby's adding layers of fat.",
    "Week 30: To prepare for breathing after birth, your baby will mimic breathing movements by repeatedly moving the diaphragm.",
    "Week 32: Eyelashes, eyebrows, and the hair on your baby's head are evident.",
    "Week 34: The vernix, the waxy, greasy coating that protects your baby's skin in the womb, starts to get thicker. Most babies move into a head-down position to get ready for labor and birth. ",    
  ];
  
  
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const typingSpeed = 50;
    const fadeOutSpeed = 1500;
    const textCompleteDelay = 50; // Additional delay after typing before starting fade-out
    const typeOutTimeouts = useRef([]); // Ref to store ongoing typing timeouts
  
    useEffect(() => {
      const textTarget = document.getElementById('textTarget');
      const typingEffectContainer = document.getElementById('typingFadeOutEffect');
  
      const clearTypeOutTimeouts = () => {
        typeOutTimeouts.current.forEach(clearTimeout); // Clear any ongoing typing timeouts
        typeOutTimeouts.current = []; // Reset the ref
      };
  
      const typeOutText = (text, onComplete) => {
        clearTypeOutTimeouts();
        let index = 0;
        const type = () => {
          if (index < text.length) {
            textTarget.innerHTML += text.charAt(index);
            index++;
            const timeoutId = setTimeout(type, typingSpeed);
            typeOutTimeouts.current.push(timeoutId);
          } else {
            setTimeout(onComplete, textCompleteDelay); // Wait a bit more before signaling completion
          }
        };
        type();
      };
  
      const startNextText = () => {
        textTarget.innerHTML = '';
        typingEffectContainer.classList.remove('fadeOut');
  
        const currentText = texts[currentTextIndex];
        typeOutText(currentText, () => {
          typingEffectContainer.classList.add('fadeOut');
  
          setTimeout(() => {
            setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
          }, fadeOutSpeed);
        });
      };
  
      startNextText();
      const intervalDuration = texts[currentTextIndex].length * typingSpeed + fadeOutSpeed + textCompleteDelay;
      const intervalId = setInterval(startNextText, intervalDuration);
  
      return () => {
        clearInterval(intervalId);
        clearTypeOutTimeouts();
      };
    }, [currentTextIndex, texts]);
  
    const submitAIQuery = (query) => {
      const aiQueryInput = document.querySelector('input[name="aiQueryInput"]');
      aiQueryInput.value = query;
      sendAIQuery();
    };

  return (
    <div className='pageContainerHomeParent'>
    <div className='pageContainerHome'>
  <div className="contentWrapper">
 <PublicNav />
      <div className="heroSection">
        <center>
        <b><h1 className="tagline">Be The Best Parent</h1></b>
        <h3 className="tagline_sub">Helpful Daily Texts and An AI Assistant for Parenting for just $95/year</h3>
        <div id="typingFadeOutEffect" className="typingEffect">
    <span id="textTarget"></span>
      </div>
      <br />
      <form id="phoneNumberForm" onSubmit={handleGettingStartedFormSubmit}><label htmlFor="phoneNumber"></label>
      <input type="tel" id="phoneNumber" name="phoneNumber" placeholder="+1 415-555-1234" required pattern="^\+?\d{1,3}[-.\s]?\(?\d{1,3}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$" title="Please enter a valid phone number in the international format: +1 123-456-7890, +1 (123) 456-7890, +1 123.456.7890, or +1 1234567890" />
    <span style={{'color':'grey', 'fontSize':'0.2em'}}>By signing up, you agree to our <a target='_blank' style={{ 'textDecoration': 'none'}} href='/terms'>terms of service</a> and <a target='_blank' style={{ 'textDecoration': 'none'}} href='/privacy'>privacy policy.</a></span>
    <button className="primaryButton homepageButton" type="submit">Get Started</button>
    </form>
        <span className="loginLink"><a href='/dashboard'>or login if you have an account.</a></span>
        </center>
      </div>
    </div>
    </div>
    <br />
    <center>
      <h1>Try Our AI Assistant</h1>
      <div>
            <center>
            <div id='aiResponseContainer'>
  <i className="initial-text">Your answer will appear here...</i>
  <div className="loader">
    <div className="dot"></div>
    <div className="dot"></div>
    <div className="dot"></div>
  </div>
  <div className="response-text"></div>
            </div>
            <input type='text' id='aiQueryInput' name='aiQueryInput' placeholder='Ask me anything' />
  <button className='aibutton' onClick={sendAIQuery}>Ask AI Assistant</button>
  <div className="suggestedQueries">
        <button className="suggestedQuery" type="button" onClick={(e) => { e.preventDefault(); submitAIQuery("My 8-week-old newborn won't sleep. Tips?")}}>My 8-week-old newborn won't sleep. Tips?</button>
        <button className="suggestedQuery" type="button"  onClick={(e) => { e.preventDefault(); submitAIQuery("What are signs of postpartum depression?")}}>What are signs of postpartum depression?</button>
        <button className="suggestedQuery" type="button" onClick={(e) => { e.preventDefault(); submitAIQuery("When should I start baby proofing my home?")}}>When should I start baby proofing my home?</button>
      </div>
            </center>
            </div>
    <h1>Let AI Make You The Best New Parent Humanly Possible</h1>    
    </center>
    <section className="value-section" id="features">
<div className="value-row">
  <div className="value-content">
  <h2 className="value-headline">&#x1F916; AI-Powered Advice</h2>
    <p className="value-text">
    Our system uses AI to provide you with the best advice for your baby's development. We use the latest research and data to provide you with the most accurate information possible. Our system is constantly learning and improving to provide you with the best advice possible (this is not medical or professional advice, just general information for expecting parents).
    </p>
    <button className='plan'><a style={{ textDecoration: 'none', color: 'black' }} href='/dashboard'>Sign Up Now</a></button>
  </div>
  <div className="value-image-container">
    <img src="phone1c.png" alt="Kintext Demo 1" />
  </div>
</div>

<div className="value-row reverse">
  <div className="value-content">
    <h2 className="value-headline">&#x1f46a; Automated Daily Texts</h2>
    <p className="value-text">
    Whether you are an anxious mom who is 10 weeks pregnant or a clueless dad with a wife who is 30 weeks pregnant, we provide you with the information you need to know about your baby's development. You get these instantly every morning at 9am PST, so you could make decisions and be the best parent possible.
    </p>
    <button className='plan'><a style={{ textDecoration: 'none', color: 'black' }} href='/dashboard'>Sign Up Now</a></button>
  </div>
  <div className="value-image-container">
  <img src="phone2b.png" alt="Kintext Demo 2" />
  </div>
</div>


<div className="value-row">
  <div className="value-content">
    <h2 className="value-headline">&#x1F381; Makes for a Great Gift</h2>
    <p className="value-text">
    There's enough STUFF out there for new parents. Give the gift of knowledge and peace of mind. Our system is perfect for expecting parents who want to know more about their baby's development. It's a gift that keeps on giving.
    </p>
    <button className='plan'><a style={{ textDecoration: 'none', color: 'black' }} href='/gift'>Gift a Subscription</a></button>
  </div>
  <div className="value-image-container">
  <img src="phone3c.png" alt="Kintext Demo 3" />
  </div>
</div>
</section>    


<div className="testimonial-section">
  <h1>Testimonials</h1>
  <div className="testimonials">
    <div className="testimonial">
      <p>"Kintext has been an invaluable resource during my pregnancy journey. The weekly updates kept me informed and prepared for every step."</p>
      <p className="testimonial-author">- Emily, New Mom</p>
    </div>
    <div className="testimonial">
      <p>"I appreciate the tips for a clueless new dad like me. It helped me stay calm and focused, so I could be helpful throughout my wife's pregnancy."</p>
      <p className="testimonial-author">- Ronald, Expecting Father</p>
    </div>
    <div className="testimonial">
      <p>"Kintext's text messages and email updates were like having a knowledgeable friend by my side, guiding me through the ups and downs of pregnancy."</p>
      <p className="testimonial-author">- Sarah, New Mom</p>
    </div>
  </div>
</div>



<center>
<h1>Parent-First Pricing</h1>
</center>
<div
  className="subscription-plans"
  style={{
    width: "80%",
    
    /*display: "flex",
    justifyContent: "space-around",
    */
    margin: "auto",
    padding: "20px",
    gap: "20px" /* Adds gap between boxes */,
    alignItems: "center"
  }}
>
  <div className="plan">
  
    <h2>&#x1f476;<br />Basic Plan</h2>
    <center>$20/month</center>
    <ul>
      <li>✔ Basic features</li>
      <li>✔ 9 months of support</li>
      <li>✔ Text and email updates</li>
      <button className='plan'><a style={{ textDecoration: 'none', color: 'white' }} href='/dashboard'>Signup Now</a></button>
    </ul>
  </div>
  <div className="plan">
  <h2>&#x1f476; +  &#x1F916;<br />Premium AI Plan</h2>
  <center>$50/month</center>
    <ul>
      <li>✔ All Basic features</li>
      <li>✔ AI Agent support</li>
      <li>✔ Priority email support</li>
      <button className='plan'><a style={{ textDecoration: 'none', color: 'white' }} href='/dashboard'>Signup Now</a></button>
    </ul>
  </div>
  <div className="plan">
  <h2>&#x1f476; +  &#x1F916; + &#127873;<br />Gift Plan</h2>
  <center>$95/year</center>
    <ul>
      <li>✔ All Premium Features</li>
      <li>✔ 1 Year Gift Subscription</li>
      <li>✔ Significant discount</li>
      <button className='plan'><a style={{ textDecoration: 'none', color: 'white' }} href='/gift'>Gift a Subscription</a></button>
    </ul>
  </div>
</div>

<div className="trimester-section" id="learn">
  <h2>The Pregancy Journey</h2>
  <div className="trimester-boxes">
    <div className="trimester-box">
      <h3>First Trimester</h3>
      <p>0-12 weeks</p>
      <ul>
        <li>Morning sickness is common in the first trimester.</li>
        <li>Your baby's major organs and systems begin to develop.</li>
        <li>Stay hydrated and eat small, frequent meals.</li>
      </ul>
    </div>
    <div className="trimester-box">
      <h3>Second Trimester</h3>
      <p>13-26 weeks</p>
      <ul>
        <li>You may start feeling your baby's movements.</li>
        <li>Your energy levels may increase.</li>
        <li>Prepare for your 20-week anatomy scan.</li>
      </ul>
    </div>
    <div className="trimester-box">
      <h3>Third Trimester</h3>
      <p>27-40 weeks</p>
      <ul>
        <li>Your baby's brain and lungs continue to develop.</li>
        <li>Prepare for labor and delivery.</li>
        <li>Rest as much as possible to conserve energy.</li>
      </ul>
    </div>
  </div>
  <div className="life-stages-section" id="learn">
  <h2>Stages of Child Development</h2>
  <div className="life-stages-boxes">
    <div className="life-stage-box">
      <h3>Early Childhood</h3>
      <p>0-5 years</p>
      <p>Rapid physical, cognitive, and social-emotional development.</p>
      <p>Learning language, motor skills, and building attachments.</p>
      <p>Encourage exploration, play, and nurturing routines.</p>
    </div>
    <div className="life-stage-box">
      <h3>Middle Childhood</h3>
      <p>6-11 years</p>
      <p>Developing independence, self-control, and social skills.</p>
      <p>Building academic foundations and peer relationships.</p>
      <p>Foster interests, encourage responsibility, and provide structure.</p>
    </div>
    <div className="life-stage-box">
      <h3>Adolescence</h3>
      <p>12-18 years</p>
      <p>Navigating physical and emotional changes of puberty.</p>
      <p>Establishing identity, independence, and decision-making skills.</p>
      <p>Support self-discovery, open communication, and healthy habits.</p>
    </div>
  </div>
</div>

<style>{`
  .life-stages-section {
    padding: 2rem;
    background-color: #f5f5f5;
  }

  .life-stages-boxes {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
  }

  .life-stage-box {
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 1.5rem;
    flex: 1 1 300px;
    max-width: 400px;
  }

  .life-stage-box h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .life-stage-box p {
    margin-bottom: 0.5rem;
  }
`}</style>
  <br />
  Let us help you on your journey. <br /><a href='/dashboard'>Sign up</a> today!
  <br />
</div>
<div className="faq-container">
  <h2 className="faq-heading">Frequently Asked Questions</h2>
  <div className="faq-list">
    <div className="faq-item">
      <div className="faq-question-wrapper" onClick={toggleAnswer}>
        <h3 className="faq-question">What is Kintext?</h3>
        <span className="faq-toggle">+</span>
      </div>
      <div className="faq-answer">
        <p>Kintext is a comprehensive AI platform designed to support expecting, new, and experienced parents throughout their pregnancy and parenthood journey. It provides daily text updates, an AI assistant, and valuable resources to help you navigate this exciting but challenging time.</p>
      </div>
    </div>
    <div className="faq-item">
      <div className="faq-question-wrapper" onClick={toggleAnswer}>
        <h3 className="faq-question">How does Kintext work?</h3>
        <span className="faq-toggle">+</span>
      </div>
      <div className="faq-answer">
        <p>Kintext delivers personalized daily text messages and email updates tailored to your specific pregnancy stage or parenting phase. These updates provide information about your baby's development, tips for a healthy pregnancy, and advice to prepare you for parenthood. Additionally, you can access our AI assistant to get answers to any questions or concerns you may have.</p>
      </div>
    </div>
    <div className="faq-item">
      <div className="faq-question-wrapper" onClick={toggleAnswer}>
        <h3 className="faq-question">What are the pricing options for Kintext?</h3>
        <span className="faq-toggle">+</span>
      </div>
      <div className="faq-answer">
        <p>Kintext offers three pricing plans to suit your needs: Basic Plan ($20/month), Premium AI Plan ($50/month), and Gift Plan ($95/year). The Basic Plan provides daily text and email updates, while the Premium AI Plan and Gift Plan also include access to our AI assistant for personalized support.</p>
      </div>
    </div>
    <div className="faq-item">
      <div className="faq-question-wrapper" onClick={toggleAnswer}>
        <h3 className="faq-question">What are the main use cases for Kintext?</h3>
        <span className="faq-toggle">+</span>
      </div>
      <div className="faq-answer">
        <p>Kintext is designed to be your go-to resource throughout your entire parenthood journey, from pregnancy to raising children of any age. It provides valuable information, tips, and support for various aspects, including morning sickness management, nutrition guidance, labor preparation, bonding with your baby, navigating the challenges of new parenthood, and supporting your child's growth and development.</p>
      </div>
    </div>
    <div className="faq-item">
      <div className="faq-question-wrapper" onClick={toggleAnswer}>
        <h3 className="faq-question">Is the AI assistant available for all plans?</h3>
        <span className="faq-toggle">+</span>
      </div>
      <div className="faq-answer">
        <p>The AI assistant is only available with the Premium AI Plan and the Gift Plan. With these plans, you can access our AI assistant and receive personalized responses to any questions you may have related to pregnancy, childbirth, parenting, and child development.</p>
      </div>
    </div>
    <div className="faq-item">
      <div className="faq-question-wrapper" onClick={toggleAnswer}>
        <h3 className="faq-question">Can I gift a Kintext subscription?</h3>
        <span className="faq-toggle">+</span>
      </div>
      <div className="faq-answer">
        <p>Absolutely! Kintext offers a Gift Plan that allows you to purchase a one-year subscription as a gift for expecting or new parents. This plan includes all the Premium AI features at a discounted price, making it an excellent gift choice for those you care about.</p>
      </div>
    </div>
    <div className="faq-item">
      <div className="faq-question-wrapper" onClick={toggleAnswer}>
        <h3 className="faq-question">Is the information provided medically reviewed? (NO!)</h3>
        <span className="faq-toggle">+</span>
      </div>
      <div className="faq-answer">
        <p>While Kintext provides valuable information and resources based on the latest research and data, the content is not intended to replace medical advice from healthcare professionals. We recommend consulting with your doctor, pediatrician, or healthcare provider for any specific medical concerns or questions.</p>
      </div>
    </div>
    <div className="faq-item">
      <div className="faq-question-wrapper" onClick={toggleAnswer}>
        <h3 className="faq-question">Can I customize the daily text messages?</h3>
        <span className="faq-toggle">+</span>
      </div>
      <div className="faq-answer">
        <p>Currently, the daily text messages are pre-designed based on your pregnancy stage or parenting phase, and you cannot customize the content. However, we value your feedback and are constantly working to improve our service. If you have any specific requests or suggestions, please feel free to reach out to our team.</p>
      </div>
    </div>
    <div className="faq-item">
      <div className="faq-question-wrapper" onClick={toggleAnswer}>
        <h3 className="faq-question">Does Kintext offer resources for postpartum and parenting support?</h3>
        <span className="faq-toggle">+</span>
      </div>
      <div className="faq-answer">
        <p>Yes, Kintext provides comprehensive resources and information related to postpartum care, as well as ongoing support for various parenting stages. This includes tips for adjusting to life with a newborn, recognizing signs of postpartum depression, promoting bonding with your baby, and overall guidance on raising happy and healthy children.</p>
      </div>
    </div>
    <div className="faq-item">
      <div className="faq-question-wrapper" onClick={toggleAnswer}>
        <h3 className="faq-question">Can I cancel my Kintext subscription?</h3>
        <span className="faq-toggle">+</span>
      </div>
      <div className="faq-answer">
        <p>Yes, you can cancel your Kintext subscription at any time. Simply reach out to our support team, and they will assist you with the cancellation process. We value our customers and aim to provide a seamless experience, even during cancellations.</p>
      </div>
    </div>
  </div>
</div>
<br />

<style>
  {`
  .faq-container {
    max-width: 800px;
    margin: 0 auto;
    font-family: 'Montserrat', sans-serif;
  }
  
  .faq-heading {
    text-align: center;
    margin-bottom: 40px;
    color: #333;
    font-size: 2rem;
    font-weight: 700;
  }
  
  .faq-list {
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .faq-item {
    border-bottom: 1px solid #ddd;
    transition: background-color 0.3s ease;
  }
  
  .faq-item:last-child {
    border-bottom: none;
  }
  
  .faq-question-wrapper {
    background-color: #fff;
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .faq-question-wrapper:hover {
    background-color: #f9f9f9;
  }
  
  .faq-question {
    font-size: 1.2rem;
    font-weight: 600;
    color: #333;
  }
  
  .faq-toggle {
    font-size: 2rem;
    font-weight: bold;
    color: #666;
    transition: transform 0.3s ease;
  }
  
  .faq-item.active .faq-toggle {
    transform: rotate(45deg);
  }
  
  .faq-answer {
    padding: 20px 30px;
    line-height: 1.8;
    background-color: #f9f9f9;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
  }
  
  .faq-item.active .faq-answer {
    max-height: 500px; /* Adjust this value based on your content */
  }
  `}
</style>


<div className="content-footer">
  <div className="footer-columns">
    <div className="footer-column">
      <h3>Advice for Expecting Moms</h3>
      <ul>
        <li><a href="/morning-sickness-tips">Morning Sickness Tips</a></li>
        <li><a href="/nutrition-during-pregnancy">Nutrition During Pregnancy</a></li>
        <li><a href="/preparing-for-labor">Preparing for Labor</a></li>
        <li><a href="https://learn.kintext.com/importance-of-self-care-for-new-parents">The Importance of Self-Care for New Parents</a></li>
      <li><a href="https://learn.kintext.com/daily-pregnancy-tips-for-moms">Daily Pregnancy Tips for Moms</a></li>
      </ul>
    </div>
    <div className="footer-column">
      <h3>Advice for Expecting Dads</h3>
      <ul>
        <li><a href="/supporting-your-partner">Supporting Your Partner</a></li>
        <li><a href="/preparing-for-fatherhood">Preparing for Fatherhood</a></li>
        <li><a href="/bonding-with-your-baby">Bonding with Your Baby</a></li>
        <li><a href="https://learn.kintext.com/best-books-for-dads-during-pregnancy">Best Books for Dads During Pregnancy</a></li>
      <li><a href="https://learn.kintext.com/daily-pregnancy-tips-for-dads">Daily Pregnancy Tips for Dads</a></li>        
      </ul>
    </div>
    <div className="footer-column">
      <h3>Favorite Partner Resources</h3>
      <ul>
        <li><a href="https://whattoexpect.com">What To Expect</a></li>
        <li><a href="https://mrdad.com">Mr. Dad</a></li>
        <li><a href="https://fathercraft.com">Fathercraft</a></li>
        <li><a href="https://www.todaysparent.com/baby/newborn-care/a-rookie-dads-guide-to-newborns/">Today's Parent: Dad Guide</a></li>
        <li><a href="https://www.reddit.com/r/daddit/">Reddit Dad Community</a></li>
      </ul>
    </div>

  </div>
</div>

<div className="footer">
<span style={{'color':'grey', 'fontSize':'0.8em'}}>
  <center>
  <a className='footerLinks' href='/'>Home </a> |<a className='footerLinks' href='/terms'> Terms of Service</a> | <a className='footerLinks' href='/privacy'>Privacy Policy</a> | <a className='footerLinks' href='https://learn.kintext.com/'>Resources</a> |  <a className='footerLinks' href='mailto:team@kintext.com'>Contact Us</a>
  </center>
  </span>
  </div>
    </div>    
  )
}
