import React from 'react'

export default function CancellationError() {
    localStorage.setItem("stripeCustomer", "true");
    localStorage.setItem("stripeCustomerCancellationError", "true");

    setTimeout(() => {
        window.location.href = '/dashboard';
      }, 2000);    
  
    return (
    <div>Cancellation Error</div>
  )
}
