/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getRecentMessage = /* GraphQL */ `
  query GetRecentMessage($id: ID!) {
    getRecentMessage(id: $id) {
      id
      user_id
      user_email
      user_phone_number
      conversation_id
      conversation_history
      createdAt
      updatedAt
    }
  }
`;
export const listRecentMessages = /* GraphQL */ `
  query ListRecentMessages(
    $filter: ModelRecentMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecentMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        user_email
        user_phone_number
        conversation_id
        conversation_history
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAffiliates = /* GraphQL */ `
  query GetAffiliates($id: ID!) {
    getAffiliates(id: $id) {
      id
      email_address
      affiliate_value
      createdAt
      updatedAt
    }
  }
`;
export const listAffiliates = /* GraphQL */ `
  query ListAffiliates(
    $filter: ModelAffiliatesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAffiliates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email_address
        affiliate_value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAppUserChildren = /* GraphQL */ `
  query GetAppUserChildren($id: ID!) {
    getAppUserChildren(id: $id) {
      id
      appuserid
      appuseremail
      gender
      birthday
      name
      createdAt
      updatedAt
    }
  }
`;
export const listAppUserChildren = /* GraphQL */ `
  query ListAppUserChildren(
    $filter: ModelAppUserChildrenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppUserChildren(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        appuserid
        appuseremail
        gender
        birthday
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGiftUser = /* GraphQL */ `
  query GetGiftUser($id: ID!) {
    getGiftUser(id: $id) {
      id
      sender_email
      sender_phone_number
      recipient_email
      recipient_phone_number
      subscription_type
      gift_timestamp
      createdAt
      updatedAt
    }
  }
`;
export const listGiftUsers = /* GraphQL */ `
  query ListGiftUsers(
    $filter: ModelGiftUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGiftUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sender_email
        sender_phone_number
        recipient_email
        recipient_phone_number
        subscription_type
        gift_timestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAppUser = /* GraphQL */ `
  query GetAppUser($id: ID!) {
    getAppUser(id: $id) {
      id
      email_address
      phone_number
      due_date
      days_pregnant_value
      days_pregnant_submitted_time
      communication_frequency
      paid_subscription_type
      paid_subscription_status
      paid_subscription_last_modified
      email_notification_status
      phone_notification_status
      gift_subscription_status
      gift_subscription_referred
      full_name
      additional_info
      your_gender
      expecting_boolean
      createdAt
      updatedAt
    }
  }
`;
export const listAppUsers = /* GraphQL */ `
  query ListAppUsers(
    $filter: ModelAppUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email_address
        phone_number
        due_date
        days_pregnant_value
        days_pregnant_submitted_time
        communication_frequency
        paid_subscription_type
        paid_subscription_status
        paid_subscription_last_modified
        email_notification_status
        phone_notification_status
        gift_subscription_status
        gift_subscription_referred
        full_name
        additional_info
        your_gender
        expecting_boolean
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUsers = /* GraphQL */ `
  query GetUsers($id: ID!) {
    getUsers(id: $id) {
      id
      email_address
      phone_number
      due_date
      days_pregnant_value
      days_pregnant_submitted_time
      communication_frequency
      paid_subscription_type
      paid_subscription_status
      paid_subscription_last_modified
      email_notification_status
      phone_notification_status
      gift_subscription_status
      gift_subscription_referred
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email_address
        phone_number
        due_date
        days_pregnant_value
        days_pregnant_submitted_time
        communication_frequency
        paid_subscription_type
        paid_subscription_status
        paid_subscription_last_modified
        email_notification_status
        phone_notification_status
        gift_subscription_status
        gift_subscription_referred
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNote = /* GraphQL */ `
  query GetNote($id: ID!) {
    getNote(id: $id) {
      id
      name
      description
      image
      createdAt
      updatedAt
    }
  }
`;
export const listNotes = /* GraphQL */ `
  query ListNotes(
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        image
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
