import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import PublicNav from './PublicNav';

const fadeInDown = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
`;

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
`;

const Homepage = styled.div`
  /* Add any global styles for the homepage */
`;

const Header = styled.header`
  /* Add any styles for the header */
`;

const PageContainerDefault = styled.div`
  /* Add any styles for the page container */
  margin-top: -40px; // Adjust the negative margin as needed
`;

//background: linear-gradient(to bottom right, #FFE4E1, #FFF0F5, #E6E6FA, #F0FFF0);
//background: linear-gradient(to top left, #FFC0CB, #FFB6C1, #87CEFA, #98FB98, #FFFACD);
//background: linear-gradient(45deg, #FFDAB9, #FFE4B5, #FFEFD5, #FFF8DC);

const Hero = styled.section`
background: linear-gradient(to bottom right, #87CEFA, #E0FFFF, #FFFACD, #FFE4E1);
  padding: 20px 0;
  text-align: center;
`;

const HeroContent = styled.div`
  max-width: 800px;
  margin: 0 auto;

  h2 {
    font-size: 48px;
    color: #333;
    margin-bottom: -10px;
    margin-top: 0px;
    animation: ${fadeInDown} 1s;
  }

  p {
    font-size: 24px;
    color: #666;
    margin-bottom: 40px;
    animation: ${fadeInUp} 1s;
  }
`;

const PlanForm = styled.form`
  background-color: transparent;
  box-shadow: none;
  padding: 0px;
  border-radius: 0px;
  animation: ${fadeInUp} 1s;

  input {
    width: 100%;
    padding: 15px;
    margin-bottom: 20px;
    font-size: 18px;
    border: 2px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s ease;

    &:focus {
      outline: none;
      border-color: #6495ED;
    }
  }

  button {
    background-color: #6495ED;
    color: #FFF;
    font-size: 20px;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 10px;

    &:hover {
      background-color: #4169E1;
    }
  }
`;

const Footer = styled.footer`
  /* Add any styles for the footer */
`;

export default function Gift() {
  const [senderEmail, setSenderEmail] = useState('');
  const [recipientEmail, setRecipientEmail] = useState('');

  return (
    <Homepage>
      <Header>
       <PublicNav />
      </Header>
      <PageContainerDefault>
      <main>
          <Hero>
            <HeroContent>
              <h2>Give the Gift of Knowledge to Expecting and New Moms and Dads</h2>
            <p>
                Kintext is AI for parenthood. We send helpful daily text messages to expecting and new parents to help them through their pregnancy and the beginning of parenthood. It's like having a personal assistant in your pocket, available 24/7 to answer your questions and provide support.
        <br /><br />
                Help your loved ones be great parents and partners to each other for just $95.
            </p>
            <center>
                <PlanForm
                  action="https://8e0pkbyzs2.execute-api.us-east-2.amazonaws.com/main/kintextsystempaymentsapi/create-checkout-session-gift"
                  method="POST"
                >
            <input
  type="email"
                    placeholder="Your Email"
  value={senderEmail}
  onChange={(e) => {
    setSenderEmail(e.target.value);
    localStorage.setItem('senderEmail', e.target.value);
  }}
                  />
<input
  type="email"
  placeholder="Recipient Email"
  value={recipientEmail}
  onChange={(e) => {
    setRecipientEmail(e.target.value);
    localStorage.setItem('recipientEmail', e.target.value);
  }}
/>
                  <button type="submit">Give the Gift Now</button>
                </PlanForm>
          </center>
            </HeroContent>
          </Hero>
      </main>
        <div className="footer">
<span style={{'color':'grey', 'fontSize':'0.8em'}}>
  <center>
  <a className='footerLinks' href='/'>Home </a> |<a className='footerLinks' href='/terms'> Terms of Service</a> | <a className='footerLinks' href='/privacy'>Privacy Policy</a> | <a className='footerLinks' href='https://learn.kintext.com/'>Resources</a> |  <a className='footerLinks' href='mailto:team@kintext.com'>Contact Us</a>
  </center>
  </span>
    </div>
      </PageContainerDefault>
    </Homepage>
  );
}