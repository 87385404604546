import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import PublicNav from './../PublicNav';
import ThomasMcMinnImage from './ThomasMcMinn.png'; // Import the image
import DadhoodLogo from './dadhood-logo.png'; // Import the image


const fadeInDown = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
`;

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
`;

const Homepage = styled.div`
  /* Add any global styles for the homepage */
`;

const Header = styled.header`
  /* Add any styles for the header */
`;

const PageContainerDefault = styled.div`
  /* Add any styles for the page container */
  margin-top: -40px; // Adjust the negative margin as needed
`;

//background: linear-gradient(to bottom right, #FFE4E1, #FFF0F5, #E6E6FA, #F0FFF0);
//background: linear-gradient(to top left, #FFC0CB, #FFB6C1, #87CEFA, #98FB98, #FFFACD);
//background: linear-gradient(45deg, #FFDAB9, #FFE4B5, #FFEFD5, #FFF8DC);

const Hero = styled.section`
background: linear-gradient(to bottom right, #87CEFA, #E0FFFF, #FFFACD, #FFE4E1);
  padding: 20px 0;
  text-align: center;
`;

const HeroContent = styled.div`
  max-width: 800px;
  margin: 0 auto;

  h2 {
    font-size: 48px;
    color: #333;
    margin-bottom: -10px;
    margin-top: 0px;
    animation: ${fadeInDown} 1s;
  }

  p {
    font-size: 24px;
    color: #666;
    margin-bottom: 40px;
    animation: ${fadeInUp} 1s;
  }
`;

const Footer = styled.footer`
  /* Add any styles for the footer */
`;

const SignUpLink = styled.a`
  background-color: #6495ed;
  color: #fff;
  font-size: 20px;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
  text-decoration: none;
  display: inline-block;

  &:hover {
    background-color: #4169e1;
  }
`;
const ConnectWithLinks = styled.p`
  a {
    color: #6495ED;
    text-decoration: none;
    margin: 0 10px;
    transition: color 0.3s ease;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: #4169E1;
    }
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 20px;

  img {
    max-width: 100%;
    height: auto;
  }
`;

export default function Dadhood() {

    useEffect(() => {
        localStorage.setItem('partnerLastTouch', 'dadhood');
      }, []);

  return (
    <Homepage>
      <Header>
        <PublicNav />
      </Header>
      <PageContainerDefault>
        <main>
          <Hero>
            <HeroContent>
            <br />
              <h2>Featured Partner: Dadhood</h2>
              <br />
              <img src={DadhoodLogo} alt="Dadhood Logo" />
              <div>
              <h3>Connect with Dadhood</h3>
                <ConnectWithLinks>
                  <a href="https://www.dadhood.co/blog" target="_blank" rel="noopener noreferrer">Blog</a> |
                  <a href="https://www.iheart.com/podcast/269-dadhood-113927902/?keyid=DADHOOD&pname=podcast_profile&sc=widget_share" target="_blank" rel="noopener noreferrer">Podcast</a> |
                  <a href="https://www.dadhood.co/shop" target="_blank" rel="noopener noreferrer">Shop</a> |
                  <a href="https://www.instagram.com/dadhood.co/" target="_blank" rel="noopener noreferrer">Instagram</a> |
                  <a href="https://www.tiktok.com/@dadhood.co" target="_blank" rel="noopener noreferrer">TikTok</a> |
                  <a href="https://www.youtube.com/channel/UChks_Gsm-AA6ivQU13y-S9Q" target="_blank" rel="noopener noreferrer">YouTube</a>
                </ConnectWithLinks>
              </div>
              <div>
                Good news - Dadhood and Kintext have partnered to provide you with the best parenting resources for new dads. 
                
                <br /><br />Thomas McMinn started DADHOOD so he could connect with other dads in a meaningful way. He wanted to hear their stories and advice. For him, as he tells it: becoming a dad didn't feel the way he expected. He felt uncertain and lost. He didn't have a village. He felt alone. After the birth of his second son he realized there had to be more men out there like him; men that were excited to become a father and who loved their kids so much, but also felt alone. And thus Dadhood was born.
                <br /><br />The Dadhood-Kintext partnership helps bring even more of those support resources to the global Dadhood community. With this partnership, Dadhood community members now have access to:
                <ul>
                - Daily Texts with Specific Parenting Advice <br />
                - AI-Powered Instant Q&A Service<br />
                - Personalized Parenting Recommendations<br />
                </ul>            
              </div>
              <SignUpLink href="/dashboard?partner=dadhood">Sign Up for Kintext via Dadhood Now</SignUpLink>
            </HeroContent>
          </Hero>
          <ImageContainer>
                <img src={ThomasMcMinnImage} alt="Thomas McMinn" />
              </ImageContainer>
        </main>
        <div className="footer">
<span style={{'color':'grey', 'fontSize':'0.8em'}}>
  <center>
  <a className='footerLinks' href='/'>Home </a> |<a className='footerLinks' href='/terms'> Terms of Service</a> | <a className='footerLinks' href='/privacy'>Privacy Policy</a> | <a className='footerLinks' href='https://learn.kintext.com/'>Resources</a> |  <a className='footerLinks' href='mailto:team@kintext.com'>Contact Us</a>
  </center>
  </span>
  </div>
      </PageContainerDefault>
    </Homepage>
  );
}