import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from "./pages/Home.js";
import Terms from "./pages/Terms.js";
import Privacy from "./pages/Privacy.js";
import PaymentSuccess from  "./pages/PaymentSuccess.js";
import PaymentCancellation from  "./pages/PaymentCancellation.js";
import GiftPaymentSuccess from  "./pages/GiftPaymentSuccess.js";
import GiftPaymentCancellation from  "./pages/GiftPaymentCancellation.js";
import CancellationSuccess from "./pages/CancellationSuccess.js";
import CancellationError from "./pages/CancellationError.js";
import Subscribe from "./pages/Subscribe.js";
import Gift from "./pages/Gift.js";
import Learn from "./pages/Learn.js";

import PartnerDadhood from "./pages/partners/Dadhood.js";


// Import the new components for the footer content
import MorningSicknessTips from "./pages/ArticleMorningSicknessTips.js";
import NutritionDuringPregnancy from "./pages/ArticleNutritionDuringPregnancy.js";
import PreparingForLabor from "./pages/ArticlePreparingForLabor.js";
import SupportingYourPartner from "./pages/ArticleSupportingYourPartner.js";
import PreparingForFatherhood from "./pages/ArticlePreparingForFatherhood.js";
import BondingWithYourBaby from "./pages/ArticleBondingWithYourBaby.js";

import ArticleUppababyCruz from "./pages/articles/ArticleUppababyCruz.js";
import ArticleUppababyStrollerComparison from "./pages/articles/ArticleUppababyStrollerComparison.js";
//import Signup from "./pages/Signup.js";
//import APITests from "./APITests.js";
//import AdminTool from "./AdminTool.js";
/*
             <Route path="/signup" element={<Signup />} />
*/
import App from "./App.js";
import './index.css';

//import DataHelper from "./DataHelper.js";


function PublicSite() {
    return (
       <>
         <div className="container">
           
           <Routes>
            <Route path="/" element={<Home />} />            
             <Route path="/dashboard" element={<App />} />
             <Route path="/terms" element={<Terms />} />
             <Route path="/privacy" element={<Privacy />} />
             <Route path="/payment-success" element={<PaymentSuccess />} />
             <Route path="/payment-cancellation" element={<PaymentCancellation />} />
             <Route path="/cancellation-success" element={<CancellationSuccess />} />
             <Route path="/cancellation-error" element={<CancellationError />} />
             <Route path="/gift-payment-success" element={<GiftPaymentSuccess />} />
             <Route path="/gift-payment-cancellation" element={<GiftPaymentCancellation />} />             
             <Route path="/subscribe" element={<Subscribe />} />
             <Route path="/gift" element={<Gift />} />
             <Route path="/learn" element={<Learn />} />

             {/* Add partners */}
             <Route path="/partners/dadhood" element={<PartnerDadhood />} />

              {/* Add the new routes for the footer content */}
            <Route path="/morning-sickness-tips" element={<MorningSicknessTips />} />
            <Route path="/nutrition-during-pregnancy" element={<NutritionDuringPregnancy />} />
            <Route path="/preparing-for-labor" element={<PreparingForLabor />} />
            <Route path="/supporting-your-partner" element={<SupportingYourPartner />} />
            <Route path="/preparing-for-fatherhood" element={<PreparingForFatherhood />} />
            <Route path="/bonding-with-your-baby" element={<BondingWithYourBaby />} />

            <Route path="/articles/uppababy-cruz-dads-review" element={<ArticleUppababyCruz />} />
            <Route path="/articles/uppababy-stroller-comparison-dads-review" element={<ArticleUppababyStrollerComparison />} />
           </Routes>
           
         </div>
         
       </>
     );
   }
   
   export default PublicSite;