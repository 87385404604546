import React from 'react';
import { Helmet } from 'react-helmet';

const Head = ({ title, description, keywords, ogTitle, ogDescription, ogImage, ogUrl }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      <meta property="og:title" content={ogTitle || title} />
      <meta property="og:description" content={ogDescription || description} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:type" content="article" />
    </Helmet>
  );
};

export default Head;