/* Deploys: Nothing custom */

import React from 'react';
import './main.css'; // Import the CSS file
import PublicNav from './PublicNav';

export default function Learn() {
  

  return (
    <div className="homepage">
      <header className="header">
       <PublicNav />
      </header>
      <div className=''>
  <div className="pageContainerDefault">
      <main>
        <section className="hero">
          <div className="hero-content">
            <h2>Learn</h2>
            <p>
              Kintext is a revolutionary service that sends weekly text messages to expecting parents, keeping them informed and prepared for their baby's arrival.
            </p>
          </div>
        </section>
      </main>

      <footer>
        <p>&copy; Kintext {new Date().getFullYear()}</p>
      </footer>
    </div>
    </div>
    </div>
  );
}