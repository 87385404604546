import React, {useState, useEffect} from "react";
import axios from "axios";
import { pregnancyTips } from "./data/PregnancyMessages";
//import { getCurrentUser } from '@aws-amplify/auth';
import { Amplify } from 'aws-amplify';
import { listAppUsers } from "./graphql/queries";
import { createAppUser, updateAppUser } from "./graphql/mutations";
import { getCurrentUser } from '@aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';
//import { API, graphqlOperation } from 'aws-amplify';



Amplify.configure({
  API: {
    graphql_headers: async () => ({
      // OPTIONAL: Configure additional headers here
    }),
    graphql_endpoint: 'https://iakw2wxucfeo7ihvgz2o2z5z3q.appsync-api.us-east-2.amazonaws.com/graphql', // Replace with your GraphQL API endpoint
  },
});



/*
import CryptoJS from "crypto-js";
import twilio from 'twilio'; // Correct import statement
*/
var aws_kintextsystemapi_url = 'https://92dgj13d0e.execute-api.us-east-2.amazonaws.com/staging';

Amplify.configure({
  API: {
    graphql_headers: async () => ({
      // OPTIONAL: Configure additional headers here
    }),
    graphql_endpoint: 'YOUR_GRAPHQL_API_ENDPOINT', // Replace with your GraphQL API endpoint
  },
});

export async function getAIResponse(prompt) {
  //console.log(fromEmail, toEmail);
  
  
  try {
    //console.log('query started...')
    // Construct the payload with from and to email addresses
    /*
    const promptData = {
      promptMessage: 'I am pregnant and feeling tired all the time. What can I do to feel more energized?'
    };
    */

    const response = await axios.post(`${aws_kintextsystemapi_url}/kintextsystemapi/aiResponse`, {
      promptMessage: prompt
    });
    //console.log('Response:', response.data);
    return response.data;
  } catch (error) {
    //console.error('Error:', error.message);
    throw error;
  }
};

export async function sendReferralEmail(fromEmail, toEmail) {
  //console.log(fromEmail, toEmail);
  try {
    // Construct the payload with from and to email addresses
    const emailData = {
      fromEmail: fromEmail,
      toEmail: toEmail
    };

    const response = await axios.post(`${aws_kintextsystemapi_url}/kintextsystemapi/sendreferralemail`, emailData);
    console.log('Response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error:', error.message);
    throw error;
  }
};

export async function sendGiftSubscriptionEmails(senderEmail, recipientEmail) {
  //console.log(fromEmail, toEmail);
  try {
    // Construct the payload with from and to email addresses
    const emailData = {
      senderEmail: senderEmail,
      recipientEmail: recipientEmail
    };

    const response = await axios.post(`${aws_kintextsystemapi_url}/kintextsystemapi/sendGiftSubscriptionEmails`, emailData);
    console.log('Response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error:', error.message);
    throw error;
  }
};

export async function sendRandomTip(phoneNumber, phoneBinary, emailAddress, emailBinary) {
  try {
    const randomTip = pregnancyTips[Math.floor(Math.random() * pregnancyTips.length)];
    const payload = {
      phoneNumber: phoneNumber,
      randomTip: randomTip,
      phoneBinary: phoneBinary,
      emailAddress: emailAddress,
      emailBinary: emailBinary
    };
    const response = await axios.post('https://92dgj13d0e.execute-api.us-east-2.amazonaws.com/staging/kintextsystemapi/sendRandomTip', payload);
    console.log('Response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error:', error.message);
    throw error;
  }
}

export async function handlesendSignupNotificationToTeam(email){
  try {
    const response = await axios.post('https://92dgj13d0e.execute-api.us-east-2.amazonaws.com/staging/kintextsystemapi/sendSignupNotificationToTeam', {
      email: email
    });
    console.log('Response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error:', error.message);
    throw error;
  }
}

//5-9-24 - Some hacky code to do 500ms delay to ensure the send-text request starts before the redirect
export function handlePhoneNumberSubmit(phoneNumber) {
  const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');
  
  // Send the welcome text request
  axios.post('https://mqvnpujda9.execute-api.us-east-2.amazonaws.com/main/kintextsystemapi/sendWelcomeText', {
    to: phoneNumber
  })
  .then((response) => {
    console.log('Response:', response.data);
  })
  .catch((error) => {
    console.error('Error:', error.message);
  });
  
  // Introduce a small delay before redirection
  setTimeout(() => {
    window.location.href = `/dashboard?phoneNumber=${encodeURIComponent(phoneNumber)}`;
  }, 500); // Adjust the delay as needed (in millisecond
}
  
      /*
      const newUserResponse = await client.graphql({
        query: createAppUser,
        variables: {
          input: {
            full_name: 'My Name',
            email_address: loginId,
            phone_number: '4157581545',
            due_date: '2024-12-31',
            days_pregnant_value: 0,
            communication_frequency: 'daily',
            email_notification_status: 'true',
            phone_notification_status: 'true'
          }
        }
      });
      */
      //const newUser = newUserResponse.data.createAppUser;
      
  /*
  try {
    // Check if the user has already received a welcome text
    const userExists = await checkUserInDatabase(phoneNumber);

    if (!userExists) {
      // Send the welcome text message
      await sendWelcomeText(phoneNumber);

      // Update the database to mark the user as welcomed
      await updateUserInDatabase(phoneNumber);

      // Store the phone number as a local variable
      localStorage.setItem('phoneNumber', phoneNumber);

      console.log('Welcome text sent successfully!');
    } else {
      console.log('User has already received a welcome text.');
    }
  } catch (error) {
    console.error('Error sending welcome text:', error);
  }
  */

// Function to check if the user exists in the database
const checkUserInDatabase = async (phoneNumber) => {
  // Make an API call to check if the user exists in the database
  // Return true if the user exists, false otherwise
  // Example:
  // const response = await fetch(`/api/users?phoneNumber=${phoneNumber}`);
  // const user = await response.json();
  // return user.exists;
};

// Function to send the welcome text message
const sendWelcomeText = async (phoneNumber) => {
  // Use your preferred SMS service provider's API to send the text message
  // Example using Twilio:
  // const accountSid = 'YOUR_ACCOUNT_SID';
  // const authToken = 'YOUR_AUTH_TOKEN';
  // const client = require('twilio')(accountSid, authToken);
  // await client.messages.create({
  //   body: 'Welcome to our app!',
  //   from: 'YOUR_TWILIO_PHONE_NUMBER',
  //   to: phoneNumber,
  // });
};

// Function to update the user in the database
const updateUserInDatabase = async (phoneNumber) => {
  // Make an API call to update the user in the database
  // Mark the user as welcomed
  // Example:
  // await fetch('/api/users', {
  //   method: 'PUT',
  //   body: JSON.stringify({ phoneNumber, welcomed: true }),
  //   headers: { 'Content-Type': 'application/json' },
  // });
};


/*
export async function sendText(){
  try {
      const response = await axios.post('https://92dgj13d0e.execute-api.us-east-2.amazonaws.com/staging/kintextsystemapi/sendText', {});
      console.log('Response:', response.data);
      return response.data;
  } catch (error) {
      console.error('Error:', error.message);
      throw error;
  }
};
*/