import React from 'react'

export default function CancellationSuccess() {
    localStorage.setItem("stripeCustomer", "true");
    localStorage.setItem("stripeCustomerCancellationSuccess", "true");

    setTimeout(() => {
        window.location.href = '/dashboard';
      }, 2000);

    return (
    <div>Cancellation Success</div>
  )
}
