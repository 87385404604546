import React from 'react';

export default function Subscribe() {
  const handleSubscribeClick = () => {
    window.location.href = 'https://your-stripe-checkout-url'; // Replace with your Stripe Checkout URL
  };

  return (
    <div className="homepage">
      <header className="header">
        <h1 className="logo">Kintext</h1>
        <nav>
          <ul>
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#features">Features</a>
            </li>
            <li>
              <button className="subscribe-button" onClick={handleSubscribeClick}>
                Subscribe Now
              </button>
            </li>
          </ul>
        </nav>
      </header>

      <main>
        <section className="hero">
          <div className="hero-content">
            <h2>Stay Informed Throughout Your Pregnancy Journey</h2>
            <p>
              Kintext is a revolutionary service that sends weekly text messages to expecting parents, keeping them informed and prepared for their baby's arrival.
            </p>
            <button className="subscribe-button" onClick={handleSubscribeClick}>
              Subscribe Now
            </button>
          </div>
        </section>

        <section className="about" id="about">
          <h2>About Kintext</h2>
          <p>
            At Kintext, we understand the excitement and challenges that come with pregnancy. Our mission is to provide expecting parents with reliable, up-to-date information through personalized text messages. From fetal development updates to helpful tips and advice, we've got you covered every step of the way.
          </p>
        </section>

        <section className="features" id="features">
          <h2>Features</h2>
          <ul>
            <li>Weekly text messages tailored to your pregnancy stage</li>
            <li>Expert-curated content from trusted sources</li>
            <li>Convenient and accessible on your mobile device</li>
            <li>Affordable subscription plans for all budgets</li>
          </ul>
        </section>
      </main>

      <footer>
        <p>&copy; Kintext {new Date().getFullYear()}</p>
      </footer>
    </div>
  );
}