/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "dailyOperation",
            "endpoint": "https://rxedckhheb.execute-api.us-east-2.amazonaws.com/staging",
            "region": "us-east-2"
        },
        {
            "name": "kintextsystemapi",
            "endpoint": "https://92dgj13d0e.execute-api.us-east-2.amazonaws.com/staging",
            "region": "us-east-2"
        },
        {
            "name": "kintextsystempaymentsapi",
            "endpoint": "https://wq1tohfcpb.execute-api.us-east-2.amazonaws.com/staging",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://iakw2wxucfeo7ihvgz2o2z5z3q.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-twogc37pwjcf3pm3lwott6agpi",
    "aws_cognito_identity_pool_id": "us-east-2:d2b0b6aa-4b6d-43b1-8f47-043326909e5d",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_zQPBX2kFV",
    "aws_user_pools_web_client_id": "5rb8oah1qqhkkh3q023tvuurde",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "kintextimagestorage140513-staging",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
