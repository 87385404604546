import React from 'react';
import BlogPost from './BlogPost';

const NutritionDuringPregnancy = () => {
    const postData = {
      title: 'Nutrition During Pregnancy: Eating for Two',
      subtitle: 'Learn about the importance of proper nutrition during pregnancy and how to nourish your growing baby.',
      introduction: 'Proper nutrition during pregnancy is crucial for the health and development of your growing baby. As an expectant mother, you have the opportunity to provide your child with the best possible start in life by making informed choices about your diet. In this article, we\'ll explore the key aspects of nutrition during pregnancy and provide guidance on how to ensure you\'re getting the essential nutrients needed for a healthy pregnancy.',
      tips: {
        heading: '5 Key Components of a Healthy Pregnancy Diet',
        items: [
          {
            title: 'Balanced Macronutrients',
            content: 'A balanced pregnancy diet should include a variety of macronutrients: carbohydrates, proteins, and healthy fats. Choose complex carbohydrates like whole grains, lean proteins such as chicken and fish, and healthy fats from sources like avocados and nuts. Aim to include a mix of these nutrients in each meal and snack.',
          },
          {
            title: 'Essential Vitamins and Minerals',
            content: 'Certain vitamins and minerals are particularly important during pregnancy. Folic acid helps prevent neural tube defects, iron supports the increased blood volume, and calcium aids in the development of your baby\'s bones and teeth. Prenatal vitamins can help ensure you\'re getting the recommended amounts of these essential nutrients.',
          },
          {
            title: 'Hydration',
            content: 'Staying hydrated is key during pregnancy. Aim to drink at least 8-10 glasses of water per day. You can also include other hydrating fluids like herbal teas and clear soups. Avoid sugary drinks and limit caffeine consumption to moderate amounts.',
          },
          {
            title: 'Food Safety',
            content: 'Pregnancy requires extra attention to food safety to minimize the risk of foodborne illnesses. Avoid raw or undercooked meats, fish with high mercury levels, unpasteurized dairy products, and certain soft cheeses. Thoroughly wash fruits and vegetables, and practice safe food handling techniques.',
          },
          {
            title: 'Mindful Eating',
            content: 'Pay attention to your hunger and fullness cues, and eat mindfully. Avoid skipping meals and opt for nutritious snacks when needed. Listen to your body\'s needs and cravings, but focus on making healthy choices most of the time. Remember, pregnancy is not a time for restrictive dieting, but rather a time to nourish yourself and your growing baby.',
          },
        ],
      },
      conclusion: 'Nutrition during pregnancy plays a vital role in supporting the growth and development of your baby. By focusing on a balanced diet, essential nutrients, hydration, food safety, and mindful eating, you can provide the best possible foundation for your child\'s health. Remember to consult with your healthcare provider for personalized advice and to address any specific dietary concerns. Embrace this special time and enjoy the journey of nourishing both yourself and your baby.',
      relatedArticles: [
        {
            title: 'Preparing for Labor',
            url: '/preparing-for-labor',
          },
          {
            title: 'Morning Sickness Tips',
            url: '/morning-sickness-tips',
          },
      ],
    };

    return <BlogPost {...postData} />;
}

export default NutritionDuringPregnancy;